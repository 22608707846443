<template>
  <div style="width: 100%;height: 100%;">
    <!-------------------- 头部导航 -------------------->
    <div style="width: 100%;height: 5%;display: flex;justify-content: space-between;align-items: center;background-color: #F1F1F1;">
      <div style="width: 50%;height: 100%;display: flex;align-items: center;">
        <div style="width: 15%;height: 100%;display: flex;align-items: center;justify-content:center;" :class="{'model1':models==1,'model2':models!=1}" @click="models=1">
          <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/huihua0.png" alt="" style="position: relative;left: -5px;top:0px;width: 18px;height: 18px;"
           v-show="models!=1">
          <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/huihua1.png" alt="" style="position: relative;left: -5px;top:0px;width: 18px;height: 18px;"
          v-show="models==1">
          <div>AI对话</div>
        </div>
        <div style="width: 15%;height: 100%;display: flex;align-items: center;justify-content:center;" :class="{'model1':models==2,'model2':models!=2}" @click="models=2,scroend()">
          <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/mj_hb0.png" alt="" style="position: relative;left: -5px;top:0px;width: 18px;height: 18px;"
           v-show="models!=2">
          <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/mj_hb1.png" alt="" style="position: relative;left: -5px;top:0px;width: 18px;height: 18px;"
          v-show="models==2">
          <div>AI绘图</div>
          <div style="position: relative;top:0;height: 100%;display: flex;justify-content: center;align-items: center;" v-show="models==2">
            <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/3jiao.png" alt="" style="position: absolute;left: 5px;height: 40%;top:15px" />
            <div style="color:#fff;position: absolute;left: 13px;font-size: 80%;width:38px;text-align: center;top:17px">{{ user_info.mj_num }}</div>
          </div>
          <!-- <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/3jiao.png" alt="" style="position: relative;left: 5px;"> -->
        </div>
        <div style="width: 15%;height: 100%;display: flex;align-items: center;justify-content:center;margin-left: 30px" :class="{'model1':models==3,'model2':models!=3}" @click="models=3,getSortList()">
          <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/sora0.png" alt="" style="position: relative;left: -5px;top:0px;width: 18px;height: 18px;"
               v-show="models!=3">
          <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/sora1.png" alt="" style="position: relative;left: -5px;top:0px;width: 18px;height: 18px;"
               v-show="models==3">
          <div>Sora</div>
          <!-- <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/3jiao.png" alt="" style="position: relative;left: 5px;"> -->
        </div>
      </div>
      <div style="width: 50%;height: 100%;display: flex;align-items: center; justify-content: end;">
        <van-tag color="#12A37D" :plain="true" size="large" style="color:#12A37D;margin-right: 3%;" @click="dialogShow2=true">充值会员</van-tag>
        <div style="width: 30px;height: 30px;color: rgba(255,255,255,1);border-radius: 3px;overflow: hidden;margin-right: 3%;" @click="usershow=!usershow,imgShow=false,settingShow=false">
          <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/user2.png" alt="" style="width: 100%;">
        </div>
      </div>
    </div>
    <div style="position:absolute;right:10px;top:6%; padding:0 20px; border-radius:5px; border:1px solid #D8D8D8;color: #8D8D8D;text-align:left;z-index: 1;background-color: #FFFFFF;" v-show="usershow">
      <div style="width:100%;height:10px;"></div>
      <div style="padding: 3% 0%;box-sizing:border-box">用户总余额: {{ user_info['balance']?? '' }}元</div>
      <div style="padding: 2% 0%;box-sizing:border-box" v-show="user_info.is_vip">AI对话会员: {{user_info.viptime}}到期</div>
      <div style="padding: 2% 0%;box-sizing:border-box" v-show="!user_info.is_vip">AI对话会员: 不是会员</div>
      <div style="padding: 2% 0%;box-sizing:border-box;" v-show="user_info.is_mj_vip">AI画画会员: {{user_info.mj_viptime}}到期</div>
      <div style="padding: 2% 0%;box-sizing:border-box" v-show="!user_info.is_mj_vip">AI画画会员: 不是会员</div>
      <div style="width:100%;height:20px;border-bottom: 1px solid #D8D8D8;"></div>
      <div style="width:100%;height:20px;"></div>
      <van-button color="#12A37C" style="width: 100%;font-size: 100%;" @click="dialogShow3=true">退出登录</van-button>
      <div style="width:100%;height:20px;"></div>
    </div>
    
    <!-------------------- 主体内容 -------------------->
    <div style="width: 100%;height: 95%; display: flex;">
      <!-------------------- 对话历史 -------------------->
      <div style="width: 15%;min-width:257px;height: 100%;background-color: #12A27E;padding: 0.8%;box-sizing: border-box; display: flex;flex-direction: column;padding-top: 1%;color: white;position: relative;" v-show="models==1">
        <div style="width: 100%;height: 35px; border: 0.5px solid rgba(255, 255, 255, 0.1);border-radius: 5px; padding: 3%;box-sizing: border-box; text-align: left;display: flex;align-items: center;font-size: 90%;padding-left: 5%;" @click="newchat"><span style="font-size: 140%;">+</span>&nbsp;&nbsp;New chat</div>
        <div style="width: 100%;height: 100%;overflow-y: auto;position: relative;" class="scrollbar">
          <div style="width: 100%;color: rgba(217,217,227,1);font-size: 80%;padding: 4% 0;box-sizing: border-box;text-align: left;">Previous 7 Days</div>
          <div v-for="(item,index) in message_list" :key="index" style="width: 100%;color: #F9F8EC;font-size: 80%;padding: 4% 0;box-sizing: border-box;display: flex;align-items: center;position: relative;">
            <van-icon name="chat-o" size="16"/>
            <div style="display: inline-block; width: 70%;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;text-align: left;padding-left: 5%;box-sizing: border-box;" @click="chooseChat(index)">{{ item.message[0].content }}</div>
            <!-- <van-icon name="delete-o" style="font-size: 16px;" @click="chooseChat(index)"/> -->
            <div style="width: 21%;text-align: right;">
              <van-popover
                v-model="item.popover"
                placement="bottom-end"
                :offset="[-8,8]"
                :close-on-click-outside="true"
                @close="popover_del_flag=false"
                @opened="popover_del_flag=true"
              >
                <div style="background-color: #F9F8EC;border-radius: 2px;">&nbsp;&nbsp;确认删除?&nbsp;&nbsp;</div>
                <template #reference>
                  <van-icon v-show="!item.popover" name="delete-o" size="16" @click="popover(index,1)"/>
                  <van-icon v-show="item.popover" name="success" size="16" @click="delMessage(item.id)" style="margin-right: 10px;"/>
                  <van-icon v-show="item.popover" name="cross" size="16" @click="popover(index,0)"/>
                </template>
              </van-popover>
            </div>
          </div>
          <div style="width: 100%;display: flex;position: absolute;left: 0%;bottom: 0;padding: 4% 0%;box-sizing: border-box;" v-show="models==1">
            <div style="width: 30px;background-color: #202123;color: rgba(217,217,227,1);display: flex;align-items: center;justify-content: center;">
              <van-icon name="delete-o" size="25" style="text-align: center;"/>
            </div>
            <div style="margin-left: 5%;font-size: 90%;color: #F9F8EC;width: calc(100% - 30px);display: flex;align-items: center;" @click="dialogShow4=true">
              <div>清除所有对话</div>
            </div>
          </div>
        </div>
      </div>
      <!--------------------对话内容-------------------->
      <div style="width: 85%;height: 100%; display:flex;flex-wrap:wrap;justify-content:center;position:relative;" v-show="models==1">
        <!--------------------头部导航-------------------->
        <div style="width: 28%;margin-top:1%;display: flex;border-radius: 3px;border: 1px solid #E3E3E3;border-radius: 5px;overflow: hidden;" ref="nav">
          <div style="width: 50%;padding: 5px 0;box-sizing: border-box;" :class="{'nav_choose':nav_choose_num==1,'nav_nochoose':nav_choose_num!=1}" @click="navChoose(1)">3.5 模型</div>
          <div style="width: 50%;padding: 5px 0;box-sizing: border-box;" :class="{'nav_choose':nav_choose_num==2,'nav_nochoose':nav_choose_num!=2}" @click="navChoose(2)">4.0 模型 ({{ user_info.gpt4_num }}次)</div>
        </div>
        <div style="height: 94.5%;width: 100%;overflow-y: auto;margin-top: 1%;" ref="messageContent">
          <!-- 用来获取消息体高度 -->
          <div id="okk" scroll-with-animation style="width: 100%;">
            <!-- 消息 -->
            <div v-for="(x,i) in msgList" :key="i">
              <!-- 用户消息 头像可选加入-->
              <div v-if="x.role=='user' || x.role=='system'" style="display: flex;flex-direction: column;align-items: flex-end;width: 100%;font-size: 1rem;line-height: 1.5rem;padding:15px 26%;box-sizing: border-box;color: rgba(52,53,65,1);">
                <div style="width: 100%;display: flex;flex-wrap: wrap;">
                  <div style="width: 30px;height: 30px;background-color: #5B4138;color: rgba(217,217,227,1);border-radius: 3px;overflow: hidden;">
                    <img :src="user_info.headimage?? ''" alt="" style="width: 100%;">
                  </div>
                  <div style="width: 25px;"></div>
                  <div style="width: calc(100% - 55px);align-items: flex-start;flex-direction: column;min-height: 20px;display: flex;text-align: left;white-space: pre-line;word-break: break-all;" ref="content" class="content">
                    {{x.content}}
                  </div>
                </div>
              </div>
              <!-- 机器人消息 -->
              <div v-if="x.role=='assistant'" style="display: flex;flex-direction: column;align-items: flex-end;width: 100%;font-size: 1rem;line-height: 1.5rem;padding:15px 26%;box-sizing: border-box;color: rgba(52,53,65,1);background-color: rgba(247,247,248,1);border: 0.5px solid rgba(0, 0, 0, 0.1);border-right:0;border-left:0;">
                <div style="width: 100%;display: flex;flex-wrap: wrap;">
                  <div style="width: 30px;height: 30px;background-color: #5B4138;color: rgba(217,217,227,1);border-radius: 3px;overflow: hidden;">
                    <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/chatlogo.jpeg" alt="" style="width: 100%;">
                  </div>
                  <div style="width: 25px;"></div>
                  <div style="width: calc(100% - 55px);align-items: flex-start;flex-direction: column;min-height: 20px;display: flex;text-align: left;white-space: pre-line;word-break: break-all;" ref="content" class="content">
                    {{x.content}}
                  </div>
                  <!-- <div style="width: 100%;font-size: 0.75rem;line-height: 1rem;text-align: right;margin-right: 20px;color: #aaa;" v-show="x.tokenFlag"></div> -->
                </div>
              </div>
            </div>
            <div style="width: 100%;height: 20vh;"></div>
          </div>
        </div>
        
        <!-- <van-field v-model="message" rows="1" :right-icon="right_icon" @click-right-icon="chatqaAdd(message)" :autosize="{'maxHeight':200}" type="textarea" placeholder="发送一条信息" :disabled="runingFlg" style="position: absolute;bottom: 80px; resize: none;overflow-y: auto;padding: 10px;font-size: 16px;line-height: 1.5;box-sizing: border-box;border: 0.5px solid rgba(0, 0, 0, 0.1);border-radius: 8px;width: 45%;box-shadow: var(0 0 transparent,0 0 transparent),var(0 0 transparent,0 0 transparent),var(0 0 transparent);" ref="van_field" @input="textUpdate">
        </van-field> -->
        <div style="width: 50%;position: absolute;bottom: 50px;max-height: 188px;overflow-y: auto;border: 0.5px solid rgba(0, 0, 0, 0.1);border-radius: 8px;box-shadow: var(0 0 transparent,0 0 transparent),var(0 0 transparent,0 0 transparent),var(0 0 transparent);display:flex;" ref="van_field_f">
          <van-field v-model="message" rows="1" autosize type="textarea" placeholder="请输入内容,按shift+回车换行" :disabled="runingFlg" style="resize: none;overflow-y: auto;font-size: 16px;line-height: 1.5;padding:15px;padding-right:40px;box-sizing: border-box;width: 100%;white-space: pre-line;word-break: break-all;" class="scrollbar2" ref="van_field" @keydown="key_down2($event)">
          </van-field>
          <!-- <van-icon :name="right_icon" size="32" style="margin:0;position:fixed ;bottom:6.6%;right:22%;"/> -->
          <van-icon :name="right_icon" size="32" style="margin:0;position:absolute ;bottom:12px;right:10px;" @click="chatqaAdd(message)"  v-show="right_icon!='wait'"/>
          <div style="margin:0;position:absolute ;bottom:23px;right:15px;display: flex;flex-direction: row;align-items: center;justify-content: center;" v-show="right_icon=='wait'">
            <div class="dot1"></div>
            <div class="dot2"></div>
            <div class="dot3"></div>
          </div>
        </div>
        
      </div>

      <!-------------------- 绘画内容 -------------------->
      <div style="width: 100%;height: 100%; display:flex;flex-wrap:wrap;justify-content:center;position:relative;" v-show="models==2">
        
        <div style="height: 78.5%;width: 100%;overflow-y: auto;margin-top: 1%;display: flex;justify-content: center;flex-wrap: wrap;" ref="mjContent">
          <div style="width: 100%;color:#777777;font-size: 90%;" @click="onRefresh" v-show="mjList.length<total">点击此处加载历史记录</div>
          <!-- 用来获取消息体高度 -->
          <div id="okk" scroll-with-animation style="width: 54%">
            <!-- <van-pull-refresh v-model="refreshLoad" @refresh="onRefresh" style="width: 100%;height: 18%;">
              <div style="height: 100%;width: 100%;display: flex;align-items: center;justify-content: center;color:#777777;font-size: 90%;">下拉此处刷新</div>
            </van-pull-refresh> -->
            <!-- 消息 -->
            <div v-for="(x,i) in mjList" :key="'mj' + i" style="margin-top: 20px;">
              <!-- 用户消息 头像可选加入-->
              <div style="display: flex;width: 100%;box-sizing: border-box;color: rgba(52,53,65,1);">
                <!-- <div style="width: 100%;display: flex;flex-wrap: wrap;"> -->
                <div style="width: 30px;height: 30px;overflow: hidden;background-color: #309F7E;color: #fff;border-radius: 50px;display: flex;align-items: center;justify-content: center;">
                  <!-- <img :src="user_info.headimage?? ''" alt="" style="width: 100%;"> -->
                  <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/hhtx.png" alt="" style="width: 75%;">
                </div>
                <div style="width: 10px;"></div>
                <div style="width: 40%;color: #111;border-radius: 5px;text-align: left;word-break: break-all;display: inline-block;" class="mj_content">
                  <div style="width: 100%;background-color: #F1F1F1;border-radius: 5px;text-align: left;padding: 10px;box-sizing: border-box;" ref="mj_content">
                    <!-- {{x.content}} -->
                    <!-- <div style="width: 100%;background-color: #F1F1F1;border-radius: 5px;text-align: left;padding: 10px;box-sizing: border-box;">
                      <img src="http://154.39.81.130:1000/mj/attachments/1153921895717163019/1165685155273392228/metavienna_A_red_dog._9959144c-b2de-43d1-b12b-b70e4c9dc69f.png" style="width: 100%;border-radius: 15px;">
                      <div style="width: 100%;text-align: right;margin-top: 10px;color: #6E6E6E;">2023-10-22 12:22</div>
                    </div>
                    
                    <div style="width: 100%;display: flex;flex-wrap: wrap;padding: 0;box-sizing: border-box;" id="asfas123123">
                      <div style="" class="u">U1</div>
                      <div style="margin-left: 2%;" class="u">U2</div>
                      <div style="margin-left: 2%;" class="u">U3</div>
                      <div style="margin-left: 2%;margin-top: 2%;" class="u">U4</div>
                      <div style="margin-left: 2%;margin-top: 2%;" class="u reset">
                      </div>
                      <div style="" class="u">V1</div>
                      <div style="margin-left: 2%;" class="u">V2</div>
                      <div style="margin-left: 2%;" class="u">V3</div>
                      <div style="margin-left: 2%;" class="u">V4</div>
                    </div> -->
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
            <div style="width: 100%;height: 5vh;"></div>
          </div>
        </div>
        
        <!-- <van-field v-model="message" rows="1" :right-icon="right_icon" @click-right-icon="chatqaAdd(message)" :autosize="{'maxHeight':200}" type="textarea" placeholder="发送一条信息" :disabled="runingFlg" style="position: absolute;bottom: 80px; resize: none;overflow-y: auto;padding: 10px;font-size: 16px;line-height: 1.5;box-sizing: border-box;border: 0.5px solid rgba(0, 0, 0, 0.1);border-radius: 8px;width: 45%;box-shadow: var(0 0 transparent,0 0 transparent),var(0 0 transparent,0 0 transparent),var(0 0 transparent);" ref="van_field" @input="textUpdate">
        </van-field> -->

        <div style="width: 40%;position: absolute;bottom: 114px;display: flex;left: 25%;" ref="settingimg">
          <div style="width: 100%;position: relative;display: flex;">
            <div style="border-radius: 5px;padding: 8px 20px;box-sizing: border-box; border: 1px solid #D6D6D6;display: flex;background-color: #fff;" @click="settingShow=!settingShow,imgShow=false,usershow=false">
              <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/setting3.png" alt="" style="position: relative;left: -5px;top:2px;width: 18px;height: 18px;">
              <div>设置</div>
            </div>
            <div style="border-radius: 5px;padding: 8px 20px;box-sizing: border-box; border: 1px solid #D6D6D6;margin-left: 15px;display: flex;align-items: center;background-color: #fff;" @click="imgShow=!imgShow,settingShow=false,usershow=false">
              <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/imgadd3.png" alt="" style="position: relative;left: -5px;top:2px;width: 18px;height: 19px;">
              <div>参考图</div>
            </div>
            <div style="width: 400px;height: auto;position: absolute;bottom: 50px;left: 0;background-color: white;border-radius: 10px;border: 1px solid #12A37D;" v-show="settingShow">
              <div style="width: 100%;display:flex;flex-direction:column;padding:6% 6%;box-sizing:border-box;border-radius: 10px;">  
                <div style="width:100%;text-align:center;font-size:110%;font-weight:bold;">设置</div>
                <div style="margin-top:10px;display: flex;font-weight: bold;">图片比例</div>
                <div style=" margin-top:10px;display: flex;align-items: center;" class="bl">
                  <van-field v-model="bl1" label="" type="digit" maxlength="2" input-align="left" style="width:30%;border:1px solid;border-radius: 4px;border-color: #619F8A;" @blur="blBlur(1)"/>
                  <div style="margin: 0 10px;font-size: 20px;">:</div>
                  <van-field v-model="bl2" label="" type="digit" maxlength="2" input-align="left" style="width:30%;border:1px solid;border-radius: 4px;border-color: #619F8A;" @blur="blBlur(2)"/>
                </div>
                <div style="width:100%;margin-top:20px;display: flex;align-items: center;">
                  <div style="font-weight: bold;">模式选择</div>
                </div>
                <div style="width: 100%;margin-top:10px;display: flex;justify-content: space-between;">
                  <div style="width: 47%;padding: 4% 0;box-sizing: border-box;border-radius: 5px;" :class="{'ms_choose':ms_choose_num==1,'ms_nochoose':ms_choose_num!=1}" @click="ms_choose_num=1">
                    <div style="width: 100%;text-align: center;">普通模式</div>
                    <div style="width: 100%;text-align: center;font-size: 70%;margin-top: 10px;">默认模式,</div>
                    <div style="width: 100%;text-align: center;font-size: 70%;">支持中文输入</div>
                  </div>
                  <div style="width: 47%;padding: 4% 0;box-sizing: border-box;border-radius: 5px;" :class="{'ms_choose':ms_choose_num==2,'ms_nochoose':ms_choose_num!=2}" @click="ms_choose_num=2">
                    <div style="width: 100%;text-align: center;">专业模式</div>
                    <div style="width: 100%;text-align: center;font-size: 70%;margin-top: 10px;">仅支持英文输入和命令</div>
                    <div style="width: 100%;text-align: center;font-size: 70%;">例如 a cat --ar 16:9</div>
                  </div>
                </div>
                <van-button style="width: 100%;margin-top: 20px; background-color: #12A37D;color: white;border-radius: 5px;font-size: 120%;"  @click="settingShow=false">确 认</van-button>
              </div>
            </div>

            <div style="width: 400px;height: auto;position: absolute;bottom: 50px;left: 14%;background-color: white;border-radius: 10px;border: 1px solid #12A37D;" v-show="imgShow">
              <div style="width: 100%;display:flex;flex-direction:column;padding:6% 6%;box-sizing:border-box;border-radius: 10px;">
                <div style="width: 100%;display: flex;border-radius: 3px;border: 1px solid #12A27E;" >
                  <div style="width: 33.33%;padding: 5px 0;box-sizing: border-box;border-right: 0.5px solid #12A27E;text-align: center;" :class="{'nav_choose_s':imgChoose==1,'nav_nochoose_s':imgChoose!=1}" @click="img_choose(1)" ref="t_width">图生图</div>
                  <div style="width: 33.33%;padding: 5px 0;box-sizing: border-box;text-align: center;" :class="{'nav_choose_s':imgChoose==2,'nav_nochoose_s':imgChoose!=2}" @click="img_choose(2)">图生文</div>
                  <div style="width: 33.33%;padding: 5px 0;box-sizing: border-box;border-left: 0.5px solid #12A27E;text-align: center;" :class="{'nav_choose_s':imgChoose==3,'nav_nochoose_s':imgChoose!=3}" @click="img_choose(3)">图片混合</div>
                </div>
                <!-------------------- 图生图 -------------------->
                <div style="width:100%;margin-top:15px;display: flex;flex-wrap: wrap; align-items: center;justify-content: center;" v-show="imgChoose==1">
                  <van-uploader style="width: 64%;" :preview-image="false" :after-read="tstAfter">
                    <div style="width: 100%;min-height: 180px;border-radius: 8px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;position: relative;left: 0;" :class="{'imgcont':tst.content.length==0}"> 
                      <div style="width: 100%;display: flex;align-items: center; flex-wrap: wrap;flex-direction: column;justify-content: center;" v-show="tst.content.length==0">
                        <div style="height: 13px;width: 100%;"></div>
                        <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/upload.png" alt="" style="width: 25%;">
                        <div style="font-size: 70%;color: #777777;margin-top: 5%;" >请点击需要描述的图片到该区域</div>
                        <div style="font-size: 65%;color: #A3A3A3;margin-top: 2%;">请上传参考图片，上传完成后,</div>
                        <div style="font-size: 65%;color: #A3A3A3;margin-top: 0%;">回到输入框输入提示词开始</div>
                      </div>
                      <img :src="tst.content" alt="" style="width: 100%;">
                      <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/delete.png" alt="" style="position: absolute;right: 0;top: 0;width: 20px;background-color: #0FA07C;z-index: 1;" v-show="tst.content.length>0" @click="tst={'content':''}">
                    </div>
                  </van-uploader>
                  <div style="width: 100%;display: flex;align-items: center;margin-top: 20px;">
                    <div style="color: #000;font-weight: bold;width: 38%;text-align: left;">参考图权重</div>
                    <van-slider v-model="tstHk" :min="0.5" :max="2" :step="0.1" active-color="#10A17C" inactive-color="#ABABAB" bar-height="4px" style="width: 100%;" >
                    <!-- <van-slider v-model="tstHk" :min="1" :max="999" active-color="#10A17C"  bar-height="4px" style="width: 100%;" > -->
                      <template #button>
                        <div style="width: 10px;height: 10px;background-color: white;border: 1px solid #10A17C;border-radius: 100px;"></div>
                        <!-- <div style="width: 26px;color: #fff;font-size: 10px;line-height: 18px;text-align: center;background-color: #1390E2;border-radius: 100px;">{{ tstHk }}</div> -->
                      </template>
                    </van-slider>
                    <div style="color: #676767;text-align: right;width: 40px;">{{ tstHk }}</div>
                  </div>
                  <van-button style="width: 100%;margin-top: 20px; background-color: #5B5A5A;color: white;border-radius: 3px;font-size: 100%;" v-show="tst.content.length==0" :disabled="tst.content.length==0" >确认并返回填写描述词</van-button>
                  <van-button style="width: 100%;margin-top: 20px; background-color: #12A37D;color: white;border-radius: 5px;font-size: 100%;" v-show="tst.content.length!=0" @click="imgShow=false">确认并返回填写描述词</van-button>
                </div>
                <!-------------------- 图生文 -------------------->
                <div style="width:100%;margin-top:15px;display: flex;flex-wrap: wrap; align-items: center;justify-content: center;" v-show="imgChoose==2">
                  <van-uploader style="width: 64%;" :preview-image="false" :after-read="tswAfter">
                    <div style="width: 100%;min-height: 180px;border-radius: 8px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;position: relative;left: 0;" :class="{'imgcont':tsw.content.length==0}"> 
                      <div style="width: 100%;display: flex;align-items: center; flex-wrap: wrap;flex-direction: column;" v-show="tsw.content.length==0">
                        <div style="height: 13px;width: 100%;"></div>
                        <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/upload.png" alt="" style="width: 25%;">
                        <div style="font-size: 70%;color: #777777;margin-top: 5%;" >请上传需要描述的图片</div>
                        <div style="font-size: 65%;color: #A3A3A3;margin-top: 2%;">上传完成后AI会自动生成</div>
                        <div style="font-size: 65%;color: #A3A3A3;margin-top: 0%;">对画面的英文描述。</div>
                      </div>
                      <img :src="tsw.content" alt="" style="width: 100%;">
                      <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/delete.png" alt="" style="position: absolute;right: 0;top: 0;width: 20px;background-color: #0FA07C;z-index: 1;" v-show="tsw.content.length>0" @click="tsw={'content':''}">
                    </div>
                  </van-uploader>
                  <van-button style="width: 100%;margin-top: 20px; background-color: #5B5A5A;color: white;border-radius: 3px;font-size: 100%;" v-show="tsw.content.length==0" :disabled="tsw.content.length==0" >立即生成</van-button>
                  <van-button style="width: 100%;margin-top: 20px; background-color: #12A37D;color: white;border-radius: 5px;font-size: 100%;" v-show="tsw.content.length!=0" @click="tswPlay">立即生成</van-button>
                </div>
                <!-------------------- 图片混合 -------------------->
                <div style="width:100%;display: flex;flex-wrap: wrap;box-sizing: border-box;" v-show="imgChoose==3">
                  <div style="width: 100%;height: 15px;"></div>
                  <van-uploader v-model="tphh" multiple :max-count="6" :after-read="tphhAfter" :preview-size="tphh_size" :preview-full-image="false">
                  </van-uploader>
                  <div :style="{'width': '100%', 'height': tphh_size}" v-show="tphh.length<3"></div>
                  <div style="color:#DCDCDC;font-size: 80%;width: 100%;text-align: center;">最多上传6张</div>

                  <van-button style="width: 100%;margin-top: 20px; background-color: #5B5A5A;color: white;border-radius: 3px;font-size: 100%;" v-show="tphh.length==0" :disabled="tphh.length==0" >立即混合</van-button>
                  <van-button style="width: 100%;margin-top: 20px; background-color: #12A37D;color: white;border-radius: 5px;font-size: 100%;" v-show="tphh.length!=0" @click="tphhPlay">立即混合</van-button>
                </div>
               </div>
            </div>
          </div>
        </div>  
        
        <div style="width: 50%;position: absolute;bottom: 50px;max-height: 188px;overflow-y: auto;border: 0.5px solid rgba(0, 0, 0, 0.1);border-radius: 8px;box-shadow: var(0 0 transparent,0 0 transparent),var(0 0 transparent,0 0 transparent),var(0 0 transparent);display:flex;" ref="van_field_f_mj">
          
          <van-field v-model="mj_message" rows="1" autosize type="textarea" placeholder="请输入内容,按shift+回车换行" :disabled="mj_runFlag" style="resize: none;overflow-y: auto;font-size: 16px;line-height: 1.5;padding:15px;padding-right:40px;box-sizing: border-box;width: 100%;white-space: pre-line;word-break: break-all;" class="scrollbar2" ref="van_field2" @keydown="key_down($event)">
          </van-field>
          <!-- <van-icon :name="right_icon" size="32" style="margin:0;position:fixed ;bottom:6.6%;right:22%;"/> -->
          <van-icon :name="mj_right_icon" size="32" style="margin:0;position:absolute ;bottom:12px;right:10px;" @click="playTu(mj_message)"  v-show="mj_right_icon!='wait'"/>
          <div style="margin:0;position:absolute ;bottom:23px;right:15px;display: flex;flex-direction: row;align-items: center;justify-content: center;" v-show="mj_right_icon=='wait'">
            <div class="dot1"></div>
            <div class="dot2"></div>
            <div class="dot3"></div>
          </div>
        </div>
      </div>

      <!------------------------Sora主题-------------------------->
      <div style="overflow-y: auto; width: 100%;height: 100%; display: flex;flex-direction: column;align-items: center;position:relative;" v-show="models==3">
        <div style="width: 100%;height:auto;display: flex;margin-top: 10px;margin-left: 30px" >
          <div style="margin-right: 1%;display: flex">
            <!--              <img style="width: 100%" src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/promptSora.png">-->
            <span style="color: #1D1D1D;font-weight: bold;font-size: 1.2rem">PromptSora</span>
          </div>
          <div style="width: 4rem;height:1.3rem;border: 1px solid #12A27E;border-radius: 3px;display:flex;align-items: center;justify-content:center;">
            <span style="font-size: 0.8rem;color: #12A27E;line-height: 1.2rem">抢先体验</span>
          </div>
        </div>
        <div style="width: 100%; display: flex; margin-top: 50px">
          <div style="width: 100%;display: flex;flex-direction: column;align-items: center;position:relative;">
            <img style="width: 30%" src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/soraWorld.png">
          </div>
        </div>
        <div style="width: 100%; display: flex;margin-top: 20px">
          <div style="width: 100%;display: flex;flex-direction: column;align-items: center;position:relative;">
            <span style="width: 35%;text-align: left;font-size: 1rem;font-weight: 400;color: #333333;line-height: 1.6rem">通过手工挑选的每周更新的资源、教程和示例提示，使用OpenAl的Sora从文本中创建视频，探索创意的世界。</span>
          </div>
        </div>
        <div style="width: 100%; display: flex;margin-top: 20px;flex-direction: column;align-items: center;position:relative;">
            <div style="width: 90%;padding:4px 4px;display: flex;justify-content:space-between;flex-wrap:wrap;align-items: center;position:relative">
                <div v-for="(value,index) in soraList" :key="index" style="width: 24%;margin:10px 0;">
                    <div style="width:100%; display: flex;flex-direction: column;align-items: center;position:relative;background-color: #F1F9F9;border-radius: 10px">
                      <div style="width: 90%;padding: 10px 0">
                        <video style="width: 100%;border-radius: 3px" autoplay="autoplay" playsinline="" loop="" muted="muted" preload="none"
                               aria-label="Video player">
                          <source :src="value.video"
                                  type="video/mp4">
                        </video>
                      </div>
                    </div>
                    <div style="height:40px;font-size: 14px;font-weight: 400;color: #838383;line-height: 20px; display: -webkit-box;-webkit-line-clamp: 2; -webkit-box-orient: vertical;overflow: hidden;">
                      {{value.text}}
                    </div>
                </div>
            </div>

        </div>
      </div>

    </div>

    <!-------------------- 图片详情 -------------------->
    <van-overlay :show="allImgShow" style="z-index:99999;" @click="allImgShow = false">
      <div style="width: 100%;height: 100%;display: flex;flex-wrap: wrap; align-items: center;flex-direction: column; position: relative;justify-content: center;">
          <img :src="allImg" alt="" style="height: 70%;max-width: 70%;">
      </div>
    </van-overlay>

    <van-overlay :show="loadFlag2">
      <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
        <van-loading type="spinner"/>
      </div>
    </van-overlay>

    <van-dialog v-model="dialogShow" title="编辑" show-cancel-button @confirm="edit">
      <div style="width: 100%;display: flex;align-items: center;justify-content: center;padding: 5%;box-sizing: border-box;flex-direction: column;">
        <van-field v-model="nickname" label=""  maxlength="10" placeholder="昵称" style="width: 80%; border: 1px solid #aaa;border-radius: 10px;"/>
        <div style="width: 80%;text-align: left;margin-top: 10px;">
          <div style="color: #C8C9CC;font-size: 90%;">上传头像</div>
          <van-uploader :after-read="upload" v-model="fileList" :max-count="1" style="margin-top: 5px;"/>
        </div>
      </div>
    </van-dialog>

    <van-dialog v-model="dialogShow2" title=""  :show-confirm-button="false" @confirm="recharge" style="width: 40%;" @closed="chooseRecharge">
      <UserIndexPC4Rechar @changeNavEvent="changeNav" :is_recharge="is_recharge" @closeEvent="dialogShow2=false"></UserIndexPC4Rechar>
    </van-dialog>

    <van-dialog v-model="dialogShow3" title="是否退出登录?" show-cancel-button @confirm="loginout">
    </van-dialog>

    <van-dialog v-model="dialogShow4" title="是否清除所有对话?" show-cancel-button @confirm="delMessage">
    </van-dialog>

    <van-overlay :show="loadFlag3" style="z-index:9999;">
      <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;position: relative;flex-direction: column;">
        <div style="width: 50px;height: 50px;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="rechar_check_cannel">X</div>
        <img :src="qrcode_url" alt="" style="width: 10%;">
        <div style="width: 100%;margin-top: 1%;color: white;">{{ payMsg }}</div>
      </div>
    </van-overlay>

    <!-------------------- 加载 -------------------->
    <van-overlay :show="loadShow" style="z-index:999999;">
      <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<style scoped lang="scss">
::v-deep .bl .van-cell{
  line-height: 10px;
}
::v-deep .blc .van-cell{
  padding-left: 0px;
}
.reset{
  background-position: 50% 50%;
  background-size: 40% 80%;
  background-repeat: no-repeat;
  background-image: url("https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/reset.png");
}
.u{
  width: 18%;padding: 2% 5%;box-sizing: border-box;background-color: #ABABAB;border-radius: 5px;color: white;margin-top: 2%;text-align: center;
}
.model1{
  color:#10A17D
}
.model2{
  color:#111;
}
.nav_choose_s{
  background-color: #12A27E;
  color:#FAFBFD;
}
.nav_nochoose_s{
  background-color: #FAFBFD;
  color:#12A27E;
}
.nav_choose{
  background-color: #12A27E;
  color:#F0F0F0;
}
.nav_nochoose{
  background-color: #FFFFFF;
  color:#BBBBBB;
}
.marginleft{
  margin-left: 3.33%;
}
.van-popover--light .van-popover__content{
  background-color: #23241f;
}
p{
  margin: 2px 0;
}
.dot1, .dot2, .dot3 {
  width: 100%;
  height: 100%;
  border:double;
  border-radius: 50%;
  margin-left: 5px;
  background: #808080;
  color: #808080;
}
.dot1 {
  animation: jump 1.6s -0.32s linear infinite;
}
.dot2 {
  animation: jump 1.6s -0.16s linear infinite;
}
.dot3 {
  animation: jump 1.6s linear infinite;
}
.recharge_choose{
  border: 1px solid #10A17D;
}
.recharge_no_choose{
  border: 1px solid #ccc;
}

@keyframes jump {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(2.0);
    transform: scale(2.0);
  }
}
</style>
<style >
.imgcont{
  border: 1px dashed #ABABAB;padding: 5% 3%;box-sizing: border-box;
}
.u{
  width: 18%;padding: 2% 5%;box-sizing: border-box;background-color: #ABABAB;border-radius: 5px;color: white;margin-top: 2%;text-align: center;
}
.scrollbar2::-webkit-scrollbar{
  width: 10px;
}
.scrollbar2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  opacity: 0.2;
  background: #E0E0E8;
}
.scrollbar2::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  border-radius: 0;
  /* background: #414141; */
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: #414141;
}
.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #414141;
}

.van-popover[data-popper-placement=top] .van-popover__arrow{
  display: none;
}
.van-popover--light .van-popover__content{
  border-radius: 10px;
}

.van-field__button{
  display:flex;
  align-items: center;
}
pre{
  width: 100%;
  padding: 10px;
  background-color: #23241f;
  border-radius: 6px;
  color: #f8f8f2;
  box-sizing: border-box;
  overflow-x: auto;
}
.content p{
  margin: 2px 0;
}
.mj_content p{
  margin: 0px 0;
}
</style>
<script>
import { EventSourcePolyfill } from "event-source-polyfill";
import { marked } from 'marked';
import { Toast,Dialog,Popover,Uploader } from 'vant';
import UserIndexPC4Rechar from '../components/UserIndexPC4Rechar.vue';

export default {
  name: 'UserIndexPc',
  components: { 
    UserIndexPC4Rechar
  },
  data() {
    return {
      screenWidth:window.screen.width,
      screenHeight:document.documentElement.clientHeight,
      models:1,
      usershow:false,
      message: '',
      mj_message: '',
      showPopover: false,
      showPopover2: false,
      settingShow: false,
      imgShow:false,
      bl1:1,
      bl2:1,
      blChecked:false,
      imgChoose:1,
      ms_choose_num:1,
      tst:{
        content:''
      },
      tstHk: 1,
      tsw:{
        content:''
      },
      tphh:[],
      tphh_size:"70px",
      mj_loadFlag:false,
      mj_loadFlag2:false,
      mj_loadFlag3:false,
      loadShow:false,
      loadFlag:false,
      loadFlag2:false,
      loadFlag3:false,
      runingFlg:false,
      mj_runFlag:false,
      msgList: [],
      mjList: [],
      taskid_message:[],
      gptpay_token:'',
      sse: "",
      left_token:100,
      message_id:0,
      message_list:[],
      message_title:'New chat',
      user_info:[],
      qrcode_url:'',
      dialogShow: false,
      dialogShow2: false,
      dialogShow3: false,
      dialogShow4: false,
      nickname: '',
      headimage: '',
      out_trade_no: '',
      fileList: [
        // {
        //   url: 'https://img01.yzcdn.cn/vant/leaf.jpg',
        //   status: 'uploading',
        //   message: '上传中...',
        // },
      ],
      recharge_money: '',
      recharge_list:[
        {
          'money':10,
          'yj':200,
          'flag':false,
        },
        {
          'money':30,
          'yj':600,
          'flag':false,
        },
        {
          'money':50,
          'yj':1000,
          'flag':false,
        }
      ],
      timer:'',
      popover_del_flag: false,
      right_icon_no:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/send_no.png",
      right_icon_send:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/send.png",
      right_icon_stop:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/stop.png",
      right_icon:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/send_no.png",
      mj_right_icon:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/send_no.png",
      // right_icon:'wait',
      eventS:'',
      timers: '',
      payType: "1",           //1支付宝支付  2微信支付
      payMsg: "请使用支付宝扫码支付",
      nav_choose_num: 1,
      message_type:1,
      recharge_type:'ci',
      no_content: '<div style="width: 100%;background-color: #F1F1F1;border-radius: 5px;text-align: left;box-sizing: border-box;margin-top:10px;"><div style="width:100%;display:flex;justify-content: space-between;flex-wrap: wrap;border-radius: 5px;overflow:hidden;position: relative;border-radius: 15px;"><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;" ></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;"></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;" ></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;" ></div><div style="position: absolute;left: 0;top: 0;width: 50%;height: 50%;border-right: 1px solid #F4F3F5;border-bottom: 1px solid #F4F3F5;"></div><div style="position: absolute;right: 0;top: 0;width: 50%;height: 50%;border-bottom: 1px solid #F4F3F5;"></div><div style="position: absolute;left: 0;bottom: 0%;width: 50%;height: 50%;border-right: 1px solid #F4F3F5;"></div><div style="position: absolute;right: 0;bottom: 0%;width: 50%;height: 50%;"></div></div><div style="width: 100%;text-align: right;margin-top: 10px;color: #6E6E6E;">2023-10-22 12:22</div></div>',
      keyCode:0,
      keyTime:null,
      page:1,
      ago_page:0,
      total:0,
      refreshLoad: false,
      allImgShow:false,
      allImg:'',
      soraList: [],
      is_recharge:false,
    }
  },
  watch:{
    message(val,newval){
      if(val){
        this.right_icon = this.right_icon_send;
      }else{
        this.right_icon = this.right_icon_no;
      }
    },
    mj_message(val,newval){
      if(val){
        setTimeout(()=>{
          var van_field_f = this.$refs.van_field_f_mj;
          this.$refs.settingimg.style.bottom = (van_field_f.offsetHeight + 58) + 'px';
          this.mj_right_icon = this.right_icon_send;
        },33);
        
      }else{
        var van_field_f = this.$refs.van_field_f_mj;
        this.$refs.settingimg.style.bottom = (van_field_f.offsetHeight + 58) + 'px';
        this.mj_right_icon = this.right_icon_no;
      }
    }
  },
  mounted(){
    // var gptpay_token = this.$route.query.gptpay_token;
    // if(gptpay_token){
    //   localStorage.setItem("gptpay_token", gptpay_token);
    // }
    this.gptpay_token = localStorage.getItem("gptpay_token");
    if(!this.gptpay_token){
      this.loginout();
    }
    var rendererMD = new marked.Renderer();
    marked.setOptions({
      renderer: rendererMD,
      gfm: true,
      tables: true,
      breaks: false,
      pedantic: false,
      sanitize: false,
      smartLists: true,
      smartypants: false,
      mangle:false,
      headerIds:false
    });//基本设置

    this.mjList = [
      {
        'content': '请描述您对绘图的要求。尽可能详细地描述您所需要的绘画风格、颜色主题、情感、环境等方面的要求，以便智能绘画师能够更好地满足您的需求。约1-3分钟出图。',
        'createtime': '2023-11-01 10:21'
      },
      // {
      //   'content': '<div style="width: 100%;background-color: #F1F1F1;border-radius: 5px;text-align: left;padding: 10px;box-sizing: border-box;"><img src="http://154.39.81.130:1000/mj/attachments/1153921895717163019/1165685155273392228/metavienna_A_red_dog._9959144c-b2de-43d1-b12b-b70e4c9dc69f.png" style="width: 100%;border-radius: 15px;"><div style="width: 100%;text-align: right;margin-top: 10px;color: #6E6E6E;">2023-10-22 12:22</div></div><div style="width: 100%;display: flex;flex-wrap: wrap;padding: 0;box-sizing: border-box;" id="asfas123123"><div style="" class="u">U1</div><div style="margin-left: 2%;" class="u">U2</div><div style="margin-left: 2%;" class="u">U3</div><div style="margin-left: 2%;margin-top: 2%;" class="u">U4</div><div style="margin-left: 2%;margin-top: 2%;" class="u reset"></div><div style="" class="u">V1</div><div style="margin-left: 2%;" class="u">V2</div><div style="margin-left: 2%;" class="u">V3</div><div style="margin-left: 2%;" class="u">V4</div></div>'
      // },
      // {
      //   'content': '<div style="width: 100%;background-color: #F1F1F1;border-radius: 5px;text-align: left;padding: 10px;box-sizing: border-box;"><div style="width:100%;display:flex;justify-content: space-between;flex-wrap: wrap;border-radius: 5px;overflow:hidden;position: relative;border-radius: 15px;"><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: 50%;" ></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: 50%;"></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: 50%;" ></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: 50%;" ></div><div style="position: absolute;left: 0;top: 0;width: 50%;height: 50%;border-right: 1px solid #F4F3F5;border-bottom: 1px solid #F4F3F5;"></div><div style="position: absolute;right: 0;top: 0;width: 50%;height: 50%;border-bottom: 1px solid #F4F3F5;"></div><div style="position: absolute;left: 0;bottom: 0%;width: 50%;height: 50%;border-right: 1px solid #F4F3F5;"></div><div style="position: absolute;right: 0;bottom: 0%;width: 50%;height: 50%;"></div></div><div style="width: 100%;text-align: right;margin-top: 10px;color: #6E6E6E;">2023-10-22 12:22</div></div>'
      // }
      // {
      //   'role': 'as',
      //   'content': '一只棕色的猫' + '<img src="http://154.39.81.130:1000/mj/attachments/1153921895717163019/1165685155273392228/metavienna_A_red_dog._9959144c-b2de-43d1-b12b-b70e4c9dc69f.png?ex=6547bfe6&is=65354ae6&hm=505766c94a4a465397709844ba132ad8127f14fb78639626c5ee0e0ec1fd8831&" style="width: 100%;margin-top:10px;border-radius: 15px;">',
      //   'createtime': '2023-11-01 10:21'
      // },
      // {
      //   'role': 'as',
      //   'content': '一只棕色的猫' + that.no_content,
      //   'createtime': '2023-11-01 10:21'
      // },
    ];
    setTimeout(()=>{
      for(let val in this.mjList){
        var container = this.$refs.mj_content[val];
        var replace = this.mjList[val]['content'].replace('.','。');
        container.innerHTML = marked.parse(replace);
      }
      // var button = document.getElementsByClassName('aabb');
      // for(let val of button){
      //   val.addEventListener("click", function() {
      //     that.allImgShow = true;
      //     that.allImg = this.src;
      //   });
      // }
      this.scroend();
    },100);

    this.get_userinfo();
    this.get_chatpay_message(false, 1);
    this.get_mj_message();
    if(this.models != 3){
      setInterval(()=>{
        var van_field_f = this.$refs.van_field_f_mj;
        this.$refs.settingimg.style.bottom = (van_field_f.offsetHeight + 58) + 'px';
        // this.mj_right_icon = this.right_icon_send;
        // if(this.mj_message){
        //   this.mj_right_icon = this.right_icon_send;
        // }else{
        //   this.mj_right_icon = this.right_icon_send;
        // }
      },33);
    }

  },
  methods: {
    onRefresh(){
      var that = this;
      that.get_mj_message(that.page);
    },
    getSortList(){
      var that = this;
      var page = 1;
      if(that.is_recharge == true){
          page = 2;
      }

      var sora_list_url = that.global.apiUrl + "/api/sora/soraList";
      that.axios.post(sora_list_url,{
        page: page
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        if(res.data.code==1){
          that.soraList = res.data.data.list
          // if(res.data.data.sora == 1){
          //   that.is_recharge = true
          // }
        }

      });
    },
    get_mj_message(page=1){
      var that = this;
      that.axios.get(that.global.apiUrl + "/api/customer/get_mj_message",{
        headers:{
          'content-type': 'application/json',
          'token':that.gptpay_token
        },
        params:{
          'page':page
        }
      }).then(function(res){
        that.refreshLoad = false;
        if(res.data.code==1){
          that.total = res.data.data.total;
          if(res.data.data.list){
            if(that.ago_page != that.page){
              if(page==1){
                // that.taskid_message.push({
              //     id: task_id,
              //     message: message,
              //     messages: messages,
              //     imgstr: imgstr
              //   })
                for(var i=0;i<res.data.data.list.length;i++){
                  that.mjList.push(res.data.data.list[i]);
                  if(res.data.data.list[i].message_data!=null){
                    var aa = res.data.data.list[i].message_data.split(',-+!@');
                      that.taskid_message.push({
                      id: aa[0],
                      message: aa[1],
                      messages: aa[2],
                      imgstr: aa[3]
                    })
                  }
                }
              }else{
                for(var j=res.data.data.list.length-1; j>=0;j--){
                  that.mjList.splice(1, 0, res.data.data.list[j]);
                }
              }
              that.ago_page = that.page;
              if(res.data.data.list.length>=10) that.page++;
            }
          }
          // that.msgList = res.data.data.list;
          setTimeout(()=>{
            for(let val in that.mjList){
              var container = that.$refs.mj_content[val];
              var replace = that.mjList[val]['content'].replace('.','。');
              container.innerHTML = marked.parse(replace);
            }
            var button = document.getElementsByClassName('u');
            for(let val of button){
              val.addEventListener("click", function() {
                var num = this.innerHTML.slice(1,2);
                var taskid = this.parentNode.id;
                if(!this.innerHTML.slice(0,1)){
                  that.resetImg(taskid);
                }else{
                  if(this.innerHTML.slice(0,1)=='U'){
                    that.getBigImg(num, taskid);
                  }else if(this.innerHTML.slice(0,1)=='V'){
                    that.kzImg(num, taskid);
                  }
                }
              });
            }
            var allimg = document.getElementsByClassName('allimg');
            for(let val of allimg){
              val.addEventListener("click", function() {
                that.allImgShow = true;
                that.allImg = this.src
              });
            }
            // that.scroend();
          },33);
        }
        that.$forceUpdate();
      }).catch(function(err){
        if(err.response.status==400){
          that.loginout();
        }
      });
    },
    key_down2(val){
      var that = this;
      if (val.keyCode == 13) {// keyCode=13为回车键     
        if (that.keycode == 0) {// keyCode=16为shift键
          setTimeout(()=>{
            that.message = that.message.substring(0, that.message.length - 1);
          },1);
          that.keyTime = setTimeout(()=>{
            if(!that.runFlag && that.right_icon != 'wait'){
              that.chatqaAdd(that.message);
            }
          },500);
        }else if (that.keycode == 16) {// keyCode=16为shift键
          clearTimeout(that.keyTime);
          // that.mj_message = that.mj_message.slice(0,that.mj_message.length-1);
          // console.log(that.mj_message);
        }else  {
          val.preventDefault(); //阻止默认事件
        }
      }else if (val.keyCode == 16) {// keyCode=13为回车键          
        if (that.keycode == 13) {// keyCode=16为shift键
          clearTimeout(that.keyTime);
        }else {
          val.preventDefault(); //阻止默认事件
        }
      }
      that.keycode = val.keyCode;
      setTimeout(()=>{
        that.keycode = 0;
      },500)
    },
    key_down(val){
      var that = this;
      if (val.keyCode == 13) {// keyCode=13为回车键     
        if (that.keycode == 0) {// keyCode=16为shift键
          setTimeout(()=>{
            that.mj_message = that.mj_message.substring(0, that.mj_message.length - 1);
          },1);
          that.keyTime = setTimeout(()=>{
            if(!that.mj_runFlag && that.mj_right_icon != 'wait'){
                that.playTu(that.mj_message);
            }
          },500);
        }else if (that.keycode == 16) {// keyCode=16为shift键
          clearTimeout(that.keyTime);
          // that.mj_message = that.mj_message.slice(0,that.mj_message.length-1);
          // console.log(that.mj_message);
        }else  {
          val.preventDefault(); //阻止默认事件
        }
      }else if (val.keyCode == 16) {// keyCode=13为回车键          
        if (that.keycode == 13) {// keyCode=16为shift键
          clearTimeout(that.keyTime);
        }else {
          val.preventDefault(); //阻止默认事件
        }
      }
      that.keycode = val.keyCode;
      setTimeout(()=>{
        that.keycode = 0;
      },500)
    },
    resetImg(taskId){
      var that = this;
      Dialog.confirm({
        title: '',
        message: '是否重新生成一张新图？',
      })
      .then(() => {
        if(that.imgStatus){
          Toast({
            type:'fail',
            message:'正在排队中，请稍后再试'
          })
          return ;
        }
        // on confirm
        that.loadShow = true;
        that.mj_right_icon = 'wait';
        that.usershow = false;
        that.settingShow = false;
        that.imgShow = false;
        that.mj_runFlag = true;
        that.axios.post(that.global.apiUrl + "/api/common/mj_change",{
          id: taskId,
          index: 0,
          action: 'REROLL'
        },{
          headers:{
            'content-type': 'application/json',
            'token': that.gptpay_token
          }
        }).then(function(res){
          that.loadShow = false;
          that.allImgShow = false;
          if(res.data.code==1){
            var task_id = res.data.data.result;
            var createtime = res.data.data['createtime'];
            that.imgStatus = true;
            that.mjList.push({
              content: '',
              createtime: createtime
            })
            var message = '';
            var messages = '';
            var imgstr = '';
            for(var i=0;i<that.taskid_message.length;i++){
              if(that.taskid_message[i].id==taskId){
                message = that.taskid_message[i].message;
                messages = that.taskid_message[i].messages;
                imgstr = that.taskid_message[i].imgstr;
                break;
              }
            }
            setTimeout(()=>{
              var content = messages + that.no_content;
              // '<img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: '+ that.contentWidth +'px;margin-top: 10px;border-radius: 5px;">' + '<div style="width:100%;color:#A3A3A3;font-size:80%;margin-top: 10px;display:flex;justify-content: space-between;"><div style="color:#EC7E8C;font-size:80%;"> 正在排队......</div> <div>' + createtime + '</div></div>';
              var l = that.$refs.mj_content.length;
              var container = that.$refs.mj_content[l-1];
              container.innerHTML = marked.parse(content);
              setTimeout(()=>{
                that.scroend();
              },200);
            },500);
            that.getTask(task_id, message, 2, imgstr);
            that.taskid_message.push({
              id: task_id,
              message: message,
              messages: messages,
              imgstr: imgstr
            })
            // that.timer = setInterval(()=>{
            //   that.getTask(task_id, '', 2);
            // },10000);
          }else{
            that.mj_loadFlag = false;
            that.mj_runFlag = false;
            that.mj_right_icon = that.right_icon_send;
            that.mj_message = '';
            Toast.fail(res.data.msg);
          }
        })
      })
      .catch(() => {
        // on cancel
        return ;
      });
    },
    kzImg(value, taskId){
      var that = this;
      Dialog.confirm({
        title: '',
        message: '是否根据' + value + '号图片扩展一张新图？',
      })
      .then(() => {
        if(that.imgStatus){
          Toast({
            type:'fail',
            message:'正在排队中，请稍后再试'
          })
          return ;
        }
        // on confirm
        that.loadShow = true;
        that.mj_right_icon = 'wait';
        that.usershow = false;
        that.settingShow = false;
        that.imgShow = false;
        that.mj_runFlag = true;
        that.axios.post(that.global.apiUrl + "/api/common/mj_change",{
          id: taskId,
          index: value,
          action: 'VARIATION'
        },{
          headers:{
            'content-type': 'application/json',
            'token': that.gptpay_token
          }
        }).then(function(res){
          that.loadShow = false;
          that.allImgShow = false;
          if(res.data.code==1){
            var task_id = res.data.data.result;
            var createtime = res.data.data['createtime'];
            that.imgStatus = true;
            that.mjList.push({
              content: '',
              createtime: createtime
            })
            var message = '';
            var messages = '';
            var imgstr = '';
            for(var i=0;i<that.taskid_message.length;i++){
              if(that.taskid_message[i].id==taskId){
                message = that.taskid_message[i].message;
                messages = that.taskid_message[i].messages;
                imgstr = that.taskid_message[i].imgstr;
                break;
              }
            }
            setTimeout(()=>{
              var content = messages + that.no_content;
              // '<img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: '+ that.contentWidth +'px;margin-top: 10px;border-radius: 5px;">' + '<div style="width:100%;color:#A3A3A3;font-size:80%;margin-top: 10px;display:flex;justify-content: space-between;"><div style="color:#EC7E8C;font-size:80%;"> 正在排队......</div> <div>' + createtime + '</div></div>';
              var l = that.$refs.mj_content.length;
              var container = that.$refs.mj_content[l-1];
              container.innerHTML = marked.parse(content);
              setTimeout(()=>{
                that.scroend();
              },200);
            },500);
            that.getTask(task_id, message, 2, imgstr);
            that.taskid_message.push({
              id: task_id,
              message: message,
              messages: messages,
              imgstr: imgstr
            })
          }else{
            that.mj_loadFlag = false;
            that.mj_runFlag = false;
            that.mj_right_icon = that.right_icon_send;
            that.mj_message = '';
            Toast.fail(res.data.msg);
          }
        })
      })
      .catch(() => {
        // on cancel
        return ;
      });
    },
    getBigImg(value, taskId){
      var that = this;
      Dialog.confirm({
        title: '',
        message: '是否获取' + value + '号大图？',
      })
      .then(() => {
        if(that.imgStatus){
          Toast({
            type:'fail',
            message:'正在排队中，请稍后再试'
          })
          return ;
        }
        // on confirm
        that.loadShow = true;
        that.mj_right_icon = 'wait';
        that.usershow = false;
        that.settingShow = false;
        that.imgShow = false;
        that.mj_runFlag = true;
        that.axios.post(that.global.apiUrl + "/api/common/mj_change",{
          id: taskId,
          index: value,
          action: 'UPSCALE'
        },{
          headers:{
            'content-type': 'application/json',
            'token': that.gptpay_token
          }
        }).then(function(res){
          that.loadShow = false;
          if(res.data.code==1){
            var task_id = res.data.data.result;
            var createtime = res.data.data['createtime'];
            that.imgStatus = true;
            that.mjList.push({
              content: '',
              createtime: createtime
            })
            var message = '';
            var messages = '';
            var imgstr = '';
            for(var i=0;i<that.taskid_message.length;i++){
              if(that.taskid_message[i].id==taskId){
                message = that.taskid_message[i].message;
                messages = that.taskid_message[i].messages;
                imgstr = that.taskid_message[i].imgstr;
                break;
              }
            }
            setTimeout(()=>{
              var content = messages + that.no_content
              var l = that.$refs.mj_content.length;
              var container = that.$refs.mj_content[l-1];
              container.innerHTML = marked.parse(content);
              setTimeout(()=>{
                that.scroend();
              },200);
            },500);
            
            that.getTask(task_id, message, 1, imgstr);
          }else{
            that.mj_loadFlag = false;
            that.mj_runFlag = false;
            that.mj_right_icon = that.right_icon_send;
            that.mj_message = '';
            Toast.fail(res.data.msg);
          }
        })
      })
      .catch(() => {
        // on cancel
        return ;
      });
    },
    tphhAfter(file){
      var that = this;
      for(var i=0;i<that.tphh.length;i++){
        if(!Reflect.has(that.tphh[i], "imgurl")){
          that.tphhAjax(i);
        }
      }
    },
    tphhAjax(i){
      var that = this;
      that.loadShow = true;
      var files = that.tphh[i].file;
      const formData = new FormData(); // 下面有备注
      formData.append('file', files);
      that.axios.post(that.global.apiUrl + "/index.php/admin/index/upload", formData, {
        headers: {
            // 注意修改请求头file格式
          'Content-Type': 'multipart/form-data',
        },
      }).then(function(res){
          if(res.data.code==1){
            that.tphh[i].imgurl = res.data.data.file.full_url;
          }else{
            Toast({
              type:'fail',
              message:'上传失败'
            })
          }
          that.loadShow = false;
      })
    },
    tswAfter(file){
      this.tsw = file;
    },
    tstAfter(file){
      var that = this;
      that.loadShow = true;
      that.tst = file;
      const formData = new FormData(); // 下面有备注
      formData.append('file', file.file);
      that.axios.post(that.global.apiUrl + "/index.php/admin/index/upload", formData, {
        headers: {
            // 注意修改请求头file格式
          'Content-Type': 'multipart/form-data',
        },
      }).then(function(res){
          if(res.data.code==1){
            that.tst.imgurl = res.data.data.file.full_url;
          }else{
            Toast({
              type:'fail',
              message:'上传失败'
            })
          }
          that.loadShow = false;
      })
    },
    tphhPlay(){
      var that = this;
      if(that.tphh.length<=1){
        Toast({
          type:'fail',
          message:'请上传至少2张图片'
        })
        return ;
      }
      if(that.imgStatus){
        Toast({
          type:'fail',
          message:'正在排队中，请稍后再试'
        })
        return ;
      }
      that.imgShow = false;
      that.loadShow = true;
      var base64Array = [];
      var message = "";
      var messages = " (正在排队......) </br>";
      var imgstr = "";
      for(var i=0;i<that.tphh.length;i++){
        base64Array.push(that.tphh[i].content);
        // message = message + '<a>'+ that.tphh[i].imgurl +'</a></br>';
        messages = messages + '<a>'+ that.tphh[i].imgurl +'</a></br>';
        imgstr += '<a>'+ that.tphh[i].imgurl +'</a></br>';
      }
      that.mj_right_icon = 'wait';
      that.usershow = false;
      that.settingShow = false;
      that.imgShow = false;
      that.mj_runFlag = true;
      that.axios.post(that.global.apiUrl + "/api/common/mj_tphh",{
        base64: base64Array
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.loadShow = false;
        if(res.data.code==1){
          var task_id = res.data.data.result;
          var createtime = res.data.data['createtime'];
          that.imgStatus = true;
          that.mjList.push({
            content: '',
            createtime: createtime
          })
          setTimeout(()=>{
            var content = messages + that.no_content;
            var l = that.$refs.mj_content.length;
            var container = that.$refs.mj_content[l-1];
            container.innerHTML = marked.parse(content);
            setTimeout(()=>{
              that.scroend();
            },200);
          },500);
          that.getTask(task_id, message, 0, imgstr);
          that.taskid_message.push({
            id: task_id,
            message: message,
            messages: messages,
            imgstr: imgstr
          })
          // that.timer = setInterval(()=>{
          //   that.getTask(task_id, message);
          // },10000);
        }else{
          that.mj_loadFlag = false;
          that.mj_runFlag = false;
          that.mj_right_icon = that.right_icon_send;
          that.mj_message = '';
          Toast.fail(res.data.msg);
        }
      })
    },
    tswPlay(){
      var that = this;
      if(that.tsw.content.length==0){
        Toast({
          type:'fail',
          message:'请先上传图片'
        })
        return ;
      }
      if(that.imgStatus){
        Toast({
          type:'fail',
          message:'正在排队中，请稍后再试'
        })
        return ;
      }
      that.imgShow = false;
      that.loadShow = true;
      that.usershow = false;
      that.settingShow = false;
      that.imgShow = false;
      that.mj_right_icon = 'wait';
      that.mj_runFlag = true;
      that.axios.post(that.global.apiUrl + "/api/common/mj_tsw",{
        base64: that.tsw.content
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.loadShow = false;
        if(res.data.code==1){
          var task_id = res.data.data.result;
          var createtime = res.data.data['createtime'];
          that.imgStatus = true;
          that.mjList.push({
            content: '',
            createtime: createtime
          })
          setTimeout(()=>{
            var content = ' (正在排队......)</br>' + that.no_content; 
            // '<img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: '+ that.contentWidth +'px;margin-top: 10px;border-radius: 5px;">' + '<div style="width:100%;color:#A3A3A3;font-size:80%;margin-top: 10px;display:flex;justify-content: space-between;"><div style="color:#EC7E8C;font-size:80%;"> 正在排队......</div> <div>' + createtime + '</div></div>';
            var l = that.$refs.mj_content.length;
            var container = that.$refs.mj_content[l-1];
            container.innerHTML = marked.parse(content);
            setTimeout(()=>{
              that.scroend();
            },200);
          },500);
          that.getTask(task_id);
          // that.timer = setInterval(()=>{
          //   that.getTask(task_id);
          // },3000);
        }else{
          that.mj_loadFlag = false;
          that.mj_runFlag = false;
          that.mj_right_icon = that.right_icon_send;
          that.mj_mmessage = '';
          Toast.fail(res.data.msg);
        }
      })
    },
    playTu(message){
      var that = this;
      var prompt = message;
      var hz = '';
      if(that.imgStatus){
        Toast({
          type:'fail',
          message:'正在排队中，请稍后再试'
        })
        return ;
      }
      if(that.ms_choose_num==2){
        let reg = new RegExp("[\\u4E00-\\u9FFF]+","g")
        if(reg.test(message)){
          Toast({
            type:'fail',
            message:'在专业模式下不能包含中文'
          })
          return ;
        }
      }else{
        // var fgys_value = 500;
        // if(that.tstHk==1) fgys_value = 10;
        // else if(that.tstHk==2) fgys_value = 500;
        // else if(that.tstHk==3) fgys_value = 999;
        hz = " --ar " + that.bl1 + ":" + that.bl2 + " --iw " + that.tstHk;
      }
      var blChecked = false;
      if(that.ms_choose_num==2) blChecked = true;
      that.loadShow = true;
      that.usershow = false;
      that.settingShow = false;
      that.imgShow = false;
      if(!that.mj_runFlag && that.mj_right_icon==that.right_icon_send){
        that.mj_runFlag = true;
        that.mj_right_icon = 'wait';
        that.axios.post(that.global.apiUrl + "/api/common/mj_tst",{
          base64: that.tst.content,
          prompt: prompt,
          hz: hz,
          is_zy: blChecked
        },{
          headers:{
            'content-type': 'application/json',
            'token': that.gptpay_token
          }
        }).then(function(res){
          that.loadShow = false;
          if(res.data.code==1){
            var createtime = res.data.data['createtime'];
            that.imgStatus = true;
            that.mjList.push({
              content: '',
              createtime: createtime
            });
            var messages = message  + ' (正在排队......)</br>';
            var imgstr = '';
            if(that.tst.imgurl) {
              messages = message + ' (正在排队......) </br><a>'+ that.tst.imgurl +'</a>';
              imgstr = ' </br><a>'+ that.tst.imgurl +'</a>';
            }
            setTimeout(()=>{
              var content = messages + that.no_content;
              var l = that.$refs.mj_content.length;
              var container = that.$refs.mj_content[l-1];
              container.innerHTML = marked.parse(content);
              setTimeout(()=>{
                that.scroend();
              },400);
            },500);

            var task_id = res.data.data.result;
            
            that.getTask(task_id, message, 0, imgstr);
            that.taskid_message.push({
              id: task_id,
              message: message,
              messages: messages,
              imgstr: imgstr
            })
          }else{
            that.mj_loadFlag = false;
            that.mj_runFlag = false;
            that.mj_right_icon = that.right_icon_send;
            that.mj_message = '';
            Toast.fail(res.data.msg);
          }
        })
      }else if(!that.mj_runFlag && that.mj_right_icon==that.right_icon_stop){
        that.mj_runFlag = false;
        that.mj_right_icon = that.right_icon_send;
      }
    },
    getTask(task_id, message='', is_change=0, imgstr=''){
      var that = this;
      that.axios.get(that.global.apiUrl + "/api/common/get_task",{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        },
        params:{
          'id': task_id
        }
      }).then(function(res){
        if(res.data.code==1){
          if(res.data.data.status=="SUCCESS"){
            clearInterval(that.timer);
            var finishTime = res.data.data.finishTime;
            var imageUrl = res.data.data.imageUrl;
            var taskId = res.data.data.id;
            that.getImg(imageUrl, finishTime, is_change, taskId, message, res.data.data.prompt, imgstr);
            
          }else if(res.data.data.status=="IN_PROGRESS" || res.data.data.status=="SUBMITTED" || res.data.data.status=="NOT_START"){
            if(res.data.data.progress == "Stopped"){
              that.mjList.pop();
              Toast({
                type: 'fail',
                message: '图片混合失败'
              })
              that.mj_loadFlag = false;
              that.mj_runFlag = false;
              that.mj_right_icon = that.right_icon_send;
            }else{
              if(res.data.data.status=="IN_PROGRESS"){
                var content = '';
                var l = that.$refs.mj_content.length;
                var container = that.$refs.mj_content[l-1];
                if(that.imgChoose==3) content = message + '  ( '+ res.data.data.progress +' )  </br>' + imgstr + that.no_content;
                else content = message + '  ( '+ res.data.data.progress +' )  ' + imgstr + that.no_content;
                container.innerHTML = marked.parse(content);
              }
              setTimeout(()=>{
                that.getTask(task_id, message, is_change, imgstr);
              },5000)
            }
          }else if(res.data.data.status=="FAILURE" || res.data.data.progress=="Stopped"){
            that.mjList.pop();
            Toast({
              type: 'fail',
              message: res.data.data.failReason
            })
            that.mj_loadFlag = false;
            that.mj_runFlag = false;
            that.mj_right_icon = that.right_icon_send;
          }
        }
      }).catch(function(err){
        console.log(err);
        if(err.response.status==400){
          that.loginout();
        }else{
          that.mjList.pop();
        }
      });
    },

    getImg(imageUrl, finishTime, is_change, taskId, message, prompt, imgstr){
      var that = this;
      var is_getbig = false;
      if(is_change==1){
        is_getbig = true;
      }
      that.axios.post(that.global.apiUrl + "/api/common/getimg",{
        url: imageUrl,
        is_getbig: is_getbig
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.mj_loadFlag = false;
        that.mj_runFlag = false;
        that.mj_right_icon = that.right_icon_send;
        
        var img = res.data.data.imgurl;
        var content = '';
        var l = that.$refs.mj_content.length;
        var container = that.$refs.mj_content[l-1];
        if(is_change==1){
          
          content = message + imgstr + '<div style="width: 100%;background-color: #F1F1F1;border-radius: 5px;text-align: left;box-sizing: border-box;margin-top:10px;"><img src="' + img + '" style="width: 100%;border-radius: 15px;" class="allimg"><div style="width: 100%;text-align: right;margin-top: 6px;color: #6E6E6E;">'+ finishTime +'</div></div>'
        }else if(is_change==2){
          that.tsw = {content:''};
          content = message + imgstr + '<div style="width: 100%;background-color: #F1F1F1;border-radius: 5px;text-align: left;box-sizing: border-box;margin-top:10px;"><img src="' + img + '" style="width: 100%;border-radius: 15px;" class="allimg"><div style="width: 100%;text-align: right;margin-top: 6px;color: #6E6E6E;">'+ finishTime +'</div></div><div style="width: 100%;display: flex;flex-wrap: wrap;padding: 0;box-sizing: border-box;" id="' + taskId + '"><div style="" class="u">U1</div><div style="margin-left: 2%;" class="u">U2</div><div style="margin-left: 2%;" class="u">U3</div><div style="margin-left: 2%;margin-top: 2%;" class="u">U4</div><div style="margin-left: 2%;margin-top: 2%;" class="u reset"></div><div style="" class="u">V1</div><div style="margin-left: 2%;" class="u">V2</div><div style="margin-left: 2%;" class="u">V3</div><div style="margin-left: 2%;" class="u">V4</div></div>'
        }else{
          if(that.imgChoose==1){
            that.mj_loadFlag = false;
            that.mj_runFlag = false;
            that.mj_right_icon = that.right_icon_send;
            that.mj_message = '';
            that.tst = {content:''};

            content = message + imgstr + '<div style="width: 100%;background-color: #F1F1F1;border-radius: 5px;text-align: left;box-sizing: border-box;margin-top:10px;"><img src="' + img + '" style="width: 100%;border-radius: 15px;" class="allimg"><div style="width: 100%;text-align: right;margin-top: 6px;color: #6E6E6E;">'+ finishTime +'</div></div><div style="width: 100%;display: flex;flex-wrap: wrap;padding: 0;box-sizing: border-box;" id="' + taskId + '"><div style="" class="u">U1</div><div style="margin-left: 2%;" class="u">U2</div><div style="margin-left: 2%;" class="u">U3</div><div style="margin-left: 2%;margin-top: 2%;" class="u">U4</div><div style="margin-left: 2%;margin-top: 2%;" class="u reset"></div><div style="" class="u">V1</div><div style="margin-left: 2%;" class="u">V2</div><div style="margin-left: 2%;" class="u">V3</div><div style="margin-left: 2%;" class="u">V4</div></div>'
          }else if(that.imgChoose==2){
            that.tsw = {content:''};
            message = prompt;
            
            content = message + imgstr + '<div style="width: 100%;background-color: #F1F1F1;border-radius: 5px;text-align: left;box-sizing: border-box;margin-top:10px;"><img src="' + img + '" style="width: 100%;border-radius: 15px;" class="allimg"><div style="width: 100%;text-align: right;margin-top: 6px;color: #6E6E6E;">'+ finishTime +'</div></div>'
          }else if(that.imgChoose==3){
            that.tphh = [];

            content = message + imgstr + '<div style="width: 100%;background-color: #F1F1F1;border-radius: 5px;text-align: left;box-sizing: border-box;margin-top:10px;"><img src="' + img + '" style="width: 100%;border-radius: 15px;" class="allimg"><div style="width: 100%;text-align: right;margin-top: 6px;color: #6E6E6E;">'+ finishTime +'</div></div><div style="width: 100%;display: flex;flex-wrap: wrap;padding: 0;box-sizing: border-box;" id="' + taskId + '"><div style="" class="u">U1</div><div style="margin-left: 2%;" class="u">U2</div><div style="margin-left: 2%;" class="u">U3</div><div style="margin-left: 2%;margin-top: 2%;" class="u">U4</div><div style="margin-left: 2%;margin-top: 2%;" class="u reset"></div><div style="" class="u">V1</div><div style="margin-left: 2%;" class="u">V2</div><div style="margin-left: 2%;" class="u">V3</div><div style="margin-left: 2%;" class="u">V4</div></div>'
          }
        }
        container.innerHTML = marked.parse(content);
        setTimeout(()=>{
          var data = '';
          for(var i=0;i<that.taskid_message.length;i++){
            if(that.taskid_message[i].id==taskId){
              data = taskId + ',-+!@' + that.taskid_message[i].message + ',-+!@' + that.taskid_message[i].messages + ',-+!@' + that.taskid_message[i].imgstr;
              break;
            }
          }
          that.axios.post(that.global.apiUrl + "/api/customer/add_mj_message",{
            content: content,
            message_data: data
          },{
            headers:{
              'content-type': 'application/json',
              'token': that.gptpay_token
            }
          }).then(function(res){

          })
          that.get_userinfo();
          var button = document.getElementsByClassName('u');
          for(let val of button){
            val.addEventListener("click", function() {
              // that.allImgShow = true;
              // that.allImg = this.src;
              // that.taskId = this.id;
              var num = this.innerHTML.slice(1,2);
              var taskid = this.parentNode.id;
              if(!this.innerHTML.slice(0,1)){
                that.resetImg(taskid);
              }else{
                if(this.innerHTML.slice(0,1)=='U'){
                  that.getBigImg(num, taskid);
                }else if(this.innerHTML.slice(0,1)=='V'){
                  that.kzImg(num, taskid);
                }
              }
            });
          }
          var allimg = document.getElementsByClassName('allimg');
          for(let val of allimg){
            val.addEventListener("click", function() {
              that.allImgShow = true;
              that.allImg = this.src;
            });
          }
          that.scroend();
        },100);
        that.imgStatus = false;
      })
    },
    img_choose(num){
      this.imgChoose = num;
      var t_width = this.$refs.t_width;
      this.tphh_size = (t_width.offsetWidth-10) + "px";
    },
    blBlur(value){
      if(value==1){
        if(this.bl1.length==0) this.bl1 = 1;
      }else if(value==2){
        if(this.bl2.length==0) this.bl2 = 1;
      }
    },
    navChoose(num){
      this.message_type = num;
      this.nav_choose_num = num;
      this.get_chatpay_message(false,num);
      this.msgList = [];
      this.message_id = 0;
      this.message_title = 'New chat';
      this.showPopover = false;
      this.showPopover2 = false;
    },
    rechar_check_cannel(){
      this.loadFlag3 = false
      clearInterval(this.timer);
    },
    delMessage(id){
      var that = this;
      that.popover_del_flag = false;
      var ids = [id];
      if(!id){
        ids = [];
        for(let val of this.message_list){
          ids.push(val.id);
        }
      }
      that.axios.post(that.global.apiUrl+"/api/customer/del_chatgpt_message",{
        message_id: ids
      },{
        headers:{
          'content-type': 'application/json',
          'token': this.gptpay_token
        }
      }).then(function(res){
        if(res.data.code==1){
          that.get_chatpay_message();
          Toast({
            type:'success',
            message:res.data.msg
          })
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    popover(index,type){
      this.popover_del_flag = false;
      for(let val of this.message_list){
        val.popover = false;
      }
      if(type){
        this.message_list[index].popover = true;
        this.popover_del_flag = true;
      }
      this.$forceUpdate();
    },
    chooseRecharge(index,flag){
      for(let val of this.recharge_list){
        val.flag = false;
      }
      if(flag){
        if(index>=0){
          this.recharge_list[index].flag = true;
        }
      }
      clearInterval(this.timer);
    },
    upload(file){
      let that = this;
      const formData = new FormData(); // 下面有备注
      formData.append('filename', file.file);
      that.axios.post(that.global.apiUrl + "/api/common/upload", formData, {
          headers: {
                // 注意修改请求头file格式
              'Content-Type': 'multipart/form-data',
              'token': that.gptpay_token
          },
      }).then(function(res){
        that.headimage = res.data.data.file.full_url;
      })
    },
    scroend() {
      // 让滚动条始终在最底部
      this.$nextTick(() => {
        this.$refs.messageContent.scrollTop = this.$refs.messageContent.scrollHeight;
        this.$refs.mjContent.scrollTop = this.$refs.mjContent.scrollHeight;
      })
    },
    chatqaAdd(question){
      if(this.right_icon == this.right_icon_send){
        if(!this.loadFlag && !this.runingFlg){
          this.loadFlag = true;
          var answerContent='';
          var codeStart=false,lastWord='',lastLastWord='';
          for(let val of this.msgList){
            val.tokenFlag = false;
          }
          this.msgList.push({
            role: 'user',
            content: question,
            tokenFlag: false
          })
          this.scroend();
          this.right_icon = 'wait';
          // if(!this.message_id) this.message_id = parseInt(new Date()/1000) + this.randomString();
          // console.log(this.message_id);
          question = question.replace(/\+/g,"%2B"); 
          question = question.replace(/\//g,"%2F"); 
          question = question.replace(/\?/g,"%3F"); 
          var url = this.global.apiUrl + '/api/common/chatgpt?t=' + this.gptpay_token + '&q=' + encodeURI(question) + '&m=' + this.message_id + '&mt=' + this.message_type;
          this.eventS = new EventSourcePolyfill(url);
          this.eventS.onopen = (event) => {
            this.msgList.push({
              role: 'assistant',
              content: '',
              tokenFlag: true
            })
            this.loadFlag = false;
            this.runingFlg = true;
            this.message = '';
          };
          this.eventS.onClosed = (event) => {
            this.right_icon = this.right_icon_no;
            this.runingFlg = false;
            this.loadFlag = false;
            this.get_chatpay_message(true, this.message_type);
            this.get_userinfo();
          };
          this.eventS.onerror = (event) => {
            if (event.readyState === EventSource.CLOSED) {
              console.log("connection is closed");
            } else {
              if(event.status==400){
                Toast({
                  message: '身份验证失败，请重新登录',
                  type:'fail',
                  onClose:()=>{
                    this.loginout();
                  }
                });
              }else if(event.status==401){
                Toast.fail('余额不足请前往充值');
              }else if(event.status==402){
                Toast.fail('4.0次数不足请前往充值');
              }else if(event.status==403){
                Toast({
                  message: '平台4.0次数已不足请明天抢先使用',
                  type:'fail',
                });
              }
            }
            this.right_icon = this.right_icon_no;
            this.runingFlg = false;
            this.loadFlag = false;
            this.get_chatpay_message(true, this.message_type);
            this.get_userinfo();
            this.eventS.close();
            this.message = '';
          };
          this.eventS.addEventListener("message", (event) => {
            let json_data = JSON.parse(event.data);
            let content = json_data['content'];
            this.message_id = json_data['message_id'];
            if(this.right_icon != this.right_icon_stop) this.right_icon = this.right_icon_stop;
            
            /////////////////////token不足
            if(content != 'length'){
              if(content.indexOf('`') != -1){
                if(content.indexOf('```') != -1){
                    codeStart = !codeStart;
                }else if(content.indexOf('``') != -1 && (lastWord + content).indexOf('```') != -1){
                    codeStart = !codeStart;
                }else if(content.indexOf('`') != -1 && (lastLastWord + lastWord + content).indexOf('```') != -1){
                    codeStart = !codeStart;
                }
              }
              lastLastWord = lastWord;
              lastWord = content;
              answerContent += content;
              const l = this.$refs.content.length;
              const container = this.$refs.content[l-1];
              container.innerHTML = marked.parse(answerContent + (codeStart?'\n\n```':''));
              this.scroend();
            }
          });
        }
      }else if(this.right_icon == this.right_icon_stop){
        this.eventS.close();
        this.right_icon = this.right_icon_no;
        this.runingFlg = false;
        this.loadFlag = false;
        this.get_chatpay_message(true, this.message_type);
        this.get_userinfo();
      }
    },
    loginout(){
      localStorage.setItem("gptpay_token", '');
      Toast({
        type:'success',
        message:"退出登录成功",
        onClose: ()=>{
          this.$router.replace({path:'/'});
        }
      })
    },
    chooseChat(index){
      this.msgList = this.message_list[index].message;
      
      this.message_title = this.msgList[0]['content'];
      this.message_id = this.message_list[index].id;
      this.showPopover2 = false;
      setTimeout(()=>{
        this.showPopover = false;
        for(let val in this.msgList){
          var container = this.$refs.content[val];
          var replace = this.msgList[val]['content'].replace('.','。');
          container.innerHTML = marked.parse(replace);
        }
        this.scroend();
      },33);
      // {"role": "system", "content": "接下来不管我发什么内容，希望你充当一个对我爱答不理的女神，高冷的语气回复。我输入的内容是：--"},
    },
    get_userinfo(){
      var that = this;
      that.axios.get(that.global.apiUrl + "/api/customer/get_userinfo",{
        headers:{
          'content-type': 'application/json',
          'token':this.gptpay_token
        }
      }).then(function(res){
        that.user_info = res.data.data;
        if(res.data.data.sora == 1){
          that.is_recharge = true;
        }
        if(!that.user_info.headimage) that.user_info.headimage = 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/user.png';
      }).catch(function(err){
        if(err.response.status==400){
          that.loginout();
        }
      });
    },
    get_chatpay_message(flag, type){
      var that = this;
      that.axios.get(that.global.apiUrl + "/api/customer/get_chatgpt_message",{
        headers:{
          'content-type': 'application/json',
          'token':this.gptpay_token
        },
        params:{
          'type':type
        }
      }).then(function(res){
        if(res.data.code==1){
          that.message_list = res.data.data.list;
          for(let val of that.message_list){
            val.popover = false;
            if(val.role=='system') val.role = 'user';
          }
          if(flag){
            if(that.message_list.length>0){
              that.message_title = that.message_list[0].message[0]['content'];
            }
          }
        }
      });
    },
    edit(){
      var that = this;
      that.loadFlag2 = true;
      that.axios.post(that.global.apiUrl+"/api/customer/edit",{
        nickname: that.nickname,
        headimage: that.headimage
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.nickname = '';
        that.headimage = '';
        that.loadFlag2 = false;
        if(res.data.code==1){
          that.user_info = res.data.data;
          Toast({
            type:'success',
            message:"修改成功",
          })
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    changeNav(recharge_money, rechar_type_num, recharge_type){
      var that = this;
      that.recharge_money = recharge_money;
      that.payType = rechar_type_num;
      that.recharge_type = recharge_type;
      that.dialogShow2 = false;
      that.recharge();
    },
    recharge(){
      var that = this;
      that.loadFlag2 = true;
      that.showPopover = false;
      var recharge_url = "";
      if(that.payType=="1") {
        recharge_url = that.global.apiUrl + "/api/customer/recharge";
        that.payMsg = "请使用支付宝扫码支付";
      }else {
        recharge_url = that.global.apiUrl + "/api/customer/recharge_native_wx";
        that.payMsg = "请使用微信扫码支付";
      }
      that.axios.post(recharge_url,{
        recharge_money: that.recharge_money,
        recharge_type: that.recharge_type,
      },{
        headers:{
          'content-type': 'application/json',
          'token': this.gptpay_token
        }
      }).then(function(res){
        that.showPopover = false;
        for(let val of that.recharge_list){
            val.flag = false;
        }
        that.loadFlag2 = false;
        if(res.data.code==1){
          that.loadFlag3 = true;
          that.qrcode_url = res.data.data.qrcode_url;
          that.out_trade_no = res.data.data.out_trade_no;
          that.timer = setInterval(()=>{
            that.recharge_check();
          },5000)
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    recharge_check(){
      var that = this;
      var recharge_url = "";
      if(that.payType=="1") recharge_url = that.global.apiUrl + "/api/customer/recharge_check";
      else recharge_url = that.global.apiUrl + "/api/customer/recharge_check_wx";

      that.axios.post(recharge_url,{
        out_trade_no: that.out_trade_no
      },{
        headers:{
          'content-type': 'application/json',
          'token': this.gptpay_token
        }
      }).then(function(res){
        if(res.data.code==1){
          clearInterval(that.timer);
          if(that.recharge_type == 'sora'){
            that.is_recharge = true;
            that.getSortList();
          }
          that.loadFlag3 = false;
          that.qrcode_url = '';
          that.out_trade_no = '';
          Toast({
            'type':'success',
            'message':'充值成功',
            'duration':3000,
            'onClose':()=>{
              that.get_userinfo();
            }
          })
        }
        // console.log(res.data);
      });
    },
    newchat(){
      this.msgList = [];
      this.message_id = 0;
      this.message_title = 'New chat';
      this.showPopover = false;
      this.showPopover2 = false;
    },
    randomString(e) {
      e = e || 6;
      var t = "0123456789",
      a = t.length,
      n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.UserIndex {
  margin: 0;
  padding: 0;
  /* background-color: black; */
  /* display: flex;
  justify-content: center; */
  /* align-items: center; */
}
</style>