<template>
  <div style="overflow-y: hidden;">

    <!--------------------返回按钮-------------------->
    <van-icon name="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/back.png" size="35" style="position: fixed;left:5%;top: 3%;z-index: 1;" @click="toHH()"></van-icon>

    <!--------------------对话内容-------------------->
      <div style="width: 100%;height: 100%;overflow-y: auto;" ref="messageContent">
        <!-- <div style="width: 100%;height: 5%;"></div> -->
        <van-pull-refresh v-model="refreshLoad" @refresh="onRefresh" style="width: 100%;height: 8%;" pull-distance="20" v-show="msgList.length<total">
          <div style="height: 100%;width: 100%;display: flex;align-items: center;justify-content: center;color:#777777;font-size: 90%;">下拉此处加载历史记录</div>
        </van-pull-refresh>
        <div v-for="(item,index) in msgList" :key="`1`+index" style="width: 100%;padding:5vw;box-sizing: border-box;">
          <div style="width: 100%;text-align: left;" v-show="item.role!='user'">
            <div style="width: 100%;display: flex;align-items: center;padding: 2% 0;box-sizing: border-box;">
              <!-- <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/gpttx.png" alt="" style="width: 10%;">
              <div style="margin-left: 2%;font-size: 100%;"></div> -->
              <div style="width: 37px;height: 37px; background-color: #309F7E;color: #fff;border-radius: 50px;display: flex;align-items: center;justify-content: center;font-size: 80%;">
                <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/hhtx.png" alt="" style="width: 70%;">
              </div>
              <div style="color: #000;margin-left: 10px;">Midjourney</div>
              <div style="color: #9A9A9A;margin-left: 10px;font-size: 80%;position: relative;top: 1px;">{{ item.createtime }}</div>
            </div>
            <div ref="content" class="content">
              <!-- <img src="http://154.39.81.130:1000/mj/attachments/1153921895717163019/1165685155273392228/metavienna_A_red_dog._9959144c-b2de-43d1-b12b-b70e4c9dc69f.png" style="width: 100%;border-radius: 15px;">
              <div style="width: 100%;display: flex;flex-wrap: wrap;" id="asfas123123">
                <div style="" class="u">U1</div>
                <div style="margin-left: 2%;"  class="u">U2</div>
                <div style="margin-left: 2%;" class="u">U3</div>
                <div style="margin-left: 2%;margin-top: 2%;" class="u">U4</div>
                <div style="margin-left: 2%;margin-top: 2%;" class="u reset">
                </div>
                <div style="" class="u">V1</div>
                <div style="margin-left: 2%;" class="u">V2</div>
                <div style="margin-left: 2%;" class="u">V3</div>
                <div style="margin-left: 2%;" class="u">V4</div>
              </div> -->
              <!-- <div style="position: absolute;left: 50%;top:0;width: 1px;height: 100%;background-color: white;"></div>
              <div style="position: absolute;left: 0%;top:50%;width: 100%;height: 1px;background-color: white;"></div> -->

              <!-- <div style="width:100%;display:flex;justify-content: space-between;flex-wrap: wrap;border-radius: 5px;overflow:hidden;position: relative;">
                <div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;">
                  <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: 50%;" class="aabb">
                </div>
                <div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;">
                  <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: 50%;" class="aabb">
                </div>
                <div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;">
                  <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: 50%;" class="aabb">
                </div>
                <div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;">
                  <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: 50%;" class="aabb">
                </div>
                <div style="position: absolute;left: 0;top: 0;width: 50%;height: 28vw;border-right: 1px solid #F4F3F5;border-bottom: 1px solid #F4F3F5;"></div>
                <div style="position: absolute;right: 0;top: 0;width: 50%;height: 28vw;"></div>
                <div style="position: absolute;left: 0;bottom: 0;width: 50%;height: 28vw;"></div>
                <div style="position: absolute;right: 0;bottom: 0;width: 50%;height: 28vw;border-left: 1px solid #F4F3F5;border-top: 1px solid #F4F3F5;"></div>
              </div> -->
            </div>
          </div>
          <div style="width: 100%;text-align: right;" v-show="item.role=='user'">
            <div style="width: 100%;display: flex;flex-direction:row-reverse;align-items: center;padding: 3% 0;box-sizing: border-box;">
              <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/usertx.png" alt="" style="width: 10%;">
              <div style="margin-right: 2%;font-size: 100%;">Me</div>
            </div>
            <div style="background-color: #ECEEF7;border-radius: 5px;text-align: left;white-space: pre-line;word-break: break-all;padding: 3% 4%;box-sizing: border-box;width: auto;display: inline-block;">
              <div style="width:100%;display:flex;justify-content: space-between;flex-wrap: wrap;border-radius: 5px;overflow:hidden;margin-top: 10px;">
                <div style="width:49%;display:flex;justify-content: center;align-items: center;background-color:white;">
                  <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: 50%;" class="aabb">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%;height: 10vh;"></div>
      </div>
   

    <!--------------------底部输入-------------------->
    <div style="width: 100%;position: absolute;bottom: 0px;left: 0;background-color: #fff;" ref="binput"></div>

    <!-- <van-button style="width: 10%;position: absolute;bottom: 21px;left:5%;color: white;" :icon="setting_icon" ref="binput_setting" :loading="runFlag" loading-type="spinner" @click="settingShow=true"></van-button> -->
    <!-- <van-button style="width: 10%;position: absolute;bottom: 21px;left:17%;color: white;"  :icon="img_icon" ref="binput_img" :loading="runFlag" loading-type="spinner" @click="imgShow=true"></van-button> -->
    <div style="position: absolute;bottom: 20px;left:5%;color: white;display: flex;align-items: center;justify-content: center;background-color: #309F7E;border-radius: 50px;padding: 4.5%;box-sizing: border-box;background-image: url('https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/setting.png');background-size: 60%;background-repeat: no-repeat;background-position: center;" @click="settingShow=true">
      <!-- <img :src="setting_icon" alt="" style="width:90%"> -->
    </div>
    <div style="position: absolute;bottom: 20px;left:17%;color: white;display: flex;align-items: center;justify-content: center;background-color: #309F7E;border-radius: 50px;padding: 4.5%;box-sizing: border-box;background-image: url('https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/imgadd.png');background-size: 45%;background-repeat: no-repeat;background-position: center;" @click="imgShow=true">
      <!-- <img :src="img_icon2" alt="" style="width:80%" v-show="tst.content.length>0 || tsw.content.length>0 || tphh.length>0"> -->
      <!-- <img :src="img_icon" alt="" style="width:90%"> -->
    </div>

    <div style="width: 53%;max-height:198px;position: absolute; bottom: 20px;left:29.5%;overflow-y: auto;border-radius: 3px;box-shadow: var(0 0 transparent,0 0 transparent),var(0 0 transparent,0 0 transparent),var(0 0 transparent);display:flex;" ref="van_field_f">
      <van-field v-model="message" rows="1" :disabled="runFlag" autosize type="textarea" placeholder="请描述你要绘制的图像" style="resize: none;overflow-y: auto;font-size: 16px;line-height: 1.5;padding: 6px 8px;box-sizing: border-box;width: 100%;white-space: pre-line;word-break: break-all;border:0;background-color: #F3F4F6;" class="scrollbar2" ref="van_field">
      </van-field>
    </div>
    
    <!--------------------发送-------------------->
    <van-button style="width: 10%;position: absolute;bottom: 21px;left:85%;background-color: #12A07C;color: white;" :disabled="btnFlag" :icon="right_icon" ref="binput_right" :loading="runFlag" loading-type="spinner" @click="playTu(message)"></van-button>

    <!--------------------设置-------------------->
    <van-overlay :show="settingShow" style="z-index:99999;">
      <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center; position: relative;">
        <div style="width: 10vw;height: 10vw;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="settingShow=false">X</div>
        <div style="width: 80%;display:flex;flex-direction:column;padding:6% 8%;box-sizing:border-box;background-color: white;border: 1px solid #12A37D;border-radius: 10px;">  
          <div style="width:100%;text-align:center;font-size:110%;font-weight:bold;">设置</div>
          <div style="width:100%;margin-top:10px;display: flex;font-weight: bold;">图片比例</div>
          <div style="width:100%;margin-top:10px;display: flex;align-items: center;" class="bl">
            <van-field v-model="bl1" label="" type="digit" maxlength="2" input-align="left" style="width:30%;border:1px solid;border-radius: 4px;border-color: #619F8A;" @blur="blBlur(1)"/>
            <div style="margin: 0 10px;font-size: 20px;">:</div>
            <van-field v-model="bl2" label="" type="digit" maxlength="2" input-align="left" style="width:30%;border:1px solid;border-radius: 4px;border-color: #619F8A;" @blur="blBlur(2)"/>
          </div>
          <div style="width:100%;margin-top:20px;display: flex;align-items: center;">
            <div style="font-weight: bold;">模式选择</div>
            <!-- <van-icon name="warning" style="position: relative;top: 1.5px;left: 10px;" size="20" @click="tishi"/> -->
          </div>
          <div style="width: 100%;margin-top:10px;display: flex;justify-content: space-between;">
            <div style="width: 47%;padding: 4% 0;box-sizing: border-box;border-radius: 5px;" :class="{'ms_choose':ms_choose_num==1,'ms_nochoose':ms_choose_num!=1}" @click="ms_choose_num=1">
              <div style="width: 100%;text-align: center;">普通模式</div>
              <div style="width: 100%;text-align: center;font-size: 70%;margin-top: 10px;">默认模式,</div>
              <div style="width: 100%;text-align: center;font-size: 70%;">支持中文输入</div>
            </div>
            <div style="width: 47%;padding: 4% 0;box-sizing: border-box;border-radius: 5px;" :class="{'ms_choose':ms_choose_num==2,'ms_nochoose':ms_choose_num!=2}" @click="ms_choose_num=2">
              <div style="width: 100%;text-align: center;">专业模式</div>
              <div style="width: 100%;text-align: center;font-size: 70%;margin-top: 10px;">仅支持英文输入和命令</div>
              <div style="width: 100%;text-align: center;font-size: 70%;">例如 a cat --ar 16:9</div>
            </div>
          </div>
          <van-button style="width: 100%;margin-top: 20px; background-color: #12A37D;color: white;border-radius: 5px;font-size: 120%;"  @click="settingShow=false">确 认</van-button>
        </div>
      </div>
    </van-overlay>
    <!-- <van-dialog v-model="settingShow" title="" >
      <div style="width:100%;height:100%;display:flex;flex-direction:column;padding:6% 8%;box-sizing:border-box;padding-top:3%;position: relative;left: 0;top: 0;">
        <div style="width: 10vw;height: 10vw;box-sizing: border-box; color: #ABABAB;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 1vw;top: 1vw;" @click="settingShow=false">X</div>
          <div style="width:100%;text-align:center;font-size:25px;font-weight:border;">设置</div>
          <div style="width:100%;margin-top:10px;display: flex;font-weight: bold;">图片比例</div>
          <div style="width:100%;margin-top:10px;display: flex;align-items: center;" class="bl">
            <van-field v-model="bl1" label="" type="digit" maxlength="2" input-align="left" style="width:25%;border:1px solid;border-radius: 4px;border-color: #619F8A;" @blur="blBlur(1)"/>
            <div style="margin: 0 10px;font-size: 20px;">:</div>
            <van-field v-model="bl2" label="" type="digit" maxlength="2" input-align="left" style="width:25%;border:1px solid;border-radius: 4px;border-color: #619F8A;" @blur="blBlur(2)"/>
          </div>
          <div style="width:100%;margin-top:20px;display: flex;align-items: center;">
            <div style="font-weight: bold;">模式选择</div>
            <van-icon name="warning" style="position: relative;top: 1.5px;left: 10px;" size="20" @click="tishi"/>
          </div>
          <div style="width: 100%;margin-top:10px;display: flex;justify-content: space-between;">
            <div style="width: 47%;padding: 4% 0;box-sizing: border-box;border-radius: 5px;" :class="{'ms_choose':ms_choose_num==1,'ms_nochoose':ms_choose_num!=1}" @click="ms_choose_num=1">
              <div style="width: 100%;text-align: center;">普通模式</div>
              <div style="width: 100%;text-align: center;font-size: 70%;margin-top: 10px;">默认模式,</div>
              <div style="width: 100%;text-align: center;font-size: 70%;">支持中文输入</div>
            </div>
            <div style="width: 47%;padding: 4% 0;box-sizing: border-box;border-radius: 5px;" :class="{'ms_choose':ms_choose_num==2,'ms_nochoose':ms_choose_num!=2}" @click="ms_choose_num=2">
              <div style="width: 100%;text-align: center;">专业模式</div>
              <div style="width: 100%;text-align: center;font-size: 70%;margin-top: 10px;">仅支持英文输入和命令</div>
              <div style="width: 100%;text-align: center;font-size: 70%;">例如 a cat --ar 16:9</div>
            </div>
          </div>
          <div class="blc">
            <van-field name="switch" label="" >
              <template #input>
                <van-switch v-model="blChecked" size="20" />
              </template>
            </van-field>
          </div>
          <van-button style="width: 100%;margin-top: 10px; background-color: #12A37D;color: white;border-radius: 5px;font-size: 120%;"  @click="settingShow=false">确 认</van-button>
      </div>
    </van-dialog> -->

    <!--------------------图片-------------------->
    <van-overlay :show="imgShow" style="z-index:99999;">
      <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center; position: relative;">
        <div style="width: 10vw;height: 10vw;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="imgShow=false">X</div>
        <div style="width: 80%;display:flex;flex-direction:column;padding:6% 8%;box-sizing:border-box;background-color: white;border: 1px solid #12A37D;border-radius: 10px;position: relative;top:-5%;">  
          <div style="width: 100%;display: flex;border-radius: 3px;border: 1px solid #12A27E;" >
            <div style="width: 33.33%;padding: 5px 0;box-sizing: border-box;border-right: 0.5px solid #12A27E;" :class="{'nav_choose':imgChoose==1,'nav_nochoose':imgChoose!=1}" @click="img_choose(1)" ref="t_width">图生图</div>
            <div style="width: 33.33%;padding: 5px 0;box-sizing: border-box;" :class="{'nav_choose':imgChoose==2,'nav_nochoose':imgChoose!=2}" @click="img_choose(2)">图生文</div>
            <div style="width: 33.33%;padding: 5px 0;box-sizing: border-box;border-left: 0.5px solid #12A27E;" :class="{'nav_choose':imgChoose==3,'nav_nochoose':imgChoose!=3}" @click="img_choose(3)">图片混合</div>
          </div>
          <!-------------------- 图生图 -------------------->
          <div style="width:100%;margin-top:15px;display: flex;flex-wrap: wrap; align-items: center;justify-content: center;" v-show="imgChoose==1">
            <van-uploader style="width: 64%;" :preview-image="false" :after-read="tstAfter">
              <div style="width: 100%;min-height: 40vw;border-radius: 8px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;position: relative;left: 0;" :class="{'imgcont':tst.content.length==0}"> 
                <div style="width: 100%;" v-show="tst.content.length==0">
                  <div style="height: 20%;width: 100%;"></div>
                  <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/upload.png" alt="" style="width: 25%;">
                  <div style="font-size: 70%;color: #777777;margin-top: 5%;" >请点击或拖动图片到该区域</div>
                  <div style="font-size: 65%;color: #A3A3A3;margin-top: 2%;">请上传参考图片，上传完成后,</div>
                  <div style="font-size: 65%;color: #A3A3A3;margin-top: 0%;">回到输入框输入提示词开始</div>
                </div>
                <img :src="tst.content" alt="" style="width: 100%;">
                <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/delete.png" alt="" style="position: absolute;right: 0;top: 0;width: 20px;background-color: #0FA07C;z-index: 99999;" v-show="tst.content.length>0" @click="tst={'content':''}">
              </div>
            </van-uploader>
            <div style="width: 100%;display: flex;align-items: center;margin-top: 20px;">
              <div style="color: #000;font-weight: bold;width: 38%;text-align: left;font-size: 95%;">参考图权重</div>
              <van-slider v-model="tstHk" :min="0.5" :max="2" :step="0.1" active-color="#10A17C" inactive-color="#ABABAB" bar-height="4px" style="width: 60%;" @change="dragEnd">
                <template #button>
                  <div style="width: 10px;height: 10px;background-color: white;border: 1px solid #10A17C;border-radius: 100px;"></div>
                </template>
              </van-slider>
              <div style="color: #676767;text-align: right;width: 40px;">{{ tstHk }}</div>
            </div>
            <van-button style="width: 100%;margin-top: 20px; background-color: #5B5A5A;color: white;border-radius: 3px;font-size: 100%;" v-show="tst.content.length==0" :disabled="tst.content.length==0" >确认并返回填写描述词</van-button>
            <van-button style="width: 100%;margin-top: 20px; background-color: #12A37D;color: white;border-radius: 5px;font-size: 100%;" v-show="tst.content.length!=0" @click="imgShow=false">确认并返回填写描述词</van-button>
          </div>
          <!-------------------- 图生文 -------------------->
          <div style="width:100%;margin-top:15px;display: flex;flex-wrap: wrap; align-items: center;justify-content: center;" v-show="imgChoose==2">
            <van-uploader style="width: 64%;" :preview-image="false" :after-read="tswAfter">
              <div style="width: 100%;min-height: 40vw;border-radius: 8px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;position: relative;left: 0;" :class="{'imgcont':tsw.content.length==0}"> 
                <div style="width: 100%;" v-show="tsw.content.length==0">
                  <div style="height: 20%;width: 100%;"></div>
                  <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/upload.png" alt="" style="width: 25%;">
                  <div style="font-size: 70%;color: #777777;margin-top: 5%;" >请上传需要描述的图片</div>
                  <div style="font-size: 65%;color: #A3A3A3;margin-top: 2%;">上传完成后AI会自动生成</div>
                  <div style="font-size: 65%;color: #A3A3A3;margin-top: 0%;">对画面的英文描述。</div>
                </div>
                <img :src="tsw.content" alt="" style="width: 100%;">
                <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/delete.png" alt="" style="position: absolute;right: 0;top: 0;width: 20px;background-color: #0FA07C;z-index: 99999;" v-show="tsw.content.length>0" @click="tsw={'content':''}">
              </div>
            </van-uploader>
            <van-button style="width: 100%;margin-top: 20px; background-color: #5B5A5A;color: white;border-radius: 3px;font-size: 100%;" v-show="tsw.content.length==0" :disabled="tsw.content.length==0" >立即生成</van-button>
            <van-button style="width: 100%;margin-top: 20px; background-color: #12A37D;color: white;border-radius: 5px;font-size: 100%;" v-show="tsw.content.length!=0" @click="tswPlay">立即生成</van-button>
          </div>
          <!-------------------- 图片混合 -------------------->
          <div style="width:100%;display: flex;flex-wrap: wrap;box-sizing: border-box;" v-show="imgChoose==3">
            <div style="width: 100%;height: 15px;"></div>
            <van-uploader v-model="tphh" multiple :max-count="6" :after-read="tphhAfter" :preview-size="tphh_size">
            </van-uploader>
            <div :style="{'width': '100%', 'height': tphh_size}" v-show="tphh.length<3"></div>
            <div style="color:#DCDCDC;font-size: 80%;width: 100%;">最多上传6张</div>

            <van-button style="width: 100%;margin-top: 20px; background-color: #5B5A5A;color: white;border-radius: 3px;font-size: 100%;" v-show="tphh.length==0" :disabled="tphh.length==0" >立即混合</van-button>
            <van-button style="width: 100%;margin-top: 20px; background-color: #12A37D;color: white;border-radius: 5px;font-size: 100%;" v-show="tphh.length!=0" @click="tphhPlay">立即混合</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
   
    <!-------------------- 图片详情 -------------------->
    <van-overlay :show="allImgShow" style="z-index:99999;">
      <div style="width: 100%;height: 100%;display: flex;flex-wrap: wrap; align-items: center;flex-direction: column; position: relative;">
        <div style="width: 10vw;height: 10vw;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="allImgShow=false">X</div>
        <div style="width: 100%;height: 17%;"> </div>
        <div style="width: 82%;position: relative;">
          <img :src="allImg" alt="" style="width: 100%;">
          <div style="width: 50%;height: 50%;position: absolute;left: 0;top: 0;display: flex;align-items: flex-end;justify-content: flex-end;">
            <span style="position: relative;bottom:10px;right: 10px;">1</span>
          </div>
          <div style="width: 50%;height: 50%;position: absolute;right: 0;top: 0;display: flex;align-items: flex-end;justify-content: flex-end;">
            <span style="position: relative;bottom:10px;right: 10px;">2</span>
          </div>
          <div style="width: 50%;height: 50%;position: absolute;left: 0;bottom: 1%;display: flex;align-items: flex-end;justify-content: flex-end;">
            <span style="position: relative;bottom:10px;right: 10px;">3</span>
          </div>
          <div style="width: 50%;height: 50%;position: absolute;right: 0;bottom: 1%;display: flex;align-items: flex-end;justify-content: flex-end;">
            <span style="position: relative;bottom:10px;right: 10px;">4</span>
          </div>
        </div>
        
        <div style="width: 82%;height: 40px;font-size: 90%; display: flex;align-items: center; background-color: #1D1D1D;color: white;border-radius: 50px;position: absolute;bottom: 30%;left:9%;">
          <div style="width: 30%;">获取大图</div>
          <div style="width: 17%;" @click="getBigImg(n)" v-for="n in 4 " :key="n">{{ n }}</div>
        </div>
        <div style="width: 82%;height: 40px;font-size: 90%; display: flex;align-items: center; background-color: #1D1D1D;color: white;border-radius: 50px;position: absolute;bottom: 20%;left:9%;">
          <div style="width: 30%;">扩展图片</div>
          <div style="width: 17%;" @click="kzImg(n)" v-for="n in 4 " :key="n">{{ n }}</div>
        </div>
      </div>
    </van-overlay>

    <!-------------------- 加载 -------------------->
    <van-overlay :show="loadShow" style="z-index:999999;">
      <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
        <van-loading type="spinner" />
      </div>
    </van-overlay>

  </div>
</template>
<style scoped>
.u{
  width: 18%;padding: 5% 5%;box-sizing: border-box;background-color: #ABABAB;border-radius: 5px;color: white;margin-top: 2%;text-align: center;
}
::v-deep .van-overlay{
  z-index:9999;
}
.content{
  background-color: #F5F7FB;
  border-radius: 5px;
  text-align: left;
  /* white-space: pre-line; */
  word-break: break-all;
  padding: 3% 4%;
  box-sizing: border-box;
  width: auto;
  display: inline-block;
  max-width: 100%;
  margin-left: 46px;
}
.tabbar_bold{
  font-weight: 500;
}
::v-deep .van-field__control::-webkit-input-placeholder {
  color: #929292;
  font-size: 1rem;
}
::v-deep .bl .van-cell{
  line-height: 10px;
}
::v-deep .blc .van-cell{
  padding-left: 0px;
}
</style>
<style>
.reset{
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/reset.png");
}
.imgcont{
  border: 1px dashed #ABABAB;padding: 5% 3%;box-sizing: border-box;
}
.nav_choose{
  background-color: #12A27E;
  color:#FAFBFD;
}
.nav_nochoose{
  background-color: #FAFBFD;
  color:#12A27E;
}
.ms_choose{
  border: 1px solid #619F8A;
  color:#619F8A;
}
.ms_nochoose{
  border: 1px solid #676767;
  color:#686868;
}
.van-uploader__input-wrapper{
  width: 100%;
}
.imgChoose{
  color: #333333;
}
.imgNoChoose{
  color: #A8A9A1;
}
pre{
  width: 100%;
  padding: 10px;
  background-color: #23241f;
  border-radius: 6px;
  color: #f8f8f2;
  box-sizing: border-box;
  overflow-x: auto;
}
.content p{
  margin: 0;
}
div::-webkit-scrollbar{
  display: none;
}
.scrollbar2::-webkit-scrollbar{
  width: 0px;
  display: none;
}
.scrollbar2::-webkit-scrollbar-thumb {
  border-radius: 0px;
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  opacity: 0;
  background: red;
}
.scrollbar2::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  border-radius: 0;
  /* background: #414141; */
}
</style>
<script>
import { marked } from 'marked';
import { Toast,Dialog,Popover,Uploader } from 'vant';

export default {
  name: 'UserIndexYd2HH',
  components: { 
  },
  props:{
    messageList: Array,
    article_id: Number,
    mj_rand: Number
  },
  data() {
    return {
      message:'',
      btnFlag: true,
      runFlag: false,
      msgList:[
        {
          'role': 'as',
          'content': '请描述您对绘图的要求。尽可能详细地描述您所需要的绘画风格、颜色主题、情感、环境等方面的要求，以便智能绘画师能够更好地满足您的需求。约1-3分钟出图。',
          'createtime': '2023-11-01 10:21'
        }
      ],
      msgId:0,
      ty:2, //1使用示例 2新会话
      right_icon_send:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/send2.png",
      right_icon_stop:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/stop2.png",
      right_icon:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/send2.png",
      setting_icon:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/setting.png",
      img_icon:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/imgadd.png",
      img_icon2:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/imgicon2.png",
      articleId:0,
      settingShow:false,
      imgShow:false,
      bl1:1,
      bl2:1,
      blChecked:false,
      imgChoose:1,
      ms_choose_num:1,
      tst:{
        content:''
      },
      tstHk: 1,
      tsw:{
        content:''
      },
      tphh:[],
      allImgShow:false,
      allImg:'',
      timer:'',
      loadShow:false,
      imgStatus:false,
      taskId: 1,
      contentWidth: 340,
      tphh_size:"70px",
      no_content: '<div style="width:100%;display:flex;justify-content: space-between;flex-wrap: wrap;border-radius: 5px;overflow:hidden;position: relative;margin-top:10px;border-radius: 15px;"><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;" class="aabb"></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;" class="aabb"></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;" class="aabb"></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;" class="aabb"></div><div style="position: absolute;left: 0;top: 0;width: 50%;height: 28vw;border-right: 1px solid #F4F3F5;border-bottom: 1px solid #F4F3F5;"></div><div style="position: absolute;right: 0;top: 0;width: 50%;height: 28vw;border-bottom: 1px solid #F4F3F5;"></div><div style="position: absolute;left: 0;bottom: 0;width: 50%;height: 28vw;border-right: 1px solid #F4F3F5;"></div><div style="position: absolute;right: 0;bottom: 0;width: 50%;height: 28vw;"></div></div>',
      no_content2: '<div style="width:100%;display:flex;justify-content: space-between;flex-wrap: wrap;border-radius: 5px;overflow:hidden;position: relative;border-radius: 15px;"><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;" class="aabb"></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;" class="aabb"></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;" class="aabb"></div><div style="width:50%;display:flex;justify-content: center;align-items: center;background-color:white;padding: 10% 5%;box-sizing: border-box;"><img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/no_content.png" alt="" style="width: 50%;" class="aabb"></div><div style="position: absolute;left: 0;top: 0;width: 50%;height: 28vw;border-right: 1px solid #F4F3F5;border-bottom: 1px solid #F4F3F5;"></div><div style="position: absolute;right: 0;top: 0;width: 50%;height: 28vw;border-bottom: 1px solid #F4F3F5;"></div><div style="position: absolute;left: 0;bottom: 0;width: 50%;height: 28vw;border-right: 1px solid #F4F3F5;"></div><div style="position: absolute;right: 0;bottom: 0;width: 50%;height: 28vw;"></div></div>',
      taskid_message:[],
      page:1,
      ago_page:0,
      refreshLoad: false,
      total:0,
    }
  },
  watch:{
    mj_rand(val,val2){
      this.scroend();
      // console.log(val2);
    },
    message(val,val2){
      setTimeout(()=>{
        var van_field_f = this.$refs.van_field_f;
        this.$refs.binput.style.height = (van_field_f.offsetHeight + 40) + 'px';
      },33)
      if(val){
        this.btnFlag = false;
      }else{
        this.btnFlag = true;
      }
    }
  },
  mounted(){
    var that = this;
    this.gptpay_token = localStorage.getItem("gptpay_token");

    setTimeout(()=>{
      for(let val in that.msgList){
        var container = that.$refs.content[val];
        var replace = that.msgList[val]['content'].replace('.','。');
        container.innerHTML = marked.parse(replace);
      }
      var button = document.getElementsByClassName('aabb');
      for(let val of button){
        val.addEventListener("click", function() {
          that.allImgShow = true;
          that.allImg = that.src;
        });
      }
      that.scroend();
    },100);

    var tt = setInterval(()=>{
      var van_field_f = this.$refs.van_field_f;
      that.$refs.binput_right.style.height = (van_field_f.offsetHeight) + 'px';
      // this.$refs.binput_setting.style.height = (van_field_f.offsetHeight) + 'px';
      // this.$refs.binput_img.style.height = (van_field_f.offsetHeight) + 'px';
      that.$refs.binput.style.height = (van_field_f.offsetHeight + 40) + 'px';
      that.contentWidth = that.$refs.content[0].children[0].offsetWidth;
      if(van_field_f.offsetHeight) clearInterval(tt);

      var button = document.getElementsByClassName('u');
      for(let val of button){
        val.addEventListener("click", function add() {
          if(!this.innerHTML.slice(0,1)){
            console.log(111);
          }

          // this.onclick = null;
          // clearTimeout(timer);
          // var timer = setTimeout(function(){
          //   this.onclick = add;
          // },1000); 
        });
      }
    },100)

    this.msgId = this.message_id;
    this.articleId = this.article_id;

    that.get_mj_message();
  },
  methods: {
    onRefresh(){
      var that = this;
      that.get_mj_message(that.page);
    },
    get_mj_message(page=1){
      var that = this;
      that.axios.get(that.global.apiUrl + "/api/customer/get_mj_message",{
        headers:{
          'content-type': 'application/json',
          'token':that.gptpay_token
        },
        params:{
          'page':page
        }
      }).then(function(res){
        that.refreshLoad = false;
        if(res.data.code==1){
          that.total = res.data.data.total;
          if(res.data.data.list){
            if(that.ago_page != that.page){
              if(page==1){
                for(var i=0;i<res.data.data.list.length;i++){
                  that.msgList.push(res.data.data.list[i]);
                  if(res.data.data.list[i].message_data!=null){
                    var aa = res.data.data.list[i].message_data.split(',-+!@');
                      that.taskid_message.push({
                      id: aa[0],
                      message: aa[1],
                      messages: aa[2],
                      imgstr: aa[3]
                    })
                  }
                }
              }else{
                for(var j=res.data.data.list.length-1; j>=0;j--){
                  that.msgList.splice(1, 0, res.data.data.list[j]);
                }
              }
              that.ago_page = that.page;
              if(res.data.data.list.length>=10) that.page++;
            }
          }
          // that.msgList = res.data.data.list;
          setTimeout(()=>{
            for(let val in that.msgList){
              var container = that.$refs.content[val];
              var replace = that.msgList[val]['content'].replace('.','。');
              container.innerHTML = marked.parse(replace);
            }
            var button = document.getElementsByClassName('u');
            for(let val of button){
              val.addEventListener("click", function() {
                // that.allImgShow = true;
                // that.allImg = this.src;
                // that.taskId = this.id;
                var num = this.innerHTML.slice(1,2);
                var taskid = this.parentNode.id;
                if(!this.innerHTML.slice(0,1)){
                  that.resetImg(taskid);
                }else{
                  if(this.innerHTML.slice(0,1)=='U'){
                    that.getBigImg(num, taskid);
                  }else if(this.innerHTML.slice(0,1)=='V'){
                    that.kzImg(num, taskid);
                  }
                }
              });
            }
          },33);
        }
        that.$forceUpdate();
      }).catch(function(err){
        if(err.response.status==400){
          that.loginout();
        }
      });
    },
    blBlur(value){
      if(value==1){
        if(this.bl1.length==0) this.bl1 = 1;
      }else if(value==2){
        if(this.bl2.length==0) this.bl2 = 1;
      }
    },
    resetImg(taskId){
      var that = this;
      Dialog.confirm({
        title: '',
        message: '是否重新生成一张新图？',
      })
      .then(() => {
        if(that.imgStatus){
          Toast({
            type:'fail',
            message:'有任务正在执行，请稍后再试'
          })
          return ;
        }
        if(that.imgStatus){
          Toast({
            type:'fail',
            message:'有任务正在执行，请稍后再试'
          })
          return ;
        }
        // on confirm
        that.loadShow = true;
        that.runFlag = true;
        that.axios.post(that.global.apiUrl + "/api/common/mj_change",{
          id: taskId,
          index: 0,
          action: 'REROLL'
        },{
          headers:{
            'content-type': 'application/json',
            'token': that.gptpay_token
          }
        }).then(function(res){
          that.loadShow = false;
          that.allImgShow = false;
          if(res.data.code==1){
            var task_id = res.data.data.result;
            var createtime = res.data.data['createtime'];
            that.imgStatus = true;
            that.msgList.push({
              role: 'assistant',
              content: '',
              createtime: createtime
            })
            var message = '';
            var messages = '';
            var imgstr = '';
            for(var i=0;i<that.taskid_message.length;i++){
              if(that.taskid_message[i].id==taskId){
                message = that.taskid_message[i].message;
                messages = that.taskid_message[i].messages;
                imgstr = that.taskid_message[i].imgstr;
                break;
              }
            }
            setTimeout(()=>{
              var content = messages + that.no_content;
              // '<img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: '+ that.contentWidth +'px;margin-top: 10px;border-radius: 5px;">' + '<div style="width:100%;color:#A3A3A3;font-size:80%;margin-top: 10px;display:flex;justify-content: space-between;"><div style="color:#EC7E8C;font-size:80%;"> 正在排队......</div> <div>' + createtime + '</div></div>';
              var l = that.$refs.content.length;
              var container = that.$refs.content[l-1];
              container.innerHTML = marked.parse(content);
              setTimeout(()=>{
                that.scroend();
              },200);
            },500);
            that.getTask(task_id, message, 2, imgstr);
            that.taskid_message.push({
              id: task_id,
              message: message,
              messages: messages,
              imgstr: imgstr
            })
            // that.timer = setInterval(()=>{
            //   that.getTask(task_id, '', 2);
            // },10000);
          }else{
            that.loadFlag = false;
            that.runFlag = false;
            that.right_icon = that.right_icon_send;
            that.message = '';
            Toast.fail(res.data.msg);
          }
        })
      })
      .catch(() => {
        // on cancel
        return ;
      });
    },
    kzImg(value, taskId){
      var that = this;
      Dialog.confirm({
        title: '',
        message: '是否根据' + value + '号图片扩展一张新图？',
      })
      .then(() => {
        if(that.imgStatus){
          Toast({
            type:'fail',
            message:'有任务正在执行，请稍后再试'
          })
          return ;
        }
        // on confirm
        that.loadShow = true;
        that.runFlag = true;
        that.axios.post(that.global.apiUrl + "/api/common/mj_change",{
          id: taskId,
          index: value,
          action: 'VARIATION'
        },{
          headers:{
            'content-type': 'application/json',
            'token': that.gptpay_token
          }
        }).then(function(res){
          that.loadShow = false;
          that.allImgShow = false;
          if(res.data.code==1){
            var task_id = res.data.data.result;
            var createtime = res.data.data['createtime'];
            that.imgStatus = true;
            that.msgList.push({
              role: 'assistant',
              content: '',
              createtime: createtime
            })
            var message = '';
            var messages = '';
            var imgstr = '';
            for(var i=0;i<that.taskid_message.length;i++){
              if(that.taskid_message[i].id==taskId){
                message = that.taskid_message[i].message;
                messages = that.taskid_message[i].messages;
                imgstr = that.taskid_message[i].imgstr;
                break;
              }
            }
            setTimeout(()=>{
              var content = messages + that.no_content;
              // '<img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: '+ that.contentWidth +'px;margin-top: 10px;border-radius: 5px;">' + '<div style="width:100%;color:#A3A3A3;font-size:80%;margin-top: 10px;display:flex;justify-content: space-between;"><div style="color:#EC7E8C;font-size:80%;"> 正在排队......</div> <div>' + createtime + '</div></div>';
              var l = that.$refs.content.length;
              var container = that.$refs.content[l-1];
              container.innerHTML = marked.parse(content);
              setTimeout(()=>{
                that.scroend();
              },200);
            },500);
            that.getTask(task_id, message, 2, imgstr);
            that.taskid_message.push({
              id: task_id,
              message: message,
              messages: messages,
              imgstr: imgstr
            })
          }else{
            that.loadFlag = false;
            that.runFlag = false;
            that.right_icon = that.right_icon_send;
            that.message = '';
            Toast.fail(res.data.msg);
          }
        })
      })
      .catch(() => {
        // on cancel
        return ;
      });
    },
    getBigImg(value, taskId){
      var that = this;
      Dialog.confirm({
        title: '',
        message: '是否获取' + value + '号大图？',
      })
      .then(() => {
        if(that.imgStatus){
          Toast({
            type:'fail',
            message:'有任务正在执行，请稍后再试'
          })
          return ;
        }
        // on confirm
        that.loadShow = true;
        that.runFlag = true;
        that.axios.post(that.global.apiUrl + "/api/common/mj_change",{
          id: taskId,
          index: value,
          action: 'UPSCALE'
        },{
          headers:{
            'content-type': 'application/json',
            'token': that.gptpay_token
          }
        }).then(function(res){
          that.loadShow = false;
          that.allImgShow = false;
          if(res.data.code==1){
            var task_id = res.data.data.result;
            var createtime = res.data.data['createtime'];
            that.imgStatus = true;
            that.msgList.push({
              role: 'assistant',
              content: '',
              createtime: createtime
            })
            var message = '';
            var messages = '';
            var imgstr = '';
            for(var i=0;i<that.taskid_message.length;i++){
              if(that.taskid_message[i].id==taskId){
                message = that.taskid_message[i].message;
                messages = that.taskid_message[i].messages;
                imgstr = that.taskid_message[i].imgstr;
                break;
              }
            }
            setTimeout(()=>{
              var content = messages + that.no_content
              var l = that.$refs.content.length;
              var container = that.$refs.content[l-1];
              container.innerHTML = marked.parse(content);
              setTimeout(()=>{
                that.scroend();
              },200);
            },500);
            
            that.getTask(task_id, message, 1, imgstr);
            // that.timer = setInterval(()=>{
            //   that.getTask(task_id, '', 1);
            // },3000);
          }else{
            that.loadFlag = false;
            that.runFlag = false;
            that.right_icon = that.right_icon_send;
            that.message = '';
            Toast.fail(res.data.msg);
          }
        })
      })
      .catch(() => {
        // on cancel
        return ;
      });
    },
    dragEnd(value){
      var that = this;
    },
    tphhAfter(file){
      var that = this;
      for(var i=0;i<that.tphh.length;i++){
        if(!Reflect.has(that.tphh[i], "imgurl")){
          that.tphhAjax(i);
        }
      }
    },
    tphhAjax(i){
      var that = this;
      that.loadShow = true;
      var files = that.tphh[i].file;
      const formData = new FormData(); // 下面有备注
      formData.append('file', files);
      that.axios.post(that.global.apiUrl + "/index.php/admin/index/upload", formData, {
        headers: {
            // 注意修改请求头file格式
          'Content-Type': 'multipart/form-data',
        },
      }).then(function(res){
          if(res.data.code==1){
            that.tphh[i].imgurl = res.data.data.file.full_url;
          }else{
            Toast({
              type:'fail',
              message:'上传失败'
            })
          }
          that.loadShow = false;
      })
    },
    tswAfter(file){
      this.tsw = file;
    },
    tstAfter(file){
      var that = this;
      that.loadShow = true;
      that.tst = file;
      const formData = new FormData(); // 下面有备注
      formData.append('file', file.file);
      that.axios.post(that.global.apiUrl + "/index.php/admin/index/upload", formData, {
        headers: {
            // 注意修改请求头file格式
          'Content-Type': 'multipart/form-data',
        },
      }).then(function(res){
          if(res.data.code==1){
            that.tst.imgurl = res.data.data.file.full_url;
          }else{
            Toast({
              type:'fail',
              message:'上传失败'
            })
          }
          that.loadShow = false;
      })
    },
    img_choose(num){
      this.imgChoose = num;
      var t_width = this.$refs.t_width;
      this.tphh_size = (t_width.offsetWidth-10) + "px";
    },
    tishi(){
      Toast("打开后，仅支持英文输入和命令，例如 a cat --ar 16:9")
    },
    toHH(){
      this.$emit('changeNavEvent',0,0,this.message_type);
    },
    tphhPlay(){
      var that = this;
      if(that.tphh.length<=1){
        Toast({
          type:'fail',
          message:'请上传至少2张图片'
        })
        return ;
      }
      if(that.imgStatus){
        Toast({
          type:'fail',
          message:'有任务正在执行，请稍后再试'
        })
        return ;
      }
      that.imgShow = false;
      that.loadShow = true;
      that.runFlag = true;
      var base64Array = [];
      var message = "";
      var messages = " (正在排队......) </br>";
      var imgstr = "";
      for(var i=0;i<that.tphh.length;i++){
        base64Array.push(that.tphh[i].content);
        // message = message + '<a>'+ that.tphh[i].imgurl +'</a></br>';
        messages = messages + '<a>'+ that.tphh[i].imgurl +'</a></br>';
        imgstr += '<a>'+ that.tphh[i].imgurl +'</a></br>';
      }
      that.axios.post(that.global.apiUrl + "/api/common/mj_tphh",{
        base64: base64Array
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.loadShow = false;
        if(res.data.code==1){
          var task_id = res.data.data.result;
          var createtime = res.data.data['createtime'];
          that.imgStatus = true;
          that.msgList.push({
            role: 'assistant',
            content: '',
            createtime: createtime
          })
          setTimeout(()=>{
            var content = messages + that.no_content;
            var l = that.$refs.content.length;
            var container = that.$refs.content[l-1];
            container.innerHTML = marked.parse(content);
            setTimeout(()=>{
              that.scroend();
            },200);
          },500);
          
          that.getTask(task_id, message, 0, imgstr);
          that.taskid_message.push({
            id: task_id,
            message: message,
            messages: messages,
            imgstr: imgstr
          })
          // that.timer = setInterval(()=>{
          //   that.getTask(task_id, message);
          // },10000);
        }else{
          that.loadFlag = false;
          that.runFlag = false;
          that.right_icon = that.right_icon_send;
          that.message = '';
          Toast.fail(res.data.msg);
        }
      })
    },
    tswPlay(){
      var that = this;
      if(that.tsw.content.length==0){
        Toast({
          type:'fail',
          message:'请先上传图片'
        })
        return ;
      }
      if(that.imgStatus){
        Toast({
          type:'fail',
          message:'有任务正在执行，请稍后再试'
        })
        return ;
      }
      that.imgShow = false;
      that.loadShow = true;
      that.runFlag = true;
      that.axios.post(that.global.apiUrl + "/api/common/mj_tsw",{
        base64: that.tsw.content
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.loadShow = false;
        if(res.data.code==1){
          var task_id = res.data.data.result;
          var createtime = res.data.data['createtime'];
          var messages = " (正在排队......) </br>";
          that.imgStatus = true;
          that.msgList.push({
            role: 'assistant',
            content: '',
            createtime: createtime
          })
          setTimeout(()=>{
            var content = messages + that.no_content; 
            // '<img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/image.png" alt="" style="width: '+ that.contentWidth +'px;margin-top: 10px;border-radius: 5px;">' + '<div style="width:100%;color:#A3A3A3;font-size:80%;margin-top: 10px;display:flex;justify-content: space-between;"><div style="color:#EC7E8C;font-size:80%;"> 正在排队......</div> <div>' + createtime + '</div></div>';
            var l = that.$refs.content.length;
            var container = that.$refs.content[l-1];
            container.innerHTML = marked.parse(content);
            setTimeout(()=>{
              that.scroend();
            },200);
          },500);
          that.getTask(task_id);
          // that.timer = setInterval(()=>{
          //   that.getTask(task_id);
          // },3000);
        }else{
          that.loadFlag = false;
          that.runFlag = false;
          that.right_icon = that.right_icon_send;
          that.message = '';
          Toast.fail(res.data.msg);
        }
      })
    },
    playTu(message){
      var that = this;
      var prompt = message;
      var hz = '';
      if(that.imgStatus){
        Toast({
          type:'fail',
          message:'有任务正在执行，请稍后再试'
        })
        return ;
      }
      if(that.ms_choose_num==2){
        let reg = new RegExp("[\\u4E00-\\u9FFF]+","g")
        if(reg.test(message)){
          Toast({
            type:'fail',
            message:'在专业模式下不能包含中文'
          })
          return ;
        }
      }else{
        // var fgys_value = 500;
        // if(that.tstHk==1) fgys_value = 100;
        // else if(that.tstHk==2) fgys_value = 500;
        // else if(that.tstHk==3) fgys_value = 999;
        hz = " --ar " + that.bl1 + ":" + that.bl2 + " --iw " + that.tstHk;
      }
      var blChecked = false;
      if(that.ms_choose_num==2) blChecked = true;
      that.loadShow = true;
      if(!that.runFlag && that.right_icon==that.right_icon_send){
        that.runFlag = true;
        that.axios.post(that.global.apiUrl + "/api/common/mj_tst",{
          base64: that.tst.content,
          prompt: prompt,
          hz: hz,
          is_zy: blChecked
        },{
          headers:{
            'content-type': 'application/json',
            'token': that.gptpay_token
          }
        }).then(function(res){
          that.loadShow = false;
          if(res.data.code==1){
            var createtime = res.data.data['createtime'];
            that.imgStatus = true;
            that.msgList.push({
              role: 'assistant',
              content: '',
              createtime: createtime
            });
            var messages = message  + ' (正在排队......)';
            var imgstr = '';
            if(that.tst.imgurl) {
              messages = message + ' (正在排队......) </br><a>'+ that.tst.imgurl +'</a>';
              imgstr = ' </br><a>'+ that.tst.imgurl +'</a>';
            }
            setTimeout(()=>{
              var content = messages + that.no_content;
              var l = that.$refs.content.length;
              var container = that.$refs.content[l-1];
              container.innerHTML = marked.parse(content);
              setTimeout(()=>{
                that.scroend();
              },200);
            },500);

            var task_id = res.data.data.result;
            that.getTask(task_id, message, 0, imgstr);
            that.taskid_message.push({
              id: task_id,
              message: message,
              messages: messages,
              imgstr: imgstr
            })
          }else{
            that.loadFlag = false;
            that.runFlag = false;
            that.right_icon = that.right_icon_send;
            that.message = '';
            Toast.fail(res.data.msg);
          }
        })
      }else if(!that.runFlag && that.right_icon==that.right_icon_stop){
        that.runFlag = false;
        that.right_icon = that.right_icon_send;
      }
    },
    getTask(task_id, message='', is_change=0, imgstr=''){
      var that = this;
      that.axios.get(that.global.apiUrl + "/api/common/get_task",{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        },
        params:{
          'id': task_id
        }
      }).then(function(res){
        if(res.data.code==1){
          if(res.data.data.status=="SUCCESS"){
            clearInterval(that.timer);
            var finishTime = res.data.data.finishTime;
            var imageUrl = res.data.data.imageUrl;
            var taskId = res.data.data.id;
            that.getImg(imageUrl, finishTime, is_change, taskId, message, res.data.data.prompt, imgstr);
            
          }else if(res.data.data.status=="IN_PROGRESS" || res.data.data.status=="SUBMITTED" || res.data.data.status=="NOT_START"){
            if(res.data.data.progress == "Stopped"){
              that.msgList.pop();
              Toast({
                type: 'fail',
                message: '图片混合失败'
              })
              that.loadFlag = false;
              that.runFlag = false;
              that.right_icon = that.right_icon_send;
            }else{
              if(res.data.data.status=="IN_PROGRESS"){
                var content = '';
                var l = that.$refs.content.length;
                var container = that.$refs.content[l-1];
                if(that.imgChoose==3) content = message + '  ( '+ res.data.data.progress +' )  </br>' + imgstr + that.no_content;
                else content = message + '  ( '+ res.data.data.progress +' )  ' + imgstr + that.no_content;
                container.innerHTML = marked.parse(content);
              }
              setTimeout(()=>{
                that.getTask(task_id, message, is_change, imgstr);
              },5000)
            }
          }else if(res.data.data.status=="FAILURE" || res.data.data.progress=="Stopped"){
            that.msgList.pop();
            Toast({
              type: 'fail',
              message: res.data.data.failReason
            })
            that.loadFlag = false;
            that.runFlag = false;
            that.right_icon = that.right_icon_send;
          }
        }
      }).catch(function(err){
        console.log(err);
        if(err.response.status==400){
          that.loginout();
        }else{
          that.msgList.pop();
        }
        that.loadFlag = false;
        that.runFlag = false;
      });
    },


    getImg(imageUrl, finishTime, is_change, taskId, message, prompt, imgstr){
      var that = this;
      var is_getbig = false;
      if(is_change==1){
        is_getbig = true;
      }
      that.axios.post(that.global.apiUrl + "/api/common/getimg",{
        url: imageUrl,
        is_getbig: is_getbig
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.loadFlag = false;
        that.runFlag = false;
        that.right_icon = that.right_icon_send;
        
        var img = res.data.data.imgurl;
        var content = '';
        var l = that.$refs.content.length;
        var container = that.$refs.content[l-1];
        if(is_change==1){
          content = message + imgstr + '<img src="' + img + '" style="width: 100%;border-radius: 15px;margin-top:10px;" class="allimg">';

          // content = '<img src="' + img + '" alt="" style="width: 100%;margin-top: 10px;border-radius: 5px;" >'  + '<div style="width:100%;color:#A3A3A3;text-align: right;font-size:80%;margin-top: 10px;">' + finishTime + '</div>';
        }else if(is_change==2){
          content = message + imgstr + '<img src="' + img + '" style="width: 100%;border-radius: 15px;margin-top:10px;" class="allimg"><div style="width: 100%;display: flex;flex-wrap: wrap;" id="' + taskId + '"><div style="" class="u">U1</div><div style="margin-left: 2%;"  class="u">U2</div><div style="margin-left: 2%;" class="u">U3</div><div style="margin-left: 2%;margin-top: 2%;" class="u">U4</div><div style="margin-left: 2%;margin-top: 2%;" class="u reset"></div><div style="" class="u">V1</div><div style="margin-left: 2%;" class="u">V2</div><div style="margin-left: 2%;" class="u">V3</div><div style="margin-left: 2%;" class="u">V4</div></div>';

          // content = '<img src="' + img + '" alt="" style="width: 100%;margin-top: 10px;border-radius: 5px;" class="aabb" id="'+ taskId +'">'  + '<div style="width:100%;color:#A3A3A3;text-align: right;font-size:80%;margin-top: 10px;">' + finishTime + '</div>';
        }else{
          that.loadFlag = false;
          that.runFlag = false;
          that.right_icon = that.right_icon_send;
          that.message = '';
          if(that.imgChoose==1){
            that.tst = {content:''};

            content = message + imgstr + '<img src="' + img + '" style="width: 100%;border-radius: 15px;margin-top:10px;" class="allimg"><div style="width: 100%;display: flex;flex-wrap: wrap;" id="' + taskId + '"><div style="" class="u">U1</div><div style="margin-left: 2%;"  class="u">U2</div><div style="margin-left: 2%;" class="u">U3</div><div style="margin-left: 2%;margin-top: 2%;" class="u">U4</div><div style="margin-left: 2%;margin-top: 2%;" class="u reset"></div><div style="" class="u">V1</div><div style="margin-left: 2%;" class="u">V2</div><div style="margin-left: 2%;" class="u">V3</div><div style="margin-left: 2%;" class="u">V4</div></div>';
            // content = message + '<img src="' + img + '" alt="" style="width: 100%;margin-top: 10px;border-radius: 5px;" class="aabb" id="'+ taskId +'">'  + '<div style="width:100%;color:#A3A3A3;text-align: right;font-size:80%;margin-top: 10px;">' + finishTime + '</div>';
          }else if(that.imgChoose==2){
            that.tsw = {content:''};

            message = prompt;
            content = message + imgstr + '<img src="' + img + '" alt="" style="width: 100%;margin-top: 10px;border-radius: 5px;margin-top:10px;" class="allimg">';
          }else if(that.imgChoose==3){
            that.tphh = [];

            content = message + imgstr + '<img src="' + img + '" style="width: 100%;border-radius: 15px;margin-top:10px;" class="allimg"><div style="width: 100%;display: flex;flex-wrap: wrap;" id="' + taskId + '"><div style="" class="u">U1</div><div style="margin-left: 2%;"  class="u">U2</div><div style="margin-left: 2%;" class="u">U3</div><div style="margin-left: 2%;margin-top: 2%;" class="u">U4</div><div style="margin-left: 2%;margin-top: 2%;" class="u reset"></div><div style="" class="u">V1</div><div style="margin-left: 2%;" class="u">V2</div><div style="margin-left: 2%;" class="u">V3</div><div style="margin-left: 2%;" class="u">V4</div></div>';

            // content = message + '<img src="' + img + '" alt="" style="width: 100%;margin-top: 10px;border-radius: 5px;" class="aabb" id="'+ taskId +'">'  + '<div style="width:100%;color:#A3A3A3;text-align: right;font-size:80%;margin-top: 10px;">' + finishTime + '</div>';
          }
        }
        container.innerHTML = marked.parse(content);
        setTimeout(()=>{
          var data = '';
          for(var i=0;i<that.taskid_message.length;i++){
            if(that.taskid_message[i].id==taskId){
              data = taskId + ',-+!@' + that.taskid_message[i].message + ',-+!@' + that.taskid_message[i].messages + ',-+!@' + that.taskid_message[i].imgstr;
              break;
            }
          }
          that.axios.post(that.global.apiUrl + "/api/customer/add_mj_message",{
            content: content,
            message_data: data
          },{
            headers:{
              'content-type': 'application/json',
              'token': that.gptpay_token
            }
          }).then(function(res){

          })
          var button = document.getElementsByClassName('u');
          for(let val of button){
            val.addEventListener("click", function() {
              // that.allImgShow = true;
              // that.allImg = this.src;
              // that.taskId = this.id;
              var num = this.innerHTML.slice(1,2);
              var taskid = this.parentNode.id;
              if(!this.innerHTML.slice(0,1)){
                that.resetImg(taskid);
              }else{
                if(this.innerHTML.slice(0,1)=='U'){
                  that.getBigImg(num, taskid);
                }else if(this.innerHTML.slice(0,1)=='V'){
                  that.kzImg(num, taskid);
                }
              }
            });
          }
          that.scroend();
        },100);
        that.imgStatus = false;
      })
    },
    
    scroend() {
      // 让滚动条始终在最底部
      this.$nextTick(() => {
        this.$refs.messageContent.scrollTop = this.$refs.messageContent.scrollHeight
      })
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.UserIndex {
  margin: 0;
  padding: 0;
  /* background-color: black; */
  /* display: flex;
  justify-content: center; */
  /* align-items: center; */
}
</style>
