import Vue from 'vue'
import VueRouter from 'vue-router'
import UserIndex from '../views/UserIndex.vue'
import UserIndex2 from '../views/UserIndex2.vue'
import UserLogin from '../views/UserLogin.vue'
import StartUp from '../views/StartUp.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'StartUp',
    component: StartUp
  },
  {
    path: '/index',
    name: 'UserIndex',
    component: UserIndex
  },
  {
    path: '/index2',
    name: 'UserIndex2',
    component: UserIndex2
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: UserLogin
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
