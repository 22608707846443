<template>
  <div class="login" :style="{'width':screenWidth+'px','height':screenHeight+'px'}">
    <UserLoginPc v-if="navigatorFlag" style="width: 100%;height: 100%;"></UserLoginPc>
    <UserLoginYd v-if="!navigatorFlag" style="width: 100%;height: 100%;"></UserLoginYd>
  </div>
</template>
<style>
.van-field__control{
  border: 0;
}
</style>
<script>
import { Toast,Dialog  } from 'vant';
import TencentCaptcha from 'TencentCaptcha';
import UserLoginPc from '../components/UserLoginPc.vue';
import UserLoginYd from '../components/UserLoginYd.vue';
export default {
  name: 'UserLogin',
  components: {
    UserLoginPc,
    UserLoginYd
  },
  data() {
    return {
      screenWidth:document.documentElement.clientWidth,
      screenHeight:document.documentElement.clientHeight,
      navigatorFlag:0 //0移动端 1pc端
    }
  },
  beforeMount(){
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      this.navigatorFlag = 0;
    }
    else {
      /*window.location.href="你的电脑版地址";    */
      this.navigatorFlag = 1;
    }
  },
  mounted(){
    var that = this;
    
    that.screenWidth = window.innerWidth;
    that.screenHeight = window.innerHeight;

    window.addEventListener('resize',function() {
      that.screenWidth = window.innerWidth;
      that.screenHeight = window.innerHeight;
    });
  },
  methods: {
    
  }
}
</script>
<style>
</style>
