<template>
  <div style="overflow-y: hidden;padding: 3vw;padding-top: 5%; box-sizing: border-box;">
    <div style="background-color: white;width: 100%;border-radius: 5px;padding: 4vw;padding-right: 6vw; box-sizing: border-box;display: flex;align-items: center;">
      <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/usertx.png" alt="" style="width: 15%;">
      <div style="width: calc(85% - 10px); margin-left: 10px;display: flex;justify-content: space-between;align-items: center;">
        <div style="font-weight: bold;position: relative;top: 2px;">Me</div>
        <div style="text-align: left;color: #8A8A8A;">
          <div>余额: {{ user_info.balance }}元</div>
          <div v-show="user_info.is_vip">AI对话会员: {{user_info.viptime}}</div>
          <div v-show="!user_info.is_vip">AI对话会员: 不是会员</div>
          <div v-show="user_info.is_mj_vip">AI画画会员: {{user_info.mj_viptime}}</div>
          <div v-show="!user_info.is_mj_vip">AI画画会员: 不是会员</div>
        </div>
      </div>
    </div>
    
    <div style="background-color: white;width: 100%;border-radius: 5px;padding: 4vw;box-sizing: border-box;display: flex;align-items: center;margin-top: 5vw;flex-wrap: wrap;">
      <div style="width: 100%;display: flex;align-items: center;justify-content: space-between; padding: 3vw 3vw;box-sizing: border-box;" @click="toRechar">
        <div style="display: flex;">
          <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/huihua0.png" alt="" style="width: 18px;">
          <div style="margin-left: 10px;font-weight: 600;font-size: 100%;">AI对话会员</div>
        </div>
        <van-tag color="#12A37B" size="medium">&nbsp;&nbsp;充值&nbsp;&nbsp;</van-tag>
        <!-- <div style="padding:3px 6%;box-sizing: border-box;background-color: #D9001B;border-radius: 3px;font-size: 80%;color: white;display: flex;">充值</div> -->
      </div>
      <div style="width: 100%;display: flex;align-items: center;justify-content: space-between; padding: 3vw 3vw;box-sizing: border-box;" @click="toMjRechar">
        <div style="display: flex;">
          <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/mj_hb0.png" alt="" style="width: 18px;">
          <div style="margin-left: 10px;font-weight: 600;font-size: 100%;">AI画画会员</div>
        </div>
        <van-tag color="#12A37B" size="medium">&nbsp;&nbsp;充值&nbsp;&nbsp;</van-tag>
        <!-- <div style="padding:3px 6%;box-sizing: border-box;background-color: #06A452;border-radius: 3px;font-size: 80%;color: white;display: flex;">充值</div> -->
      </div>
      <div style="width: 100%;display: flex;align-items: center;padding: 3vw 3vw;box-sizing: border-box;margin-top: 3vw;" @click="kfShow=true">
        <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/lxkf.png" alt="" style="width: 18px;">
        <div style="margin-left: 10px;font-weight: 600;font-size: 100%;">联系客服</div>
      </div>
      <div style="width: 100%;display: flex;align-items: center;padding: 3vw 3vw;box-sizing: border-box;margin-top: 3vw;" @click="loginoutDia">
        <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/tcdl.png" alt="" style="width: 18px;">
        <div style="margin-left: 10px;font-weight: 600;font-size: 100%;">退出登录</div>
      </div>
    </div>

    <!-------------------- 充值弹窗界面 -------------------->
    <van-dialog v-model="recharShow" title="" show-cancel-button confirmButtonText="充值" @confirm="recharge" @closed="chooseRecharge(0,false)">
      <div style="width: 100%;font-weight: bold;font-size: 120%;padding: 7% 0;padding-bottom: 5%;  box-sizing: border-box;">计费说明</div>
      <div style="width: 100%;padding: 0 6%;box-sizing: border-box;font-size: 90%;text-align: left;">
        用户需知<br/>
        1、使用GPT-3.5-turbo模型，每次提问收费0.1元 。现推出充值优惠，每次提问只需要0.05元 。<br/>
        2、用户的余额无法提现。<br/>
        3、新用户我们将赠送10次的提问机会。<br/>
      </div>
      <div style="width: 100%;display: flex;align-items: center;justify-content: space-around;padding: 5%;box-sizing: border-box;flex-wrap: wrap;">

        <van-radio-group v-model="payType" direction="horizontal" style="width: 100%;" checked-color="#10A17D">
          <van-radio name="1" >支付宝支付</van-radio>
          <van-radio name="2">微信支付</van-radio>
        </van-radio-group>

        <div style="width: 100%;display: flex;">
          <div v-for="(item,index) in recharge_list" :key="`recharge` + index" style="width: 30%; padding: 5% 0;box-sizing:border-box;border-radius: 5px;margin-top: 5%;" :class="{'recharge_choose':item.flag,'recharge_no_choose':!item.flag,'marginleft':index>0}" @click="chooseRecharge(index,true)">
            <span style="font-size: 100%;font-weight: bold;">{{ item.money }}元</span><br/><span style="font-size: 70%;">提问: {{ item.yj }} 次</span>
          </div>
        </div>
        
        <div style="width: 100%;display: flex;">
          <van-field v-model="recharge_money" type="digit" placeholder="自定义价格(最少5元)" maxlength="5" style="margin-top: 5%; border: 0.5px solid rgba(0, 0, 0, 0.1);border-radius: 5px;width: 60%;box-shadow: var(0 0 transparent,0 0 transparent),var(0 0 transparent,0 0 transparent),var(0 0 transparent);" @focus="chooseRecharge(-1,true)">
          </van-field>
        </div>
      </div>
    </van-dialog>
    <!-------------------- 支付界面 -------------------->
    <van-overlay :show="zfFlag" style="z-index:9999;">
      <div style="width: 100%;height: 100%;display: flex;flex-wrap: wrap; align-items: center;justify-content: center;flex-direction: column; position: relative;">
        <div style="width: 10vw;height: 10vw;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="rechar_check_cannel">X</div>
        <img :src="qrcode_url" alt="" style="width: 50%;">
        <div style="width: 100%;margin-top: 5%;color: white;">{{ payMsg }}</div>
      </div>
    </van-overlay>
    <!-------------------- 等待 -------------------->
    <van-overlay :show="loadFlag">
      <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
        <van-loading type="spinner"/>
      </div>
    </van-overlay>
    <!-------------------- 客服界面 -------------------->
    <van-overlay :show="kfShow" style="z-index:9999;">
      <div style="width: 100%;height: 100%;display: flex;flex-wrap: wrap; align-items: center;justify-content: center;flex-direction: column; position: relative;">
        <div style="width: 10vw;height: 10vw;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="kfShow=false">X</div>
        <img src="../assets/kf.png" alt="" style="width: 50%;">
      </div>
    </van-overlay>
  </div>
</template>
<style scoped>
.marginleft{
  margin-left: 3.33%;
}
.van-overlay{
  z-index: 99;
}
.recharge_choose{
  border: 1px solid #10A17D;
  color: #10A17D;
}
.recharge_no_choose{
  border: 1px solid #ccc;
}
</style>
<!-- 邀请好友页 -->
<script>
import { Toast,Dialog } from 'vant';

export default {
  name: 'UserIndexYd3WD',
  props:{
    user_info:Object
  },
  data() {
    return {
      recharShow:false,
      recharge_list:[
        {
          'money':10,
          'yj':200,
          'flag':false,
        },
        {
          'money':20,
          'yj':400,
          'flag':false,
        },
        {
          'money':50,
          'yj':1000,
          'flag':false,
        }
      ],
      recharge_money: '',
      zfFlag: false,
      qrcode_url: '',
      out_trade_no: '',
      timer: '',
      kfShow: false,
      loadFlag: false,
      payType: "1",           //1支付宝支付  2微信支付
      payMsg: "请使用支付宝扫码支付"
    }
  },
  mounted(){
    this.gptpay_token = localStorage.getItem("gptpay_token");
  },
  methods: {
    toMjRechar(){
      console.log(12);
      this.$emit('changeNavEvent',12);
    },
    toRechar(){
      // this.recharShow = true;
      this.$emit('changeNavEvent',11);
    },
    rechar_check_cannel(){
      this.zfFlag=false
      clearInterval(this.timer);
    },
    chooseRecharge(index,flag){
      var that = this;
      for(let val of that.recharge_list){
        val.flag = false;
      }
      if(flag){
        if(index>=0){
          that.recharge_list[index].flag = true;
        }
      }
    },
    recharge(){
      var that = this;
      var recharge_money = 0;

      for(let val of this.recharge_list){
        if(val.flag){
          recharge_money = val.money;
        }
      }
      if(recharge_money==0){
        recharge_money = that.recharge_money;
      }
      if(recharge_money==0){
        Toast({
          'type':'fail',
          'message':'请输入要充值的金额'
        })
        that.recharge_money = '';
        return ;
      }
      that.loadFlag = true;
      var recharge_url = "";
      var opid = localStorage.getItem("opid");
      
      if(that.payType=="1") {
        recharge_url = that.global.apiUrl + "/api/customer/recharge";
        that.payMsg = "请使用支付宝扫码支付";
        that.zfbRechar(recharge_url, recharge_money);
      }else {
        if(opid){ 
          //微信浏览器
          recharge_url = that.global.apiUrl + "/api/customer/recharge_jsapi_wx";
          that.wxJsapiRechar(recharge_url, recharge_money, opid);
        }else{
          //外部浏览器
          recharge_url = that.global.apiUrl + "/api/customer/recharge_h5_wx";
          that.wxH5Rechar(recharge_url, recharge_money);
        }
      }
    },
    wxH5Rechar(recharge_url, recharge_money){
      var that = this;
      that.axios.post(recharge_url,{
        recharge_money: recharge_money,
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.loadFlag = false;
        that.recharge_money = '';
        for(let val of that.recharge_list){
          val.flag = false;
        }
        if(res.data.code==1){
          var h5_url = res.data.data.h5_url;
          that.timer = setInterval(()=>{
            that.recharge_check();
          },3000);
          window.location.href = h5_url;
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    wxJsapiRechar(recharge_url, recharge_money, opid){
      var that = this;
      that.axios.post(recharge_url,{
        recharge_money: recharge_money,
        opid: opid,
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.loadFlag = false;
        that.recharge_money = '';
        for(let val of that.recharge_list){
          val.flag = false;
        }
        if(res.data.code==1){
          var appid = res.data.data.appid;
          var timeStamp = res.data.data.timeStamp.toString();
          var nonceStr = res.data.data.nonceStr;
          var paySign = res.data.data.paySign;
          var prepay_id = res.data.data.prepay_id;
          prepay_id = "prepay_id=" + prepay_id;
          that.onBridgeReady(appid, timeStamp, nonceStr, prepay_id, paySign);

          that.timer = setInterval(()=>{
            that.recharge_check();
          },3000)
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    zfbRechar(recharge_url, recharge_money){
      var that = this;
      that.axios.post(recharge_url,{
        recharge_money: recharge_money
      },{
        headers:{
          'content-type': 'application/json',
          'token': this.gptpay_token
        }
      }).then(function(res){
        that.loadFlag = false;
        that.recharge_money = '';
        for(let val of that.recharge_list){
          val.flag = false;
        }
        if(res.data.code==1){
          that.zfFlag = true;
          that.qrcode_url = res.data.data.qrcode_url;
          that.out_trade_no = res.data.data.out_trade_no;
          that.timer = setInterval(()=>{
            that.recharge_check();
          },3000)
          
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    onBridgeReady(appid='', timeStamp='', nonceStr='', prepay_id='', paySign=''){
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
          "appId": appid,     //公众号ID，由商户传入     
          "timeStamp": timeStamp,     //时间戳，自1970年以来的秒数     
          "nonceStr": nonceStr,      //随机串     
          "package": prepay_id,
          "signType": "RSA",     //微信签名方式：     
          "paySign": paySign //微信签名 
      },
      function(res) {
        console.log(res);
          if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
      });
    },
    recharge_check(){
      var that = this;
      var recharge_url = "";
      if(that.payType=="1") recharge_url = that.global.apiUrl + "/api/customer/recharge_check";
      else recharge_url = that.global.apiUrl + "/api/customer/recharge_check_wx";

      that.axios.post(recharge_url,{
        out_trade_no: that.out_trade_no
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        if(res.data.code==1){
          clearInterval(that.timer);
          that.zfFlag = false;
          that.qrcode_url = '';
          that.out_trade_no = '';
          Toast({
            'type':'success',
            'message':'充值成功',
            'duration':3000,
            'onClose':()=>{
              that.$emit('changeNavEvent',2);
            }
          })
        }
        // console.log(res.data);
      });
    },
    loginoutDia(){
      Dialog.confirm({
        title: '',
        message: '确认退出登录吗?',
      })
      .then(() => {
        this.loginout();
      })
    },
    loginout(){
      localStorage.setItem("gptpay_token", '');
      Toast({
        type:'success',
        message:"退出登录成功",
        onClose: ()=>{
          this.$router.replace({path:'/login'});
        }
      })
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
