<template>
  <div class="UserIndex">
    <div style="height: 4%;"></div>
    <div style="font-weight: 500;font-size: 180%;">中文调教指南</div>
    <div style="border: 1px solid #ccc;box-sizing: border-box;width: 80%;height: 30px; display: flex;align-items: center;margin-top: 4%;padding-left: 7px;box-sizing: border-box;">
      <div style="width: 80%;text-align: left;line-height: 30px;">https://qd.qdmedia.site/zl.html</div>
      <div style="width: 21%;height: 106%;line-height: 30px;text-align: center;background-color: black;color: #fff;" @click="jumps('https://qd.qdmedia.site/zl.html')">前往</div>
    </div>
    <div style="height: 4%;"></div>
    <div style="font-weight: 500;font-size: 180%;">最新地址发布</div>
    <div style="border: 1px solid #ccc;box-sizing: border-box;width: 80%;height: 30px; display: flex;align-items: center;margin-top: 4%;padding-left: 7px;box-sizing: border-box;">
      <div style="width: 80%;text-align: left;line-height: 30px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">https://gitee.com/wsh666/gptcn-forever</div>
      <div style="width: 21%;height: 106%;line-height: 30px;text-align: center;background-color: black;color: #fff;" @click="jumps('https://gitee.com/wsh666/gptcn-forever')">前往</div>
    </div>
    <div style="border: 1px solid #ccc;box-sizing: border-box;width: 80%;height: 30px; display: flex;align-items: center;margin-top: 4%;padding-left: 7px;box-sizing: border-box;">
      <div style="width: 80%;text-align: left;line-height: 30px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">https://github.com/ggtogether/GPTCN-forever</div>
      <div style="width: 21%;height: 106%;line-height: 30px;text-align: center;background-color: black;color: #fff;" @click="jumps('https://github.com/ggtogether/GPTCN-forever')">前往</div>
    </div>
    <!-- <div style="border: 1px solid #ccc;box-sizing: border-box;width: 80%;height: 30px; display: flex;align-items: center;margin-top: 4%;">
      <div style="width: 80%;text-align: left;line-height: 30px;">&nbsp;&nbsp;https://github.com/ggtogether/GPTCN-forever</div>
      <div style="width: 21%;height: 106%;line-height: 30px;text-align: center;background-color: black;color: #fff;" @click="jumps('http://qd.qljzxyz.com/')">前往</div>
    </div> -->
    <!-- <div style="height: 4%;"></div>
    <div style="font-weight: 500;font-size: 180%;">邀请好友</div>
    <div style="width: 80%;margin-top: 4%;text-align: left;white-space: pre-line;word-break: break-all;">
      当你通过分享您的专属链接邀请好友成功注册后并充值任意金额，将获得1元(20次)的提问次数，快来邀请您的好友加入我们吧
    </div>
    <div style="border: 1px solid #ccc;box-sizing: border-box;width: 80%;height: 30px; display: flex;align-items: center;margin-top: 4%;">
      <div style="width: 80%;text-align: left;line-height: 30px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">&nbsp;&nbsp;{{ invite_url }}</div>
      <div style="width: 21%;height: 108%;line-height: 30px;text-align: center;background-color: black;color: #fff;" @click="copy(invite_url)">复制</div>
    </div> -->
    <div style="height: 4%;"></div>
    <div style="font-weight: 500;font-size: 180%;">联系客服</div>
    <img src="../assets/kf.png" alt="" srcset="" style="width: 40%;margin-top: 4%;">
    <!-- <canvas ref="canvas" style="margin-top: 2%;"></canvas> -->
  </div>
</template>
<!-- 邀请好友页 -->
<script>
import { Toast } from 'vant';

export default {
  name: 'UserMic',
  data() {
    return {
      screenWidth:window.screen.width,
      screenHeight:document.documentElement.clientHeight,
      invite_url:''
    }
  },
  mounted(){
    var domain = 'http://qd.qljzxyz.com/#/';
    this.invite_url = domain + 'login?type=2&mic=' + this.$route.query.mic; //我的邀请码
    // let opts = {
    //   errorCorrectionLevel: 'H',
    //   type: 'image/png',
    //   quality: 0.3,
    //   width: 165,
    //   height: 165,
    //   text: 'xxx',
    //   color: {
    //     dark: '#333333',
    //     light: '#fff'
    //   }
    // }
    // this.QRCode.toCanvas(this.$refs.canvas, this.invite_url, opts);
    
  },
  methods: {
    jumps(url){
      window.location.replace(url);
      // if(url.indexOf("qd")!='-1'){
        
      // }else{
      //   this.$router.push({'path':url,'query':{'mic':this.user_info['my_invite_code']}})
      // }
    },
    copy(text){
      this.$copyText(text).then(
        (e) => {
          Toast.success('复制成功')
        },
        (err) => {
          Toast.fail("复制失败");
        }
      );
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.UserIndex {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: black;
}
</style>
