<template>
  <div class="login" style="width: 100%;height: 100%;">
    <div style="height: 6%;"></div>
    <!-- <img src="../assets/logo3.jpg" alt="" style="width: 32px;"> -->
    <div style="width: 100%;height: 20px;"></div>
    <div style="height: 20%;"></div>
    <div class="i-title" ref="title">{{title}}</div>
    <div style="height: 1%;"></div>
    <div style="width: 288px;margin: 0 auto;font-size: 90%;text-align: left;">{{ describe }}</div>
    <div style="height: 3%;"></div>
    <van-field v-model="email_address" placeholder="请输入邮箱" class="i-input" style="width: 288px;">
    </van-field>
    <div class="pyz">
      <van-field v-model="password" type="password" placeholder="请输入密码" class="i-input" style="width:100%;">
      </van-field>
    </div>
    <div class="pyz" v-show="pass_type=='reset' || pass_type=='register'">
      <van-field v-model="yzm" type="tel" placeholder="请输入验证码" class="i-input" style="width:100%">
      </van-field>
      <div style="position: absolute;right: 2.5%;width: 40%;background-color: #12A27E;border-radius: 3px;color:#fff;font-size: 50%;padding: 5px;padding-top: 8px;padding-bottom: 8px; box-sizing: border-box;" ref="ver_code_send" @click="tencent_slide_validate">发送邮箱验证码</div>
    </div>
    <div class="pyz" v-show="pass_type=='register'">
      <van-field v-model="invite_code" placeholder="邀请码（选填）" class="i-input" style="width:100%">
      </van-field>
    </div>
    <van-button type="success" block class="btn" @click="login">{{btn_text}}</van-button>
    <div class="p-c">
      <div @click="pass_type='reset',title='忘记密码',btn_text='确认',smtp_type='reset_password',describe='你将会在你的邮箱收到一封含有验证码的邮件，用来重设密码。'" v-show="pass_type=='pass'">忘记密码?</div>

      <div @click="pass_type='pass',title='欢迎回来',btn_text='登录',describe=''" v-show="pass_type=='register' || pass_type=='reset'">已有账号? <span style="color: #12A27E;">登录</span></div>

      <div @click="pass_type='register',btn_text='注册',title='注册',smtp_type='register',describe='注册时需要邮箱验证，处于安全考虑，您的邮箱将用于验证您的身份。'" v-show="pass_type=='pass'">还没有账号? <span style="color: #12A27E;">注册</span></div>
    </div>

    <van-overlay :show="loadFlag">
      <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
        <van-loading type="spinner"/>
      </div>
    </van-overlay>

  </div>
</template>
<style>
.van-field__control{
  border: 0;
}
</style>
<script>
import { Toast,Dialog  } from 'vant';
import TencentCaptcha from 'TencentCaptcha';
export default {
  name: 'UserLoginYd',
  components: {
  },
  data() {
    return {
      screenWidth:document.documentElement.clientWidth,
      screenHeight:document.documentElement.clientHeight,
      email_address:'',
      password:'',
      time_count:59,
      pass_type:'pass',
      invite_code:'',
      yzm:'',
      btn_text:'登录',
      title:"欢迎回来",
      smtp_type:'register',
      loadFlag:false,
      describe:'',
    }
  },
  mounted(){
    if(this.$route.query.type==2){
      this.pass_type = 'register';
      this.btn_text = '注册';
      this.title = '注册';
      this.smtp_type = 'register';
      this.describe = '注册时需要邮箱验证，处于安全考虑，您的邮箱将用于验证您的身份。';
    }else{
      this.pass_type = 'pass';
      this.title = '欢迎回来';
      this.btn_text = '登录';
      this.describe = '';
    }
    if(this.$route.query.mic) this.invite_code = this.$route.query.mic;
  },
  methods: {
    login(){
      let that = this;
      that.loadFlag = true;
      if(that.pass_type=='pass'){
        that.axios.post(that.global.apiUrl + "/api/customer/pass_login",{
          email_address: that.email_address,
          password: that.password,
        }).then(function(res){
          that.loadFlag = false;
          if(res.data.code==1){
            Toast.success('登录成功');
            setTimeout(()=>{
                that.$router.replace('/index');
            },1500)
            localStorage.setItem("gptpay_token", res.data.data.token);
          }else{
            Toast.fail(res.data.msg);
          }
        })
      }else if(that.pass_type=='reset'){
        that.axios.post(that.global.apiUrl + "/api/customer/update_password",{
          email_address:that.email_address,
          password: that.password,
          smtp_code:that.yzm,
        }).then(function(res){
          that.loadFlag = false;
          if(res.data.code==1){
            Toast.success('修改成功');
            setTimeout(()=>{
              that.pass_type = 'pass';
              that.btn_text = '登录';
              that.title = '欢迎回来';
              that.describe = '欢迎回来';
              that.yzm = '';
            },1500)
          }else{
            Toast.fail(res.data.msg);
          }
        })
      }else if(that.pass_type=='register'){
        that.axios.post(that.global.apiUrl + "/api/customer/register_h5",{
          email_address:that.email_address,
          password:that.password,
          smtp_code:that.yzm,
          invite_code:that.invite_code
        }).then(function(res){
          that.loadFlag = false;
          if(res.data.code==1){
            Toast.success('注册成功');
            setTimeout(()=>{
              that.pass_type = 'pass';
              that.btn_text = '登录';
              that.title = '欢迎回来';
              that.describe = '欢迎回来';
              that.yzm = '';
            },1500)
          }else{
            Toast.fail(res.data.msg);
          }
        })
      }
    }, 
    tencent_slide_validate(){
      let that = this;
      if(!that.email_address){
        Toast({
          'type':'fail',
          'message':'邮箱地址不能为空'
        })
        return ;
      }
      if(that.time_count==59){
        var Captcha = new TencentCaptcha('192024574', res => {
          if(res.ret==0){
            var randstr = res.randstr;
            var ticket = res.ticket;
            that.loadFlag = true;
            that.axios.post(that.global.apiUrl + "/api/common/tencent_slide_validate",{
              Ticket:ticket,
              Randstr:randstr
            }).then(function(ress){
              if(ress.data.code==1){
                that.send_code();
              }else{
                Toast.fail(res.data.msg);
              }
            }).catch(function(e){
              that.loadFlag = false;
            })
          }
        });
        Captcha.show();
      }
    },
    /////////////////发送邮箱验证码
    send_code(){
      let that = this;
      that.axios.post(that.global.apiUrl + "/api/common/send_smtp",{
        email_address:that.email_address,
        type:that.smtp_type
      }).then(function(res){
        that.loadFlag = false;
        if(res.data.code==0){
          Toast.fail(res.data.msg);
        }else{
          that.$refs.ver_code_send.innerHTML = that.time_count;
          that.$refs.ver_code_send.style['background-color'] = '#aaa';
          var time = setInterval(()=>{
            that.time_count--;
            try {
              that.$refs.ver_code_send.innerHTML = that.time_count;
              if(that.time_count<=0){
                that.time_count = 59;
                that.$refs.ver_code_send.style['background-color'] = '#12A27E';
                that.$refs.ver_code_send.innerHTML = "发送验证码";
                clearInterval(time);
              }
            } catch (error) {
              console.log(error);
            }
          },1000)
        }
      })
    }
  }
}
</script>
<style>
.dialogs .van-dialog__footer::after{
  border: 0;
}
.dialogs .van-button__text{
  background: #26009A;
  border-radius: 23px;
  width: 21%;
  height: 7vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 90%;
  color: white;
}
.dialogs .van-button__content{
  background-color: black;
}
.dialogs .van-dialog__footer .van-button--default{
  background: linear-gradient(to right, #26009A, #7900E2);
  color: #ffffff;
  width: 20px;
}
.dialogs .van-dialog__footer{
  background-color: black;
}
.dialogs .van-dialog__header{
  padding-top: 5px;
  font-size: 90%;
}
.dialogs .van-dialog__message{
  padding: 0% 0%;
  color: #ffffff;
}
.dialogs .van-dialog__content{
  font-size: 120%;
  height: 40vw;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.dialogs{
  background: linear-gradient(to right, #26009A, #7900E2);
  color: #ffffff;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dialog1 {
  background-color: linear-gradient(to right, #26009A, #7900E2);
}
.btn{
  background-color:#12A27E;
  /* width: 15%; */
  width: 288px;
  margin: 0 auto;
  color: white;
  font-weight: bolder;
  border: 0;
  font-size: 100%;
  margin-top: 2%;
  /* letter-spacing: 5px; */
}
.foot{
  width: 100%;
  margin: 0 auto;
  margin-top: 60%;
}
.f-logo{
  width: 50%;
}
.yzm{
  position:absolute;
  top: 15%;
  right:2%;
  width: 30%;
  height: auto;
  line-height: 1.5vw;
  background-color: #12A27E;
  border-radius: 23px;
  color:#cccccc;
  font-size: 60%;
}
.pyz{
  /* width: 15%; */
  width: 288px;
  margin: 0 auto;
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.p-c{
  width: 288px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  color: #cccccc;
  /* padding: 2% 6.8%;
  padding-right: 4%; */
  box-sizing: border-box;
  font-size: 90%;
  letter-spacing: 1px;
  margin-top: 10px;
}
.van-cell::after{
  border-bottom: 0;
}
::v-deep input.van-field__control::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 90%;
}
::v-deep input.van-field__control{
  color: #353A40;
  margin-left: 0%;
  font-size: 100%;
}
.i-title{
  font-size: 130%;
  font-weight: bolder;
  /* font-weight: 400; */
  letter-spacing: 2px;
}
.i-input{
  width: 15%;
  height: auto;
  /* width: 254px;
  height: 24px; */
  min-width: 15%;
  margin: 0 auto;
  /* background: linear-gradient(to right, #26009A, #7900E2); */
  /* border-radius: 23px; */
  border: 1px solid #D2D7DD;
  color: black;
  display: flex;
  align-items: center;
  transition: box-shadow .2s ease-in-out,border-color .2s ease-in-out;
  border-radius: 3px;
}
.login {
  margin: 0;
  padding: 0;
  background-color: white;
  color:black;
}
</style>
