<template>
  <div style="overflow-y: hidden;">
    <UserIndexYd3HH :style="{'height':screenHeight*0.92+'px'}" class="hh safe-area-inset-bottom" @changeNavEvent="changeNav" :message_list="message_list" :user_info="user_info" :message_type="message_type" v-show="active==0"  ref="hh"></UserIndexYd3HH>

    <UserIndexYd3ZL :style="{'height':screenHeight*0.92+'px'}" class="zl safe-area-inset-bottom" @changeNavEvent="changeNavZlToGPT" :article_list="article_list" :user_info="user_info" :mj_rand="mj_rand" v-show="active==1"></UserIndexYd3ZL>

    <UserIndexYdSora :style="{'height':screenHeight*0.92+'px'}" class="zl safe-area-inset-bottom" @changeNavEvent="changeNavZlToGPT" :article_list="article_list" :user_info="user_info" v-show="active==3"></UserIndexYdSora>


    <UserIndexYd3WD :style="{'height':screenHeight*0.92+'px'}" class="wd safe-area-inset-bottom" @changeNavEvent="changeNav" :user_info="user_info" v-show="active==4"></UserIndexYd3WD>

    <UserIndexYd3GPT style="height:100%" class="gpt safe-area-inset-bottom" @changeNavEvent="changeNav" :messageList="messageList" :message_id="message_id" :article_id="article_id" :message_type="message_type" v-if="active==10"></UserIndexYd3GPT>

    <UserIndexYd3MJ style="height:100%;background-color: white;" class="gpt safe-area-inset-bottom" @changeNavEvent="changeNav" :messageList="messageList" :mj_rand="mj_rand" v-show="active==2"></UserIndexYd3MJ>

    <UserIndexYd3Rechar style="height:100%" class="rechar safe-area-inset-bottom" @changeNavEvent="changeNav" v-if="active==11"></UserIndexYd3Rechar>

    <UserIndexYd3MjRechar style="height:100%" class="rechar safe-area-inset-bottom" @changeNavEvent="changeNav" v-if="active==12"></UserIndexYd3MjRechar>

    <van-tabbar v-model="active" active-color="#12A27E" inactive-color="#000" @change="vChange" :style="{'height':screenHeight*0.08+'px'}" safe-area-inset-bottom ref="tabbar">
      <van-tabbar-item>
        <template #icon="props">
          <div style="display: flex; flex-direction: column;align-items: center;">
            <img :src="props.active ? icon.huihua1 : icon.huihua0" style="width: 18px;height: 18px;"/>
            <span :class="{'tabbar_bold': props.active}" style="font-size: 13px;margin-top: 7px;">会话</span>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item >
        <template #icon="props">
          <div style="display: flex; flex-direction: column;align-items: center;">
            <img :src="props.active ? icon.zhuli1 : icon.zhuli0" style="width: 18px;height: 18px;"/>
            <span :class="{'tabbar_bold': props.active}" style="font-size: 13px;margin-top: 7px;">助理</span>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item >
        <template #icon="props">
          <div style="display: flex; flex-direction: column;align-items: center;">
            <img :src="props.active ? icon.mjhb1 : icon.mjhb0" style="width: 18px;height: 18px;"/>
            <span :class="{'tabbar_bold': props.active}" style="font-size: 13px;margin-top: 7px;">画图</span>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item >
        <template #icon="props">
          <div style="display: flex; flex-direction: column;align-items: center;">
            <img :src="props.active ? icon.sora1 : icon.sora0" style="width: 18px;height: 18px;"/>
            <span :class="{'tabbar_bold': props.active}" style="font-size: 13px;margin-top: 7px;">Sora</span>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item >
        <template #icon="props">
          <div style="display: flex; flex-direction: column;align-items: center;">
            <img :src="props.active ? icon.wode1 : icon.wode0" style="width: 18px;height: 18px;"/>
            <span :class="{'tabbar_bold': props.active}" style="font-size: 13px;margin-top: 7px;">我的</span>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<style scoped lang="scss">
.safe-area-inset-bottom{
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom); 
  box-sizing: border-box;
}
.hh{
  width: 100%;position: relative;background-color: #FAFBFD;
  
}
.zl{
  width: 100%;position: relative;background-color: #F0F9F9;
}
.wd{
  width: 100%;position: relative;background-color: #F0F9F9;
  
}
.gpt{
  width: 100%;position: absolute;left: 0;top: 0;z-index:99; background-color: #F0F9F9;
}
.rechar{
  width: 100%;position: absolute;left: 0;top: 0;z-index:99; background-color: #FAFBFD;
}
.tabbar_bold{
  font-weight: 500;
}
</style>
<style >
</style>
<script>
import UserIndexYd3HH from '../components/UserIndexYd3HH.vue';
import UserIndexYd3ZL from '../components/UserIndexYd3ZL.vue';
import UserIndexYd3WD from '../components/UserIndexYd3WD.vue';
import UserIndexYd3GPT from '../components/UserIndexYd3GPT.vue';
import UserIndexYd3Rechar from '../components/UserIndexYd3Rechar.vue';
import UserIndexYd3MjRechar from '../components/UserIndexYd3MjRechar.vue';
import UserIndexYd3MJ from '../components/UserIndexYd3MJ.vue';
import UserIndexYdSora from './UserIndexYdSora.vue';
import { Toast } from 'vant';
import VConsole from 'vconsole'

export default {
  name: 'UserIndexYd3',
  components: { 
    UserIndexYd3HH,
    UserIndexYd3ZL,
    UserIndexYd3WD,
    UserIndexYd3GPT,
    UserIndexYd3Rechar,
    UserIndexYd3MJ,
    UserIndexYd3MjRechar,
    UserIndexYdSora
  },
  data() {
    return {
      screenWidth:window.screen.width,
      screenHeight:document.documentElement.clientHeight,
      active:0,
      icon: {
        huihua0: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/huihua0.png',
        huihua1: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/huihua1.png',
        zhuli0: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/zhuli0.png',
        zhuli1: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/zhuli1.png',
        mjhb0: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/mj_hb0.png',
        mjhb1: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/mj_hb1.png',
        sora0: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/sora0.png',
        sora1: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/sora1.png',
        wode0: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/wode0.png',
        wode1: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/wode1.png',
        gpttx: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/gpttx.png',
        sx_btn: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/sx_btn.png',
        sx: 'https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/sx.png',
      },
      message_list:[
        {
          id: 1,
          type: 1, //1使用示例  2新会话
          message: '如何使用 GPT22'
        },
        {
          id: 2,
          type: 2, //1使用示例  2新会话
          message: '帮我写啊沙发沙发沙发撒啊沙发沙发是发撒法'
        },
        {
          id: 3,
          type: 2, //1使用示例  2新会话
          message: '帮我写啊沙发沙发沙发撒啊沙发沙发是发撒法'
        },
        {
          id: 4,
          type: 2, //1使用示例  2新会话
          message: '帮我写啊沙发沙发沙发撒啊沙发沙发是发撒法'
        },
        {
          id: 5,
          type: 2, //1使用示例  2新会话
          message: '帮我写啊沙发沙发沙发撒啊沙发沙发是发撒法'
        },
        {
          id: 6,
          type: 2, //1使用示例  2新会话
          message: '帮我写啊沙发沙发沙发撒啊沙发沙发是发撒法'
        },
        {
          id: 7,
          type: 2, //1使用示例  2新会话
          message: '帮我写啊沙发沙发沙发撒啊沙发沙发是发撒法'
        },
        {
          id: 8,
          type: 2, //1使用示例  2新会话
          message: '帮我写啊沙发沙发沙发撒啊沙发沙发是发撒法'
        },
        {
          id: 9,
          type: 2, //1使用示例  2新会话
          message: '帮我写啊沙发沙发沙发撒啊沙发沙发是发撒法'
        },
        {
          id: 10,
          type: 2, //1使用示例  2新会话
          message: '帮我写啊沙发沙发沙发撒啊沙发沙发是发撒法'
        },
        {
          id: 11,
          type: 2, //1使用示例  2新会话
          message: '帮我写啊沙发沙发沙发撒啊沙发沙发是发撒法'
        },
        {
          id: 12,
          type: 2, //1使用示例  2新会话
          message: '帮我写啊沙发'
        }
      ],
      messageList:[],
      gptpay_token:'',
      article_list:[],
      user_info:{},
      wx_url: '',
      message_type: 1, //1:3.5  2:4.0,
      mj_rand:1
    }
  },
  beforeCreate(){
    document.title = 'AI超会玩';
  },
  watch:{
    
  },
  mounted(){
    // let vconsole = new VConsole()
    this.gptpay_token = localStorage.getItem("gptpay_token");
    if(!this.gptpay_token){
      // this.loginout();
      this.$router.replace({path:'/'});
    }
    this.get_userinfo();
    this.get_chatpay_message();
    this.get_article_zhuli();

  },
  methods: {
    changeNav(type,message_id=0,message_type=1){
      if(type==0){
        this.get_chatpay_message(message_type);
        this.get_userinfo();
      }else if(type==1){
        this.get_article_zhuli();
      }else if(type==2){
        this.get_userinfo();
      }
      if(message_id){
        for(let val of this.message_list){
          if(message_id==val.id){
            this.messageList = val.message;
            this.message_id = message_id;
            break;
          }
        }
      }else{
        this.messageList = [];
        this.message_id = 0;
      }
      this.article_id = 0;
      this.message_type = message_type;
      this.active = type;
      console.log(this.active);
    },
    changeNavZlToGPT(article_id,message_type=1){
      this.message_type = message_type;
      for(let val of this.article_list){
        if(article_id==val.id){
          this.messageList = [
            {
              'role': 'system',
              'content': val.prompt
            }
          ];
          this.message_id = 0;
          this.article_id = article_id;
        }
      }
      this.active = 10;  
    },
    vChange(index){
      this.mj_rand = Math.random(10000);
      if(index==0){
        this.get_chatpay_message(this.message_types);
      }else if(index==1){
        this.get_article_zhuli();
      }else if(index==3){
        this.get_userinfo();
      }
    },
    get_userinfo(){
      var that = this;
      that.axios.get(that.global.apiUrl + "/api/customer/get_userinfo",{
        headers:{
          'content-type': 'application/json',
          'token':that.gptpay_token
        }
      }).then(function(res){
        that.user_info = res.data.data;
        that.$forceUpdate();
      }).catch(function(err){
        if(err.response.status==400){
          that.loginout();
        }
      });
    },
    get_article_zhuli(){
      var that = this;
      that.axios.get(that.global.apiUrl + "/api/article_zhuli",{
        headers:{
          'content-type': 'application/json',
          'token':that.gptpay_token
        }
      }).then(function(res){
        if(res.data.code==1){
          that.article_list = res.data.data.list;
        }
      });
    },
    get_chatpay_message(type){
      var that = this;
      that.axios.get(that.global.apiUrl + "/api/customer/get_chatgpt_message",{
        headers:{
          'content-type': 'application/json',
          'token':that.gptpay_token
        },
        params:{
          'type':type
        }
      }).then(function(res){
        if(res.data.code==1){
          that.message_list = res.data.data.list;
          for(let val of that.message_list){
            if(val.message[0]['role']=='user'){
              val.message_title = val.message[0]['content'];
            }else{
              val.message_title = val.message[1]['content'];
            }
          }
        }
      });
    },
    loginout(){
      localStorage.setItem("gptpay_token", '');
      Toast({
        type:'success',
        message:"登录已过期，请重新登录",
        onClose: ()=>{
          this.$router.replace({path:'/login'});
        }
      })
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.UserIndex {
  margin: 0;
  padding: 0;
  /* background-color: black; */
  /* display: flex;
  justify-content: center; */
  /* align-items: center; */
}
</style>
