<template>
  <div id="app">
    <router-link to="/"></router-link>
    <router-view/>
  </div>
</template>

<script>
export default {
  methods:{
    goPAGE() {
        if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          return false;
        }
        else {
          /*window.location.href="你的电脑版地址";    */
          return true;
        }
    }
  },
  created(){
    // var href = window.location.href;
    // if(href.indexOf('192')==-1 && href.indexOf("https://qd.qdmedia.site/#/")==-1){
    //     window.location.href = "https://qd.qdmedia.site/#/";
    // }
  },
  mounted(){
    // if(this.goPAGE()){
    //   this.$router.replace('/p_index');
    // }else{
    //   this.$router.replace('/index');
    // }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
