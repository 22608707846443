<template>
  <div style="overflow-y: hidden;" ref="contain">
    <div style="width: 100%;height: 100%;position: relative;">
      <!--------------------头部导航-------------------->
      <div style="width: 95%;margin:2.5% auto;display: flex;border-radius: 3px;border: 1px solid #12A27E;" >
        <div style="width: 50%;padding: 5px 0;box-sizing: border-box;" :class="{'nav_choose':nav_choose_num==1,'nav_nochoose':nav_choose_num!=1}" @click="navChoose(1)">3.5 模型</div>
        <div style="width: 50%;padding: 5px 0;box-sizing: border-box;" :class="{'nav_choose':nav_choose_num==2,'nav_nochoose':nav_choose_num!=2}" @click="navChoose(2)">4.0 模型 ({{ user_info.gpt4_num }}次)</div>
      </div>
      <!--------------------历史信息-------------------->
      <div style="height: 83%;width: 95%;overflow-y: auto;margin: 0 auto;" ref="c1">
        <div v-for="(item,index) in message_list" :key="index" style="width: 100%;padding: 5% 0%;display: flex;margin-top: 2%;background-color: #fff;">
          <div style="width: 20%;display: flex;align-items: center;justify-content: center;">
            <div style="width: 25px;height: 25px;border-radius: 100%;background-color: #12A07C;color: white;display: flex;align-items: center;justify-content: center;">{{ index+1 }}</div>
          </div>
          <div style="width: 60%;" @click="toGPT2(item.id)">
            <div style="text-align: left;font-weight: bold;" v-show="item.system_name!=''"> {{item.system_name}} </div>
            <div style="text-align: left;font-weight: bold;" v-show="item.system_name==''">新会话</div>
            <div style="text-align: left;font-size: 90%;width: 100%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;margin-top: 1%;">{{ item.message_title }}</div>
          </div>
          <div style="width: 20%;display: flex;align-items: center;justify-content: center;color: #DF6060;" @click="delMessage(item.id)">删除</div>
        </div>
        <div style="width: 100%;height: 5vh;"></div>
      </div>
      <!-- <div style="background-color: red;width: 100%;height: 500px;"></div> -->
      <!--------------------底部按钮-------------------->
      <div style="width: 100%; display: flex;justify-content: space-between;position: absolute;bottom: 2%; padding-top: 2vw;padding-bottom: 2vw;padding-left: 2.5%;padding-right: 2.5%; box-sizing: border-box;background-color: #fff;border-top: 0.5px solid #F3F4F4;" ref="c2">
        <van-button style="width: 60%;color: #34AF91;border: 1px solid #34AF91;height: 40px;font-weight: bold;" icon="plus" @click="toGPT()"> 创建新会话
        </van-button>
        <van-button style="width: 33%;color: #E26969;border: 1px solid #E26969;height: 40px;font-weight: bold;" icon="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/del.png" @click="delMessage()">&nbsp;清空对话</van-button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.nav_choose{
  background-color: #12A27E;
  color:#FAFBFD;
}
.nav_nochoose{
  background-color: #FAFBFD;
  color:#12A27E;
}
.tabbar_bold{
  font-weight: 500;
}
::v-deep .van-field__control::-webkit-input-placeholder {
  color: #929292;
  font-size: 1rem;
}

</style>
<style scoped>
div::-webkit-scrollbar{
  display: none;
}
.scrollbar2::-webkit-scrollbar{
  width: 0px;
  display: none;
}
.scrollbar2::-webkit-scrollbar-thumb {
  border-radius: 0px;
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  opacity: 0;
  background: red;
}
.scrollbar2::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  border-radius: 0;
  /* background: #414141; */
}
</style>
<script>
import { Toast,Dialog,Popover,Uploader } from 'vant';

export default {
  name: 'UserIndexYd3HH',
  components: { 
  },
  props:{
    message_list: Array,
    user_info:Object
  },
  data() {
    return {
      message:'',
      nav_choose_num: 1,
    }
  },
  watch:{
    
  },
  mounted(){
    this.gptpay_token = localStorage.getItem("gptpay_token");
  },
  methods: {
    navChoose(num){
      this.nav_choose_num = num;
      this.$emit('changeNavEvent',0,0,num);
    },
    delMessage(id=0){
      if(id>0){
        Dialog.confirm({
          title: '',
          message: '对话删除后无法恢复，确认删除吗?',
        })
        .then(() => {
          this.del_chatgpt_message(id);
        })
      }else{
        Dialog.confirm({
          title: '',
          message: '清空对话后将无法恢复，确认清空吗?',
        })
        .then(() => {
          this.del_chatgpt_message(id);
        })
      }
    },
    del_chatgpt_message(id){
      var that = this;
      var ids = [id];
      if(!id){
        ids = [];
        for(let val of this.message_list){
          ids.push(val.id);
        }
      }
      that.axios.post(that.global.apiUrl+"/api/customer/del_chatgpt_message",{
        message_id: ids
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        if(res.data.code==1){
          that.$emit('changeNavEvent',0,0,that.nav_choose_num);
          Toast({
            type:'success',
            message:res.data.msg
          })
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    toGPT(message_id=0){
      if(this.nav_choose_num==2 && this.user_info.gpt4_num<=0){
        Toast({
          'type':'fail',
          'message':'您的4.0次数不足，无法开启会话'
        })
        return 
      }
      this.$emit('changeNavEvent',10,message_id,this.nav_choose_num);
    },
    toGPT2(message_id=0){
      this.$emit('changeNavEvent',10,message_id,this.nav_choose_num);
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.UserIndex {
  margin: 0;
  padding: 0;
  /* background-color: black; */
  /* display: flex;
  justify-content: center; */
  /* align-items: center; */
}
</style>
