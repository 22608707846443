<template>
  <div style="overflow-y: hidden;">
    <div  @scroll="scrollHandle" ref="scrollContainer" style="width: 100%;height: 100%;position: relative;display: flex;flex-direction: column;align-items: center;overflow-y: auto;">
      <div style="width: 95%;margin:6% 5%;display: flex;border-radius: 3px;" >
        <div style="margin-right: 3%">
          <!--              <img style="width: 100%" src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/promptSora.png">-->
          <span style="color: #1D1D1D;font-weight: bold;font-size: 1.2rem">PromptSora</span>
        </div>
        <div style="width: 18%;border: 1px solid #12A27E;border-radius: 3px">
          <span style="font-size: 0.8rem;color: #12A27E;line-height: 1.2rem">抢先体验</span>
        </div>
      </div>
      <div style="width: 80%;margin-top: 20%">
        <img style="width: 100%" src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/soraWorld.png">
      </div>
      <div style="width: 90%;text-align: left;margin-top: 2%">
        <span style="font-size: 0.83rem;font-weight: 400;color: #333333;line-height: 1.6rem">通过手工挑选的每周更新的资源、教程和示例提示，使用OpenAl的Sora从文本中创建视频，探索创意的世界。</span>
      </div>
      <div style="width: 100%;height:auto;margin-top: 2%;display: flex;flex-direction: column;align-items: center">
        <div v-for="(value,index) in list" :key="index" style="width: 90%;margin-top: 20px">
          <video style="width: 100%;border-radius: 3px" ref="video" autoplay="autoplay" playsinline="" loop="" muted="muted" preload="none"
                 aria-label="Video player">
            <source :src="value.video"
                    type="video/mp4">
          </video>
          <div style="width: 100%;text-align: left;margin-top: 10px">
              <span style="font-size: 0.85rem;font-weight: 400;color: #333333">
                 {{value.text}}
              </span>
          </div>
        </div>
      </div>
      <div  style="width: 100%;height:auto;margin-top: 2%;margin-bottom: 2px;display: flex;flex-direction: column;align-items: center">
        <span style="font-size: 0.83rem;font-weight: 400;color: #333333;line-height: 1.6rem">{{prompt}}</span>
      </div>
    </div>
    <van-overlay :show="zfFlag" style="z-index:9999;">
      <div style="width: 100%;height: 100%;display: flex;flex-wrap: wrap; align-items: center;justify-content: center;flex-direction: column; position: relative;">
        <div style="width: 10vw;height: 10vw;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="rechar_check_cannel">X</div>
        <img :src="qrcode_url" alt="" style="width: 50%;">
        <div style="width: 100%;margin-top: 5%;color: white;">{{ payMsg }}</div>
      </div>
    </van-overlay>
    <van-overlay :show="show_recharge" style="z-index:9999;">
      <div class="wrapper" @click.stop>
        <div style="width: 85%;padding: 7% 5%;box-sizing: border-box;border: 1px solid #12A27E;border-radius: 3px;background-color: #FAFBFD;">
          <div style="font-size: 130%;font-weight: bold;">9.9元解锁更多Sora Prompts</div>
          <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 10%;">
            <div style="width: 40%;padding: 2% 3%;border: 1px solid #12A27E;border-radius: 3px;"  @click="recharge(1)">微信支付</div>
            <div style="width: 40%;padding: 2% 3%;border: 1px solid #12A27E;border-radius: 3px;" @click="recharge(2)">支付宝支付</div>
          </div>
          <div style="width: 10vw;height: 10vw;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="show_recharge=false">X</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>

import {Dialog, Toast} from "vant";

// import VConsole from 'vconsole';
// const vConsole = new VConsole({ // 配置选项
//   defaultPlugins: ['system', 'network', 'element'],
//   // 默认开启的插件
//   onReady() {
//
//     console.log('vConsole is ready!');
//
//   },
// });

export default {
  name: 'UserIndexSora',
  props:{
    user_info:Object
  },
  data() {
    return {
      list: [],
      is_recharge:false,
      show_recharge:false,
      page:1,
      prompt:'加载数据...',
      out_trade_no:'',
      payType:1,
      qrcode_url: '',
      zfFlag: false,
      timer:'',
      is_play:false,
      play_num:0,
      payMsg:"请用支付宝扫码支付",
      ls_recharge:false,
      get_list:true,
    }
  },
  mounted(){
    this.gptpay_token = localStorage.getItem("gptpay_token");
    this.getList(this.page);
  },
  methods: {
    getList(page){
      var that = this;
      var sora_list_url = that.global.apiUrl + "/api/sora/soraList";
      that.axios.post(sora_list_url,{
        page: page,
        type:1
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        if(res.data.code==1){
          if(page == 1){
            that.list = res.data.data.list
          }else{
            that.list = that.list.concat(res.data.data.list);
          }

          console.log(that.list);
          //
          if(res.data.data.sora == 1){
            that.is_recharge = true
          }
          that.get_list = false;
          setTimeout(() => {
             that.playNum(that.play_num);
          },  1000);
        }

      });
    },
    playNum(play_num){
      var that = this;
      for (let i = that.play_num; i < that.list.length; i++) {
        this.$refs.video[i].play(); // 利用 setTimeout 函数设置不同的延迟时间，确保视频能够同时播放
        that.play_num = play_num + 1
      }
    },
    setPlay(){
      var that = this;
        for (let i = 0; i < that.list.length; i++) {
            this.$refs.video[i].play(); // 利用 setTimeout 函数设置不同的延迟时间，确保视频能够同时播放
        }
       // 这里的延迟时间根据需求自行调整
    },
    rechar_check_cannel(){
      this.zfFlag=false
      clearInterval(this.timer);
    },
    recharge(type){
      var that = this;
      var recharge_money = 9.9;
      var recharge_url = "";
      var opid = localStorage.getItem("opid");
      var recharge_type = 'sora'
      that.payType = type;
      if(type==2) {
        recharge_url = that.global.apiUrl + "/api/customer/recharge";
        that.zfbRechar(recharge_url, recharge_money, recharge_type);
      }else {
        if(opid){
          //微信浏览器
          recharge_url = that.global.apiUrl + "/api/customer/recharge_jsapi_wx";
          that.wxJsapiRechar(recharge_url, recharge_money, opid, recharge_type);
        }else{
          //外部浏览器
          recharge_url = that.global.apiUrl + "/api/customer/recharge_h5_wx";
          that.wxH5Rechar(recharge_url, recharge_money, recharge_type);
        }
      }
    },
    wxH5Rechar(recharge_url, recharge_money, recharge_type){
      var that = this;
      that.axios.post(recharge_url,{
        recharge_money: recharge_money,
        recharge_type: recharge_type,
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        if(res.data.code==1){
          var h5_url = res.data.data.h5_url;
          that.timer = setInterval(()=>{
            that.recharge_check();
          },3000);
          window.location.href = h5_url;
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    wxJsapiRechar(recharge_url, recharge_money, opid, recharge_type){
      var that = this;
      that.axios.post(recharge_url,{
        recharge_money: recharge_money,
        recharge_type: recharge_type,
        opid: opid,
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        var appid = res.data.data.appid;
        var timeStamp = res.data.data.timeStamp.toString();
        var nonceStr = res.data.data.nonceStr;
        var paySign = res.data.data.paySign;
        var prepay_id = res.data.data.prepay_id;
        that.out_trade_no = res.data.data.out_trade_no;
        prepay_id = "prepay_id=" + prepay_id;
        that.onBridgeReady(appid, timeStamp, nonceStr, prepay_id, paySign);
        that.rechar_type_num = 0;

        that.timer = setInterval(()=>{
          that.recharge_check(1);
        },3000)
      });
    },
    onBridgeReady(appid='', timeStamp='', nonceStr='', prepay_id='', paySign=''){
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
            "appId": appid,     //公众号ID，由商户传入
            "timeStamp": timeStamp,     //时间戳，自1970年以来的秒数
            "nonceStr": nonceStr,      //随机串
            "package": prepay_id,
            "signType": "RSA",     //微信签名方式：
            "paySign": paySign //微信签名
          },
          function(res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            }
          });
    },
    zfbRechar(recharge_url, recharge_money, recharge_type){
      var that = this;
      that.axios.post(recharge_url,{
        recharge_money: recharge_money,
        recharge_type: recharge_type
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        if(res.data.code==1){
          that.is_recharge = true;
          that.show_recharge = false;
          that.zfFlag = true;
          that.qrcode_url = res.data.data.qrcode_url;
          that.out_trade_no = res.data.data.out_trade_no;
          that.timer = setInterval(()=>{
            that.recharge_check(2);
          },3000)
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    recharge_check(){
      var that = this;
      var recharge_url = "";
      if(that.payType==2) recharge_url = that.global.apiUrl + "/api/customer/recharge_check";
      else recharge_url = that.global.apiUrl + "/api/customer/recharge_check_wx";
      that.axios.post(recharge_url,{
        out_trade_no: that.out_trade_no
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        if(res.data.code==1){
          clearInterval(that.timer);
            that.is_recharge = true
            that.show_recharge = false;
            that.is_play = false;
            that.zfFlag = false;
            that.qrcode_url = '';
            that.out_trade_no = '';
            that.$refs.scrollContainer.scrollTop = 500;
            // const container = that.$refs.scrollContainer;
            // if (container) {
            //   const height = container.clientHeight - 2; // 计算滚动条应该位于的位置（减去5像素）
            //   container.style.height = `${height}px`;
            // }
            Toast({
              'type':'success',
              'message':'充值成功',
              'duration':3000,
            })
        }
        // console.log(res.data);
      });
    },

    scrollHandle (e) {
      if(this.get_list == false){
        if ((e.srcElement.offsetHeight + e.srcElement.scrollTop) - e.srcElement.scrollHeight > -1) {
          if(this.is_recharge == false){
               this.show_recharge = true;
               return ;
          }
          if(this.page < 17) {
            this.get_list  = true
            this.page = this.page + 1;
            this.setPlay();
            setTimeout(() => {
              this.getList(this.page);
            },  1000); // 这里的延迟时间根据需求自行调整

          }else{
            this.setPlay();
            this.prompt="没有数据"
          }
        }
      }
    },

  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>