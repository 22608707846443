<template>
  <div style="overflow-y: hidden;">

    <!--------------------返回按钮-------------------->
    <van-icon name="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/back.png" size="35" style="position: fixed;left:5%;top: 3%;z-index: 120;" @click="toHH()"></van-icon>

    <!--------------------对话内容-------------------->
    <div style="width: 100%;height: 100%;overflow-y: auto;" ref="messageContent">
      <div v-for="(item,index) in msgList" :key="`1`+index" style="width: 100%;padding:5vw;box-sizing: border-box;">
        <div style="width: 100%;text-align: left;" v-show="item.role=='assistant'">
          <div style="width: 100%;display: flex;align-items: center;padding: 3% 0;box-sizing: border-box;">
            <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/gpttx.png" alt="" style="width: 10%;">
            <div style="margin-left: 2%;font-size: 100%;">AI超会玩</div>
          </div>
          <div ref="content" class="content">{{ item.content }}</div>
        </div>
        <div style="width: 100%;text-align: right;" v-show="item.role=='user' || item.role=='system'">
          <div style="width: 100%;display: flex;flex-direction:row-reverse;align-items: center;padding: 3% 0;box-sizing: border-box;">
            <img src="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/usertx.png" alt="" style="width: 10%;">
            <div style="margin-right: 2%;font-size: 100%;">Me</div>
          </div>
          <div style="background-color: #ECEEF7;border-radius: 5px;text-align: left;white-space: pre-line;word-break: break-all;padding: 3% 4%;box-sizing: border-box;width: auto;display: inline-block;">{{ item.content }}</div>
        </div>
      </div>
      <div style="width: 100%;height: 10vh;"></div>
    </div>

    <!--------------------底部输入-------------------->
    <div style="width: 100%;position: absolute;bottom: 0px;left: 0;background-color: #fff;" ref="binput"></div>
    <div style="width: 65%;max-height:198px;position: absolute; bottom: 20px;left:5%;overflow-y: auto;border-radius: 3px;box-shadow: var(0 0 transparent,0 0 transparent),var(0 0 transparent,0 0 transparent),var(0 0 transparent);display:flex;" ref="van_field_f">
      <van-field v-model="message" rows="1" autosize type="textarea" placeholder="发送一条信息" style="resize: none;overflow-y: auto;font-size: 16px;line-height: 1.5;padding: 6px 8px;box-sizing: border-box;width: 100%;white-space: pre-line;word-break: break-all;border:0;background-color: #F3F4F6;" class="scrollbar2" ref="van_field">
      </van-field>
    </div>
    <van-button style="width: 20%;position: absolute;bottom: 21px;left:75%;background-color: #12A07C;color: white;" :disabled="btnFlag" :icon="right_icon" ref="binput_right" :loading="runFlag" loading-type="spinner" @click="chatqaAdd(message)"></van-button>
   
  </div>
</template>
<style scoped>
.content{
  background-color: #ECEEF7;
  border-radius: 5px;
  text-align: left;
  /* white-space: pre-line; */
  word-break: break-all;
  padding: 3% 4%;
  box-sizing: border-box;
  width: auto;
  display: inline-block;
  max-width: 100%;
}
.tabbar_bold{
  font-weight: 500;
}
::v-deep .van-field__control::-webkit-input-placeholder {
  color: #929292;
  font-size: 1rem;
}
</style>
<style>
pre{
  width: 100%;
  padding: 10px;
  background-color: #23241f;
  border-radius: 6px;
  color: #f8f8f2;
  box-sizing: border-box;
  overflow-x: auto;
}
.content p{
  margin: 0;
}
div::-webkit-scrollbar{
  display: none;
}
.scrollbar2::-webkit-scrollbar{
  width: 0px;
  display: none;
}
.scrollbar2::-webkit-scrollbar-thumb {
  border-radius: 0px;
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  opacity: 0;
  background: red;
}
.scrollbar2::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  border-radius: 0;
  /* background: #414141; */
}
</style>
<script>
import { EventSourcePolyfill } from "event-source-polyfill";
import { marked } from 'marked';
import { Toast,Dialog,Popover,Uploader } from 'vant';
import UserMic from '../components/UserMic.vue';

export default {
  name: 'UserIndexYd2HH',
  components: { 
  },
  props:{
    messageList: Array,
    message_id: Number,
    article_id: Number,
    message_type: Number
  },
  data() {
    return {
      message:'',
      btnFlag: true,
      runFlag: false,
      msgList:[],
      msgId:0,
      ty:2, //1使用示例 2新会话
      right_icon_send:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/send2.png",
      right_icon_stop:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/stop2.png",
      right_icon:"https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/send2.png",
      articleId:0
    }
  },
  watch:{
    messageList(val,val2){
      // console.log(val);
      // console.log(val2);
    },
    message(val,val2){
      setTimeout(()=>{
        var van_field_f = this.$refs.van_field_f;
        this.$refs.binput.style.height = (van_field_f.offsetHeight + 40) + 'px';
      },33)
      if(val){
        this.btnFlag = false;
      }else{
        this.btnFlag = true;
      }
    }
  },
  mounted(){
    var van_field_f = this.$refs.van_field_f;
    this.$refs.binput_right.style.height = (van_field_f.offsetHeight) + 'px';
    this.$refs.binput.style.height = (van_field_f.offsetHeight + 40) + 'px';
    this.gptpay_token = localStorage.getItem("gptpay_token");

    var rendererMD = new marked.Renderer();
    marked.setOptions({
      renderer: rendererMD,
      gfm: true,
      tables: true,
      breaks: false,
      pedantic: false,
      sanitize: false,
      smartLists: true,
      smartypants: false,
      mangle:false,
      headerIds:false,
      windowInnerHeight1: 0,
      windowInnerHeight2: 0,
    });

    this.msgList = this.messageList;

    setTimeout(()=>{
      for(let val in this.messageList){
        var container = this.$refs.content[val];
        var replace = this.messageList[val]['content'].replace('.','。');
        container.innerHTML = marked.parse(replace);
      }
      this.scroend();
    },100);

    this.msgId = this.message_id;
    this.articleId = this.article_id;
  },
  methods: {
    toHH(){
      this.$emit('changeNavEvent',0,0,this.message_type);
    },
    chatqaAdd(question){
      if(!this.runFlag && this.right_icon==this.right_icon_send){
        this.runFlag = true;
        var answerContent='';
        var codeStart=false,lastWord='',lastLastWord='';
        for(let val of this.msgList){
          val.tokenFlag = false;
        }
        this.msgList.push({
          role: 'user',
          content: question,
          tokenFlag: false
        })
        this.scroend();
        question = question.replace(/\+/g,"%2B"); 
        question = question.replace(/\//g,"%2F"); 
        question = question.replace(/\?/g,"%3F");
        var url = this.global.apiUrl + '/api/common/chatgpt?t=' + this.gptpay_token + '&q=' + encodeURI(question) + '&m=' + this.msgId + '&zl=' + this.articleId + '&mt=' + this.message_type;
        this.eventS = new EventSourcePolyfill(url);
        this.eventS.onopen = (event) => {
          this.msgList.push({
            role: 'assistant',
            content: '',
            tokenFlag: true
          })
          this.right_icon = this.right_icon_stop;
          this.runFlag = false;
        };
        this.eventS.onClosed = (event) => {
          this.runFlag = false;
          this.message = '';
          this.right_icon = this.right_icon_send;
          this.articleId = 0;
        };
        this.eventS.onerror = (event) => {
          if (event.readyState === EventSource.CLOSED) {
            console.log('error');
          } else {
            if(event.status==400){
              Toast({
                message: '身份验证失败，请重新登录',
                type:'fail',
                onClose:()=>{
                  this.$router.replace({path:'/'});
                }
              });
            }
            if(event.status==401){
              Toast({
                message: '余额不足，请前往充值',
                type:'fail',
              });
            }
            if(event.status==402){
              Toast({
                message: '4.0次数不足，请前往充值',
                type:'fail',
              });
            }
            if(event.status==403){
              Toast({
                message: '平台4.0次数已不足请明天抢先使用',
                type:'fail',
              });
            }
          }
          this.eventS.close();
          this.runFlag = false;
          this.right_icon = this.right_icon_send;
          this.message = '';
          this.articleId = 0;
        };
        this.eventS.addEventListener("message", (event) => {
          let json_data = JSON.parse(event.data);
          let content = json_data['content'];
          this.msgId = json_data['message_id'];
          // console.log(json_data);
          
          /////////////////////token不足
          if(content != 'length'){
            if(content.indexOf('`') != -1){
              if(content.indexOf('```') != -1){
                  codeStart = !codeStart;
              }else if(content.indexOf('``') != -1 && (lastWord + content).indexOf('```') != -1){
                  codeStart = !codeStart;
              }else if(content.indexOf('`') != -1 && (lastLastWord + lastWord + content).indexOf('```') != -1){
                  codeStart = !codeStart;
              }
            }
            lastLastWord = lastWord;
            lastWord = content;
            answerContent += content;
            const l = this.$refs.content.length;
            const container = this.$refs.content[l-1];
            container.innerHTML = marked.parse(answerContent + (codeStart?'\n\n```':''));
            this.scroend();
          }
        });
      }else if(!this.runFlag && this.right_icon==this.right_icon_stop){
        this.eventS.close();
        this.runFlag = false;
        this.right_icon = this.right_icon_send;
      }
    },
    scroend() {
      // 让滚动条始终在最底部
      this.$nextTick(() => {
        this.$refs.messageContent.scrollTop = this.$refs.messageContent.scrollHeight
      })
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.UserIndex {
  margin: 0;
  padding: 0;
  /* background-color: black; */
  /* display: flex;
  justify-content: center; */
  /* align-items: center; */
}
</style>
