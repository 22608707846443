
// 接口地址
var apiUrl = "http://192.168.1.6:1000";
// var apiUrl = "http://192.168.132.125:10011";
// var apiUrl = "http://127.0.0.1:10011";
apiUrl = "https://wl.qdmedia.site";
var url = window.location.href;
if(process.env.NODE_ENV=="production"){
  if(url.indexOf('-test')==-1){
    apiUrl = "https://wl.qdmedia.site";
    // apiUrl = "http://38.47.255.83:10001";
    // apiUrl = "https://dev.api.metamall.cc";
  }
}
// apiUrl = "https://dev.api.metamall.cc";

var mobile = "";
var team_name = "177****5331";

// get(url,params={}){
//   var token = localStorage.getItem("token");
//   this.axios.get(apiUrl+url,{
//     params:{
//       params
//     },
//     headers:{
//       'content-type': 'application/json',
//       'chuangtoken':token
//     }
//   }).then(function(res){
//     return res;
//   }).catch(function (error) {
//     return error;
//   });
// }

// function post(url,data){
//   var token = localStorage.getItem("token");
//   this.axios.post(apiUrl + url,{data}).then(function(res){
//     if(res.data.code==1){
//       Toast.success(res.data.msg);
//       setTimeout(()=>{
//         // if(res.data.data.has_contract==1){
//           this.$router.replace('/main');
//         // }else if(res.data.data.has_contract==0){
//         //   that.$router.replace('/contract');
//         // }
//       },2000)
//       localStorage.setItem("token", res.data.data.chuangtoken);
//     }else{
//       Toast.fail(res.data.msg);
//     }
//   })
// }
 
export default {
  apiUrl,
  mobile,
  team_name,
  test:function (val){
  },
};