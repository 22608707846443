<template>
  <div class="startup" :style="{'width':screenWidth+'px','height':screenHeight+'px'}">
    <img src="../assets/logo3.jpg" alt="" style="width: 32px;">
    <div style="height: 10px;"></div>
    <div>欢迎访问AI超会玩</div>
    <div style="height: 20px;"></div>
    <div style="display: flex;justify-content: center;width: 182px;">
      <van-button type="success" style="border-radius: 3px;background-color: #12A27E;border: 0;color:white;height: 30px;" @click="login(1)">&nbsp;&nbsp;登&nbsp;录&nbsp;&nbsp;</van-button>
      <div style="width: 26px;"></div>
      <van-button type="success" style="border-radius: 3px;background-color: #12A27E;border: 0;color:white;height: 30px;" @click="login(2)">&nbsp;&nbsp;注&nbsp;册&nbsp;&nbsp;</van-button>
    </div>
    <div style="height: 20%;"></div>
  </div>
</template>
<style>
  .sw1{
    width: 10%;
  }
  .sw2{
    width: 50%;
  }
</style>
<script>
export default {
  name: 'StartUp',
  components: {
  },
  data() {
    return {
      screenWidth:document.documentElement.clientWidth,
      screenHeight:document.documentElement.clientHeight,
      opacity:0,
    }
  },
  mounted(){
    
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      this.$router.replace({path:'/login','query':{'type':1}});
    }
    var that = this;
    window.addEventListener('resize',function() {
      that.screenWidth = window.innerWidth;
      that.screenHeight = window.innerHeight;
    });
    this.gptpay_token = localStorage.getItem("gptpay_token");
    if(this.gptpay_token){
      this.$router.replace({path:'/index'});
    }
  },
  methods: {
    login(num){
      this.$router.replace({'path':'login','query':{'type':num}})
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.startup{
  margin: 0;
  padding: 0;
  background-color: white;
  color:black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content{
  display: block;
  color: white;
  font-weight: bold;
}
</style>
