import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/axios'
import './plugins/vant.js'
import global from '@/js/global.js'
// import vconsole from '@/js/vconsole.js'

/////////////复制粘贴
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

/////////////二维码生成
// import QRCode from "qrcode"
// Vue.use(QRCode)
// Vue.prototype.QRCode = QRCode;

Vue.config.productionTip = false
Vue.prototype.global = global;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
