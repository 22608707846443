<template>
  <div style="overflow-y: auto;">
    <div style="width: 100%;height: 1%;"></div>
    <div v-for="(item,index) in article_list" :key="`2`+index" style="width: 90%;border-radius: 4px;padding: 3% 3%;box-sizing: border-box;display: flex;background-color:#fff;margin: 0 auto;margin-top: 2%;" @click="chooseArticleId(item.id)">
      <img :src="item.picture" alt="" style="width: 25%;">
      <div style="margin-left: 3%;width: 72%;">
        <div style="font-weight: bold;width: 100%;text-align: left;">{{ item.title }}</div>
        <div style="width: 100%;text-align: left;">{{ item.describe }}</div>
      </div>
    </div>
    <div style="width: 100%;height: 5vh;"></div>

    <van-overlay :show="choss_show" @click="choss_show = false">
      <div class="wrapper" @click.stop>
        <div style="width: 85%;padding: 7% 5%;box-sizing: border-box;border: 1px solid #12A27E;border-radius: 3px;background-color: #FAFBFD;">
          <div style="font-size: 130%;font-weight: bold;">选择模型</div>
          <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 10%;">
            <div style="width: 40%;padding: 2% 3%;border: 1px solid #12A27E;border-radius: 3px;" :class="{'nav_choose':choose_num==1,'nav_nochoose':choose_num!=1}" @click="toGPT(1, '3.5')">3.5模型</div>
            <div style="width: 40%;padding: 2% 3%;border: 1px solid #12A27E;border-radius: 3px;" :class="{'nav_choose':choose_num==2,'nav_nochoose':choose_num!=2}" @click="toGPT(2, '4.0')">4.0模型</div>
          </div>
          <div style="width: 10vw;height: 10vw;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="choss_show=false">X</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<style scoped>
.van-overlay{
  z-index:121;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.nav_choose{
  background-color: #12A27E;
  color:#FAFBFD;
}
.nav_nochoose{
  background-color: #FAFBFD;
  color:#12A27E;
}
</style>
<!-- 邀请好友页 -->
<script>
import { Toast } from 'vant';

export default {
  name: 'UserIndexYd2ZL',
  props:{
    article_list: Array,
    user_info: Object,
    mj_rand: Number
  },
  data() {
    return {
      choss_show:false,
      choose_article_id:0,
      choose_num:0
    }
  },
  watch:{
    mj_rand(val,val2){
      this.choss_show = false;
    },
  },
  mounted(){

  },
  methods: {
    chooseArticleId(article_id){
      this.choose_article_id = article_id;
      this.choss_show = true;
    },
    toGPT(choose_num, type){
      this.choose_num = choose_num;
      setTimeout(()=>{
        this.choose_num = 0;
      },300);
      if(type=='3.5'){
        setTimeout(()=>{
          this.$emit('changeNavEvent',this.choose_article_id, 1);
        },300);
      }else{
        if(this.user_info['gpt4_num']>0){
          setTimeout(()=>{
            this.$emit('changeNavEvent',this.choose_article_id, 2);
          },300);
        }else{
          Toast({
            'type':'fail',
            'message':'您的4.0次数不足，无法开启会话'
          })
        }
      }
      
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
