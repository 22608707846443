<template>
  <div class="UserIndex" :style="{'width':screenWidth+'px','height':screenHeight+'px'}">
    
    <UserIndexPc3 v-if="navigatorFlag" style="width: 100%;height: 100%;"></UserIndexPc3>
    <UserIndexYd3 v-if="!navigatorFlag" style="width: 100%;height: 100%;"></UserIndexYd3>
  </div>
</template>

<script>
import UserIndexPc3 from '../components/UserIndexPc4.vue';
import UserIndexYd3 from '../components/UserIndexYd3.vue';

export default {
  name: 'UserIndex',
  components: { 
    UserIndexPc3,
    UserIndexYd3
  },
  data() {
    return {
      screenWidth:window.screen.width,
      screenHeight:document.documentElement.clientHeight,
      navigatorFlag:0 //0移动端 1pc端
    }
  },
  beforeMount(){
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      this.navigatorFlag = 0;
    }
    else {
      /*window.location.href="你的电脑版地址";    */
      this.navigatorFlag = 1;
    }
  },
  mounted(){
    var that = this;
    // that.gptpay_token = localStorage.getItem("gptpay_token");
    // if(!that.gptpay_token){
    //   that.$router.replace({path:'/login'});
    // }
    that.screenWidth = window.innerWidth;
    that.screenHeight = window.innerHeight;
    
    window.addEventListener('resize',function() {
      that.screenWidth = window.innerWidth;
      that.screenHeight = window.innerHeight;
    });
  },
  methods: {
    
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.UserIndex {
  margin: 0;
  padding: 0;
  /* background-color: black; */
  /* display: flex;
  justify-content: center; */
  /* align-items: center; */
}
</style>
