<template>
  <div style="overflow-y: auto;">
    <!--------------------返回按钮-------------------->
    <van-icon name="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/back.png" size="35" style="position: fixed;left:5%;top: 3%;z-index: 120;" @click="toHH()"></van-icon>

    <div style="height: 8%;"></div>
    <div style="width: 100%;padding: 5%;box-sizing: border-box;display: flex;flex-wrap: wrap;">
      <!--------------------按月收费-------------------->
      <div style="width: 100%;margin-top: 10px;">
        <div style="width: 100%;border-radius: 5px;padding: 3%;box-sizing: border-box;margin-top: 10px;" :class="{'yue_choose':yue_choose_num==1,'yue_nochoose':yue_choose_num!=1}" @click="yueChoose(1)">
          <div style="font-size: 110%;font-weight: bold;">月度会员</div>
          <div style="margin-top: 10px;">200组AI绘图</div>
          <div>49.99元</div>
          <div>折合0.25元/4张</div>
        </div>
        <div style="width: 100%;border-radius: 5px;padding: 3%;box-sizing: border-box;margin-top: 4%;" :class="{'yue_choose':yue_choose_num==2,'yue_nochoose':yue_choose_num!=2}" @click="yueChoose(2)">
          <div style="font-size: 110%;font-weight: bold;">季度会员</div>
          <div style="margin-top: 10px;">800组AI绘图</div>
          <div>129.99元</div>
          <div>折合0.25元/天</div>
        </div>
      </div>
     
      <van-button color="#12A27E" style="width: 100%;margin-top: 25px;" :disabled="btnDis" @click="rechar_type_show=true">立即支付</van-button>
      <!-- <div style="width: 100%;font-size: 80%;color: #AFAFAF;margin-top: 2%;text-align: center;">{{ bottom_tip }}</div> -->
    </div>

     <!-------------------- 等待 -------------------->
     <van-overlay :show="loadFlag">
      <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
        <van-loading type="spinner"/>
      </div>
    </van-overlay>

    <!-------------------- 支付宝支付界面 -------------------->
    <van-overlay :show="zfFlag" style="z-index:9999;">
      <div style="width: 100%;height: 100%;display: flex;flex-wrap: wrap; align-items: center;justify-content: center;flex-direction: column; position: relative;">
        <div style="width: 10vw;height: 10vw;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="rechar_check_cannel">X</div>
        <img :src="qrcode_url" alt="" style="width: 50%;">
        <div style="width: 100%;margin-top: 5%;color: white;">{{ payMsg }}</div>
      </div>
    </van-overlay>

    <van-overlay :show="rechar_type_show" @click="rechar_type_show = false">
      <div class="wrapper" @click.stop>
        <div style="width: 85%;padding: 7% 5%;box-sizing: border-box;border: 1px solid #12A27E;border-radius: 3px;background-color: #FAFBFD;">
          <div style="font-size: 130%;font-weight: bold;">选择支付方式</div>
          <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 10%;">
            <div style="width: 40%;padding: 2% 3%;border: 1px solid #12A27E;border-radius: 3px;" :class="{'nav_choose':rechar_type_num==1,'nav_nochoose':rechar_type_num!=1}" @click="recharge(1)">微信支付</div>
            <div style="width: 40%;padding: 2% 3%;border: 1px solid #12A27E;border-radius: 3px;" :class="{'nav_choose':rechar_type_num==2,'nav_nochoose':rechar_type_num!=2}" @click="recharge(2)">支付宝支付</div>
          </div>
          <div style="width: 10vw;height: 10vw;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="rechar_type_show=false">X</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<style scoped>
.van-overlay{
  z-index:121;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.nav_choose{
  background-color: #12A27E;
  color:#FAFBFD;
}
.nav_nochoose{
  background-color: #FAFBFD;
  color:#12A27E;
}
.yue_choose{
  border: 1px solid #12A27E;
  color:#12A27E;
}
.yue_nochoose{
  border: 1px solid #000;
  color:#000;
}
</style>
<!-- 邀请好友页 -->
<script>
import { Toast } from 'vant';

export default {
  name: 'UserIndexYd2ZL',
  props:{
    article_list: Array
  },
  data() {
    return {
      nav_choose_num: 1,
      yue_choose_num: 0,
      ci_choose_num:0,
      ci4_choose_num: 0,
      rechar_type_num: 0,
      bottom_tip: '包月会员到期后，将"按次计费"',
      btnDis: true,
      recharge_money: '',
      loadFlag: false,
      rechar_type_show: false,
      zfFlag: false,
      qrcode_url: '',
      out_trade_no: '',
      payMsg: "请使用支付宝扫码支付",
      timer: '',
    }
  },
  watch:{
    recharge_money(val,val2){
      if(this.recharge_money){
        this.btnDis = false;
      }else{
        this.btnDis = true;
      }
    },
  },
  mounted(){
    this.gptpay_token = localStorage.getItem("gptpay_token");
  },
  methods: {
    toHH(){
      this.$emit('changeNavEvent',3);
    },
    rechar_check_cannel(){
      this.zfFlag=false
      clearInterval(this.timer);
    },
    recharge(type){
      var that = this;
      var recharge_money = 0;
      var recharge_type = '';
      var yue_choose_num = that.yue_choose_num;
      that.rechar_type_num = type;
      //按月收费
      if(yue_choose_num==1) {
        recharge_money = 49.99;
        recharge_type = 'mj_yue_1';
      }else if(yue_choose_num==2) {
        recharge_money = 129.99;
        recharge_type = 'mj_yue_3';
      }
      if(recharge_money==0){
        Toast({
          'type':'fail',
          'message':'请输入要充值的金额'
        })
        that.recharge_money = '';
        return ;
      }
      that.rechar_type_show = false;
      that.loadFlag = true;
      var recharge_url = "";
      var opid = localStorage.getItem("opid");
      
      if(type==2) {
        recharge_url = that.global.apiUrl + "/api/customer/recharge";
        that.payMsg = "请使用支付宝扫码支付";
        that.zfbRechar(recharge_url, recharge_money, recharge_type);
      }else {
        if(opid){ 
          //微信浏览器
          recharge_url = that.global.apiUrl + "/api/customer/recharge_jsapi_wx";
          that.wxJsapiRechar(recharge_url, recharge_money, opid, recharge_type);
        }else{
          //外部浏览器
          recharge_url = that.global.apiUrl + "/api/customer/recharge_h5_wx";
          that.wxH5Rechar(recharge_url, recharge_money, recharge_type);
        }
      }
    },
    wxH5Rechar(recharge_url, recharge_money, recharge_type){
      var that = this;
      that.axios.post(recharge_url,{
        recharge_money: recharge_money,
        recharge_type: recharge_type,
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.loadFlag = false;
        that.recharge_money = '';
        for(let val of that.recharge_list){
          val.flag = false;
        }
        if(res.data.code==1){
          var h5_url = res.data.data.h5_url;
          that.timer = setInterval(()=>{
            that.recharge_check();
          },3000);
          window.location.href = h5_url;
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    wxJsapiRechar(recharge_url, recharge_money, opid, recharge_type){
      var that = this;
      that.axios.post(recharge_url,{
        recharge_money: recharge_money,
        recharge_type: recharge_type,
        opid: opid,
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.loadFlag = false;
        that.recharge_money = '';
        if(res.data.code==1){
          var appid = res.data.data.appid;
          var timeStamp = res.data.data.timeStamp.toString();
          var nonceStr = res.data.data.nonceStr;
          var paySign = res.data.data.paySign;
          var prepay_id = res.data.data.prepay_id;
          that.out_trade_no = res.data.data.out_trade_no;
          prepay_id = "prepay_id=" + prepay_id;
          that.onBridgeReady(appid, timeStamp, nonceStr, prepay_id, paySign);
          that.rechar_type_num = 0;

          that.timer = setInterval(()=>{
            that.recharge_check(1);
          },3000)
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    zfbRechar(recharge_url, recharge_money, recharge_type){
      var that = this;
      that.axios.post(recharge_url,{
        recharge_money: recharge_money,
        recharge_type: recharge_type
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        that.loadFlag = false;
        that.recharge_money = '';
        if(res.data.code==1){
          that.zfFlag = true;
          that.qrcode_url = res.data.data.qrcode_url;
          that.out_trade_no = res.data.data.out_trade_no;
          that.timer = setInterval(()=>{
            that.recharge_check(2);
          },3000)
          
        }else{
          Toast({
            type:'fail',
            message:res.data.msg,
          })
        }
      });
    },
    onBridgeReady(appid='', timeStamp='', nonceStr='', prepay_id='', paySign=''){
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
          "appId": appid,     //公众号ID，由商户传入     
          "timeStamp": timeStamp,     //时间戳，自1970年以来的秒数     
          "nonceStr": nonceStr,      //随机串     
          "package": prepay_id,
          "signType": "RSA",     //微信签名方式：     
          "paySign": paySign //微信签名 
      },
      function(res) {
        console.log(res);
          if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
      });
    },
    recharge_check(type){
      var that = this;
      var recharge_url = "";
      if(type==2) recharge_url = that.global.apiUrl + "/api/customer/recharge_check";
      else recharge_url = that.global.apiUrl + "/api/customer/recharge_check_wx";

      that.axios.post(recharge_url,{
        out_trade_no: that.out_trade_no
      },{
        headers:{
          'content-type': 'application/json',
          'token': that.gptpay_token
        }
      }).then(function(res){
        if(res.data.code==1){
          clearInterval(that.timer);
          that.zfFlag = false;
          that.qrcode_url = '';
          that.out_trade_no = '';
          Toast({
            'type':'success',
            'message':'充值成功',
            'duration':3000,
            'onClose':()=>{
              that.$emit('changeNavEvent',2);
            }
          })
        }
        // console.log(res.data);
      });
    },
    chooseRecharge(){
      this.ci_choose_num = 0;
      if(this.recharge_money){
        this.btnDis = false;
      }else{
        this.btnDis = true;
      }
    },
    ci4Choose(num){
      this.ci4_choose_num = num;
      this.btnDis = false;
    },
    yueChoose(num){
      this.yue_choose_num = num;
      this.btnDis = false;
    },
    ciChoose(num){
      this.ci_choose_num = num;
      this.btnDis = false;
    },
    navChoose(num){
      if(this.nav_choose_num!=num){
        this.btnDis = true;
      }
      if(num==1){
        this.bottom_tip = '包月会员到期后，将"按次计费"';
      }else if(num==2){
        this.bottom_tip = '余额无法提现';
        if(this.recharge_money){
          this.btnDis = false;
        }else{
          this.btnDis = true;
        }
      }else if(num==3){
        this.bottom_tip = '';
      }
      this.nav_choose_num = num;
      this.yue_choose_num = 0;
      this.ci_choose_num = 0;
      this.ci4_choose_num = 0;
      console.log(this.nav_choose_num);
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
