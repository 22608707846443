<template>
  <div style="overflow-y: auto;height: 492px;position: relative;">
    <!--------------------返回按钮-------------------->
    <div style="width: 100%;text-align: right;padding: 1% 1.5%;box-sizing: border-box;">
      <van-icon name="https://wanlian2022.oss-cn-hangzhou.aliyuncs.com/chatgpt/close.png" size="20" style="margin-top: 5px;" @click="toClose()"></van-icon>
    </div>
    
    <!-- <div style="height: 1vw;"></div> -->
    <div style="width: 100%;padding: 5%;padding-top: 0%; box-sizing: border-box;display: flex;flex-wrap: wrap;">
      <!--------------------头部导航-------------------->
      <div style="width: 100%;display: flex;border-radius: 3px;align-items: center;justify-content: center;" >
        <div style="width: 20%;padding: 5px 0;box-sizing: border-box;border-right: 1px solid #12A27E;border-left: 1px solid #12A27E;border-top-left-radius: 3px;border-bottom-left-radius: 3px;" :class="{'nav_choose':nav_choose_num==1,'nav_nochoose':nav_choose_num!=1}" @click="navChoose(1)">3.5按月收费</div>
        <div style="width: 20%;padding: 5px 0;box-sizing: border-box;border-right: 1px solid #12A27E;" :class="{'nav_choose':nav_choose_num==2,'nav_nochoose':nav_choose_num!=2}" @click="navChoose(2)">3.5按次购买</div>
        <div style="width: 20%;padding: 5px 0;box-sizing: border-box;border-right: 1px solid #12A27E;" :class="{'nav_choose':nav_choose_num==3,'nav_nochoose':nav_choose_num!=3}" @click="navChoose(3)">4.0按次购买</div>
        <div style="width: 20%;padding: 5px 0;box-sizing: border-box;border-right: 1px solid #12A27E;" :class="{'nav_choose':nav_choose_num==4,'nav_nochoose':nav_choose_num!=4}" @click="navChoose(4)">AI绘画套餐</div>
        <div v-if="!is_recharge" style="width: 20%;padding: 5px 0;box-sizing: border-box;border-right: 1px solid #12A27E;border-top-right-radius: 3px;border-bottom-right-radius: 3px;" :class="{'nav_choose':nav_choose_num==5,'nav_nochoose':nav_choose_num!=5}" @click="navChoose(5)">Sora Prompts解锁</div>
      </div>
      <!--------------------按月收费-------------------->
      <div style="width: 100%;margin-top: 15px;display: flex;flex-wrap: wrap;letter-spacing:1px;" v-show="nav_choose_num==1">
        <div style="width: 30%;border-radius: 3px;padding: 3%;box-sizing: border-box;margin-top: 10px;" :class="{'yue_choose':yue_choose_num==1,'yue_nochoose':yue_choose_num!=1}" @click="yueChoose(1)">
          <div style="font-size: 130%;font-weight: bold;padding: 7% 0%;box-sizing: border-box;">包月不限次</div>
          <!-- <div style="margin-top: 20px;">限时每日赠送</div> -->
          <!-- <div style="margin-top: 3px;">10次4.0模型体验</div> -->
          <div style="font-weight:bold;margin-top: 3px;">49.99元</div>
          <div style="margin-top: 3px;">折合1.63元 / 天</div>
        </div>
        <div style="width: 30%;border-radius: 3px;padding: 3%;box-sizing: border-box;margin-top: 10px;margin-left: 5%;" :class="{'yue_choose':yue_choose_num==2,'yue_nochoose':yue_choose_num!=2}" @click="yueChoose(2)">
          <div style="font-size: 130%;font-weight: bold;padding: 7% 0%;box-sizing: border-box;">包季不限次</div>
          <!-- <div style="margin-top: 20px;">限时每日赠送</div> -->
          <!-- <div style="margin-top: 3px;">10次4.0模型体验</div> -->
          <div style="font-weight:bold;margin-top: 3px;">129.99元</div>
          <div style="margin-top: 3px;">折合1.43元 / 天</div>
        </div>
        <div style="width: 30%;border-radius: 3px;padding: 3%;box-sizing: border-box;margin-top: 10px;margin-left: 5%;" :class="{'yue_choose':yue_choose_num==3,'yue_nochoose':yue_choose_num!=3}" @click="yueChoose(3)">
          <div style="font-size: 130%;font-weight: bold;padding: 7% 0%;box-sizing: border-box;">包年不限次</div>
          <!-- <div style="margin-top: 20px;">限时每日赠送</div> -->
          <!-- <div style="margin-top: 3px;">10次4.0模型体验</div> -->
          <div style="font-weight:bold;margin-top: 3px;">399.99元</div>
          <div style="margin-top: 3px;">折合1.09元 / 天</div>
        </div>
      </div>
      <!--------------------按次收费-------------------->
      <div style="width: 100%;margin-top: 25px;display: flex;flex-wrap: wrap;" v-show="nav_choose_num==2">
        <div style="width: 30%; padding: 2% 0;box-sizing:border-box;border-radius: 5px;" :class="{'ci_choose':ci_choose_num==1,'ci_nochoose':ci_choose_num!=1}" @click="ciChoose(1)">
          <div style="display: flex;align-items: center;justify-content: center;">
            <span style="font-size: 230%;font-weight: bold;">5  </span>
            <span style="font-size: 200%;font-weight: bold;margin-left: 5px;position: relative;top:-3px;">元</span>
          </div>
          <span style="font-size: 100%;">提问：100次</span>
        </div>
        <div style="width: 30%; padding: 2% 0; box-sizing:border-box;border-radius: 5px;margin-left: 5%;" :class="{'ci_choose':ci_choose_num==2,'ci_nochoose':ci_choose_num!=2}" @click="ciChoose(2)">
          <div style="display: flex;align-items: center;justify-content: center;">
            <span style="font-size: 230%;font-weight: bold;">20  </span>
            <span style="font-size: 200%;font-weight: bold;margin-left: 5px;position: relative;top:-3px;">元</span>
          </div>
          <span style="font-size: 100%;">提问：400次</span>
        </div>
        <div style="width: 30%; padding: 2% 0;box-sizing:border-box;border-radius: 5px;margin-left: 5%;" :class="{'ci_choose':ci_choose_num==3,'ci_nochoose':ci_choose_num!=3}" @click="ciChoose(3)">
          <div style="display: flex;align-items: center;justify-content: center;">
            <span style="font-size: 230%;font-weight: bold;">50  </span>
            <span style="font-size: 200%;font-weight: bold;margin-left: 5px;position: relative;top:-3px;">元</span>
          </div>
          <span style="font-size: 100%;">提问：1000次</span>
        </div>
        <div style="width: 100%;box-sizing: border-box;text-align: left;margin-top: 1%;font-size:100%; color: #B1B1B1;">低至 0.05元 / 次</div>
        <van-field v-model="recharge_money" type="digit" placeholder="请输入金额，最少五元" maxlength="5" style="margin-top: 2%; border: 0.5px solid #C2C2C4;border-radius: 3px;width: 45%;box-shadow: var(0 0 transparent,0 0 transparent),var(0 0 transparent,0 0 transparent),var(0 0 transparent);" @focus="chooseRecharge()">
          </van-field>
      </div>

      <!--------------------4.0收费-------------------->
      <div style="width: 100%;margin-top: 25px;display: flex;flex-wrap: wrap;" v-show="nav_choose_num==3">
        <div style="width: 47.5%; padding: 7% 0;box-sizing:border-box;border-radius: 5px;" :class="{'ci_choose':ci4_choose_num==1,'ci_nochoose':ci4_choose_num!=1}" @click="ci4Choose(1)">
          <div style="display: flex;align-items: center;justify-content: center;">
            <span style="font-size: 230%;font-weight: bold;">10  </span>
            <span style="font-size: 200%;font-weight: bold;margin-left: 5px;position: relative;top:-3px;">次</span>
          </div>
          <span style="font-size: 100%;">9.99元</span>
        </div>
        <div style="width: 47.5%; padding: 7% 0;box-sizing:border-box;border-radius: 5px;margin-left: 5%;" :class="{'ci_choose':ci4_choose_num==2,'ci_nochoose':ci4_choose_num!=2}" @click="ci4Choose(2)">
          <div style="display: flex;align-items: center;justify-content: center;">
            <span style="font-size: 230%;font-weight: bold;">100  </span>
            <span style="font-size: 200%;font-weight: bold;margin-left: 5px;position: relative;top:-3px;">次</span>
          </div>
          <span style="font-size: 100%;">89.99元</span>
        </div>
      </div>

      <!--------------------mj收费-------------------->
      <div style="width: 100%;margin-top: 25px;display: flex;flex-wrap: wrap;" v-show="nav_choose_num==4">
        <div style="width: 47.5%; padding: 7% 0;box-sizing:border-box;border-radius: 5px;" :class="{'ci_choose':hh_choose_num==1,'ci_nochoose':hh_choose_num!=1}" @click="hhChoose(1)">
          <div style="display: flex;align-items: center;justify-content: center;">
            <span style="font-size: 200%;font-weight: bold;">月度会员</span>
          </div>
          <div style="font-size: 100%;margin-top: 5%;">200 组 AI 绘画</div>
          <div style="font-size: 100%;margin-top: 1%;font-weight: bold;">49.99元</div>
          <div style="font-size: 100%;margin-top: 1%;">折合 0.25元 / 4 张</div>
        </div>
        <div style="width: 47.5%; padding: 7% 0;box-sizing:border-box;border-radius: 5px;margin-left: 5%;" :class="{'ci_choose':hh_choose_num==2,'ci_nochoose':hh_choose_num!=2}" @click="hhChoose(2)">
          <div style="display: flex;align-items: center;justify-content: center;">
            <span style="font-size: 200%;font-weight: bold;">季度会员</span>
          </div>
          <div style="font-size: 100%;margin-top: 5%;">800 组 AI 绘画</div>
          <div style="font-size: 100%;margin-top: 1%;font-weight: bold;">129.99元</div>
          <div style="font-size: 100%;margin-top: 1%;">折合 0.25元 / 4 张</div>
        </div>
      </div>
      <div style="width: 100%;margin-top: 25px;display: flex;align-items: center;justify-content: center;" v-show="nav_choose_num==5">
        <div style="width: 47.5%; padding: 7% 0;box-sizing:border-box;border-radius: 5px;" :class="{'ci_choose':sora_choose_num==1,'ci_nochoose':sora_choose_num!=1}" @click="soraChoose(1)">
          <div style="display: flex;align-items: center;justify-content: center;">
            <span style="font-size: 200%;font-weight: bold;">解锁所有</span>
          </div>
          <div style="font-size: 100%;margin-top: 1%;font-weight: bold;">9.9元</div>
        </div>

      </div>
    </div>

    <!--------------------选择支付方式-------------------->
    <div style="width: 100%;position: absolute;bottom: 5%;">
      <div style="width: 100%;display:flex;align-items: center;justify-content: center;">
        <van-radio-group v-model="rechar_type_num" direction="horizontal" checked-color="#10A17D">
          <van-radio name="1" >支付宝支付</van-radio>
          <van-radio name="2">微信支付</van-radio>
        </van-radio-group>
      </div>
      
      <van-button color="#12A27E" style="width: 45%;margin-top: 20px;" :disabled="btnDis" @click="recharge(rechar_type_num)">立即支付</van-button>
      <div style="width: 100%;font-size: 90%;color: #AFAFAF;margin-top: 1%;text-align: center;height: 20px;">{{ bottom_tip }}</div>
    </div>

    <!-------------------- 支付宝支付界面 -------------------->
    <van-overlay :show="zfFlag" style="z-index:9999;">
      <div style="width: 100%;height: 100%;display: flex;flex-wrap: wrap; align-items: center;justify-content: center;flex-direction: column; position: relative;">
        <div style="width: 10vw;height: 10vw;border: 2px solid white;box-sizing: border-box; color: white;display: flex;align-items: center;justify-content: center;font-size: 120%;position: absolute;right: 5vw;top: 5vw;" @click="rechar_check_cannel">X</div>
        <img :src="qrcode_url" alt="" style="width: 50%;">
        <div style="width: 100%;margin-top: 5%;color: white;">{{ payMsg }}</div>
      </div>
    </van-overlay>

  </div>
</template>
<style scoped>
.van-overlay{
  z-index:121;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.nav_choose{
  background-color: #12A27E;
  color:#FAFBFD;
  
}
.nav_nochoose{
  background-color: #FAFBFD;
  color:#12A27E;
  border-top: 1px solid #12A27E;
  border-bottom: 1px solid #12A27E;
}
.ci_choose{
  border: 1px solid #12A27E;
  color:#12A27E;
}
.ci_nochoose{
  border: 1px solid #727272;
  color: #252525;
}
.yue_choose{
  border: 1px solid #12A27E;
  color:#12A27E;
}
.yue_nochoose{
  border: 1px solid #727272;
  color: #666666;
}
</style>
<!-- 邀请好友页 -->
<script>
import { Toast } from 'vant';

export default {
  name: 'UserIndexYd2ZL',
  props:{
    is_recharge: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      nav_choose_num: 1,
      yue_choose_num: 0,
      ci_choose_num:0,
      ci4_choose_num: 0,
      hh_choose_num:0,
      sora_choose_num:0,
      rechar_type_num: "1",
      bottom_tip: '包月会员到期后，将"按次计费"',
      btnDis: true,
      recharge_money: '',
      zfFlag: false,
      qrcode_url: '',
      out_trade_no: '',
      payMsg: "请使用支付宝扫码支付",
      timer: '',
      
    }
  },
  watch:{
    recharge_money(val,val2){
      if(this.recharge_money){
        this.btnDis = false;
      }else{
        this.btnDis = true;
      }

    },
  },
  mounted(){
    this.gptpay_token = localStorage.getItem("gptpay_token");
  },
  methods: {
    toClose(){
      this.$emit('closeEvent');
    },
    rechar_check_cannel(){
      this.zfFlag=false
      clearInterval(this.timer);
    },
    recharge(type){
      var that = this;
      var recharge_money = 0;
      var recharge_type = '';
      var nav_choose_num = that.nav_choose_num;
      var yue_choose_num = that.yue_choose_num;
      var ci_choose_num = that.ci_choose_num;
      var ci4_choose_num = that.ci4_choose_num;
      var hh_choose_num = that.hh_choose_num;
      that.rechar_type_num = type;
      //按月收费
      if(that.nav_choose_num==1){
        if(yue_choose_num==1) {
          recharge_money = 49.99;
          recharge_type = 'yue_1';
        }else if(yue_choose_num==2) {
          recharge_money = 129.99;
          recharge_type = 'yue_3';
        }else if(yue_choose_num==3) {
          recharge_money = 129.99;
          recharge_type = 'yue_12';
        }
      }else if(nav_choose_num==2){
        recharge_type = 'ci';
        if(ci_choose_num==1) {
          recharge_money = 5;
        }else if(ci_choose_num==2) {
          recharge_money = 20;
        }else if(ci_choose_num==3) {
          recharge_money = 50;
        }else if(ci_choose_num==0){
          recharge_money = that.recharge_money;
        }
      }else if(nav_choose_num==3){
        if(ci4_choose_num==1) {
          recharge_money = 9.99;
          recharge_type = 'ci4_10';
        }else if(ci4_choose_num==2) {
          recharge_money = 89.99;
          recharge_type = 'ci4_100';
        }
      }else if(nav_choose_num==4){
        if(hh_choose_num==1) {
          recharge_money = 49.99;
          recharge_type = 'mj_yue_1';
        }else if(hh_choose_num==2) {
          recharge_money = 129.99;
          recharge_type = 'mj_yue_3';
        }
      }else if(nav_choose_num==5){
        recharge_money = 9.9;
        recharge_type = 'sora';
      }
      if(recharge_money==0){
        Toast({
          'type':'fail',
          'message':'请输入要充值的金额'
        })
        that.recharge_money = '';
        return ;
      }
      this.$emit('changeNavEvent', recharge_money, that.rechar_type_num, recharge_type);
    },
    chooseRecharge(){
      this.ci_choose_num = 0;
      if(this.recharge_money){
        this.btnDis = false;
      }else{
        this.btnDis = true;
      }
    },
    hhChoose(num){
      this.hh_choose_num = num;
      this.btnDis = false;
    },
    soraChoose(num){
      this.sora_choose_num = num;
      this.btnDis = false;
    },
    ci4Choose(num){
      this.ci4_choose_num = num;
      this.btnDis = false;
    },
    yueChoose(num){
      this.yue_choose_num = num;
      this.btnDis = false;
    },
    ciChoose(num){
      this.ci_choose_num = num;
      this.btnDis = false;
    },
    navChoose(num){
      if(this.nav_choose_num!=num){
        this.btnDis = true;
      }
      if(num==1){
        this.bottom_tip = '包月会员到期后，将"按次计费"';
      }else if(num==2){
        this.bottom_tip = '余额无法提现';
        if(this.recharge_money){
          this.btnDis = false;
        }else{
          this.btnDis = true;
        }
      }else if(num==3){
        this.bottom_tip = '';
      }else if(num==4){
        this.bottom_tip = '';
      }
      this.nav_choose_num = num;
      this.yue_choose_num = 0;
      this.ci_choose_num = 0;
      this.ci4_choose_num = 0;
      this.$forceUpdate();
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
